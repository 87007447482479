import { Instagram, Search } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import { Box, useMediaQuery, useTheme } from "@mui/system";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import arrowRight from "../../assets/images/FrankIcons/arrows.png";
import left from "../../assets/images/FrankIcons/Left.png";
import right from "../../assets/images/FrankIcons/Right.png";
import apiServices from "../../services/RequestHandler";
import CustomFooter from "../../UiComponent/CustomFooter";
import Loader from "../../UiComponent/Loader";
import NewHeader from "../../UiComponent/NewHeader";
import ScrollToTop from "../../UiComponent/ScrollToTop";
import { defaultLabelColors } from "../../utils/constants";
import { handleLocalize } from "../../utils/helper";
import moment from "moment";
import InputField from "../../UiComponent/TextFeild";
import { debounce } from "lodash";
import CustomHeaderMobile from "../../UiComponent/CustomeHeaderMobile";
import CustomMobileFooter from "../../UiComponent/CustomMobileFooter";

interface InstagramPost {
  id: string;
  media_url: string;
  caption: string;
}
function BlogMobileView(props: any) {
  const { classes } = props;
  const navigate = useNavigate();
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      activity: false,
      page: 1,
    }
  );

  const [posts, setPosts] = useState<InstagramPost[]>([]);

  const getInstaPost = async () => {
    try {
      setState({ activity: true });
      let resp = await apiServices.getFromApi("c2c-blogs/insta-posts", "");
      console.log("Insta Post", resp);
      setState({ instaPosts: resp?.data, activity: false });
    } catch (error) {}
  };

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const xl = useMediaQuery(theme.breakpoints.up("xl"));

  React.useEffect(() => {
    handleGetAllCategory();
    handleGetAllBlog();
    getInstaPost();
  }, []);

  const handleGetAllCategory = async () => {
    try {
      setState({ activity: true });

      let resp = await apiServices.getFromApi("c2c-blog-categories/web", "");
      console.log("Categories", resp);
      if (resp?.status === 200 || resp?.status === 201) {
        setState({ allCategories: resp?.data });
        setState({ activity: false });
      }
    } catch (error) {
      console.log(error);
      setState({ activity: false });
    }
  };

  const onInputChange = (e: any) => {
    try {
      const { value, name } = e.target;
      setState({
        [name]: value,
      });
    } catch (error) {}
  };

  useEffect(() => {
    handleGetAllBlog();
  }, []);

  const handleGetAllBlog = async () => {
    try {
      setState({ activity: true });
      let resp = await apiServices.getFromApi(
        `c2c-blogs/web?page=${state?.page}&limit=6`,
        ""
      );
      if (resp?.status === 200 || resp?.status === 201) {
        setState({ allBlogs: resp?.data, totalPage: resp?.total_pages });
        setState({ activity: false });
      }
    } catch (error) {
      setState({ activity: false });
    }
  };

  const handleGetBlogsByCategory = async (data: any) => {
    setState({ activity: true });
    let resp = await apiServices.getFromApi(`c2c-blogs/web/${data}`, "");
    console.log("resp?.", resp);
    setState({ allBlogs: resp?.data });
    setState({ activity: false });
  };

  const handleSearchProducts = useCallback(
    debounce(async (searchValue: any) => {
      try {
        if (!searchValue) {
          setState({ activity: true });
          handleGetAllBlog();
          setState({
            activity: false,
          });
        }
        if (searchValue?.trim()) {
          setState({ activity: true });
          let resp = await apiServices.getFromApi(
            `c2c-blogs/web?search=${searchValue}`,
            ""
          );
          console.log("resp", resp);
          if (resp?.data?.length < 1) {
            setState({
              showImg: true,
            });
          }
          setState({
            allBlogs: resp?.data,
            activity: false,
            showSearchedData: true,
          });
        }
      } catch (error) {
        console.error("Error while searching products:", error);
      }
    }, 1500),
    []
  );

  useEffect(() => {
    handleSearchProducts(state.global_search);
  }, [state.global_search, handleSearchProducts]);

  return (
    <>
      <Loader loading={state?.activity} />
      <div>
        <ScrollToTop />

        <div style={{ background: "#f2cca7" }}>
          <ScrollToTop />
          <div
            style={{
              background: "#F2CCA7",
            }}
            className="main-container"
          >
            <CustomHeaderMobile />
          </div>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div>
              <img src={left} style={{ height: "200px" }} alt="" />
            </div>
            <div style={{ flex: 1 }}>
              <h1
                className="heading-one"
                style={{
                  fontFamily: "Raleway-Italic",
                  color: defaultLabelColors.main_blue,
                  flex: 1,
                  //  padding: "40px 160px",
                }}
              >
                NOTRE BLOG
              </h1>
            </div>
            <div>
              <img src={right} style={{ height: "200px" }} alt="" />
            </div>
          </div>
        </div>
        <div style={{ background: "#f9d9b6", padding: "10px 20px" }}>
          <Grid container spacing={2}>
            <Grid xs={12} md={4} item>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <div>
                  <InputField
                    placeHolder={`Chercher un produit chez   `}
                    input_value={state?.global_search}
                    input_name={"global_search"}
                    onchange={onInputChange}
                    input_style={{
                      background: "#FFF",
                      borderRadius: "100px",
                      fontFamily: "Raleway-Variable",
                      color: "#000",
                    }}
                    endIicon={<Search style={{ color: "#6D6D6D" }} />}
                  />
                </div>

                {/* <div
                  style={{
                    background: "#f2cca7",
                    borderRadius: "16px",
                    padding: "24px",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Raleway-Extra-Bold",
                      fontSize: "20px",
                      color: defaultLabelColors.main_blue,
                      margin: 20,
                      paddingBottom: "20px",
                    }}
                  >
                    Catégorie
                  </p>
                  {state?.allCategories?.map((item: any, idx: any) => {
                    return (
                      <>
                        <div
                          style={{
                            display: "flex",
                            fontFamily: "Raleway-Italic",
                            margin: 15,
                            cursor: "pointer",
                          }}
                          onClick={() => handleGetBlogsByCategory(item?.id)}
                        >
                          <div style={{ flex: 1, fontSize: "18px" }}>
                            {handleLocalize({
                              en: item?.category_name?.en,
                              fr: item?.category_name?.fr,
                            })}
                          </div>
                          <div>
                            <img
                              src={arrowRight}
                              alt=""
                              style={{ height: "15px", width: "20px" }}
                            />
                          </div>
                        </div>
                        <div
                          style={{ height: "1px", background: "#fff" }}
                        ></div>
                      </>
                    );
                  })}
                </div> */}

                <div
                  style={{
                    background: "#f2cca7",
                    borderRadius: "13px",
                    padding: "24px 35px",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Raleway-Extra-Bold",
                      fontSize: "18px",
                      color: defaultLabelColors.main_blue,
                      // margin: 20,
                      paddingBottom: "15px",
                      paddingLeft: 10,
                    }}
                  >
                    Catégorie
                  </p>
                  {state?.allCategories?.map((item: any, idx: any) => {
                    return (
                      <>
                        <div
                          onClick={() => handleGetBlogsByCategory(item?.id)}
                          style={{
                            display: "flex",
                            fontFamily: "Raleway-Italic",
                            margin: 15,
                            cursor: "pointer",
                          }}
                        >
                          <div style={{ flex: 1, fontSize: "18px" }}>
                            {handleLocalize({
                              en: item?.category_name?.en,
                              fr: item?.category_name?.fr,
                            })}
                          </div>
                          <div>
                            <img
                              src={arrowRight}
                              alt=""
                              style={{ height: "15px", width: "20px" }}
                            />
                          </div>
                        </div>
                        <div
                          style={{ height: "1px", background: "#fff" }}
                        ></div>
                      </>
                    );
                  })}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "22px",
                    fontFamily: "Raleway-Variable",
                    color: defaultLabelColors.main_blue,
                    gap: 6,
                  }}
                >
                  <Instagram style={{ fontSize: "32px", fontWeight: "400" }} />{" "}
                  Instagram
                </div>
                <div>
                  <Grid container spacing={1} className="gallery">
                    {state?.instaPosts?.map((i: any) => {
                      return (
                        <Grid
                          xs={2.5}
                          sm={2}
                          item
                          className="gallery-item"
                          onClick={() =>
                            window.open(
                              "https://www.instagram.com/frankme.fr/",
                              "_blank",
                              "noopener,noreferrer"
                            )
                          }
                        >
                          <img
                            src={
                              i?.media_type === "VIDEO"
                                ? i?.thumbnail_url
                                : i?.media_url
                            }
                            alt=""
                            style={{
                              borderRadius: "12px",
                              height: "117px",
                              width: "117px",
                              objectFit: "cover",
                              maxWidth: "100%",
                            }}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              </div>
            </Grid>
            <Grid xs={12} md={8} item>
              <Grid container spacing={2}>
                {state?.allBlogs?.map((item: any) => {
                  return (
                    <Grid
                      style={{ cursor: "pointer" }}
                      xs={12}
                      md={6}
                      item
                      onClick={() => {
                        navigate(
                          `/blog-details/${item?.blog_cat_id}/${item?.id}`
                        );
                      }}
                    >
                      <div>
                        <Card
                          elevation={0}
                          sx={{
                            backgroundColor: "#fff",
                            height: "462px",
                            borderRadius: 4,
                          }}
                        >
                          <CardMedia
                            component="img"
                            style={{ height: "227px", objectFit: "cover" }}
                            image={
                              item?.blog_image?.[1] ?? item?.blog_image?.[0]
                            } // Replace with the image URL you need
                            alt="Blog card image"
                          />

                          <Box
                            sx={{
                              position: "relative",
                              backgroundColor: "#fff",
                            }}
                          >
                            <Button
                              variant="contained"
                              size="small"
                              sx={{
                                position: "absolute",
                                fontSize: "14px",
                                fontFamily: "Raleway-Semi-Bold",
                                top: -20,
                                right: 16,
                                backgroundColor: "#f26e22",
                                color: "#fff",
                                fontWeight: "bold",
                                textTransform: "none",
                                boxShadow: 1,
                                borderRadius: "20px",
                                paddingX: 2,
                                "&:hover": { backgroundColor: "#e75e20" },
                              }}
                            >
                              {handleLocalize(item?.category_name)}
                            </Button>
                          </Box>

                          <CardContent style={{ padding: "37px 46px" }}>
                            <Typography
                              className="elipsis-title-blogs"
                              style={{
                                fontFamily: "Raleway-Extra-Bold",
                                fontSize: "21px",
                                color: "#000",
                              }}
                            >
                              {handleLocalize(item?.title)}
                            </Typography>
                            <Typography
                              sx={{
                                marginBottom: 2,
                                fontFamily: "Raleway-Italic",
                                fontSize: "14px",
                              }}
                            >
                              Posté le{" "}
                              {moment(
                                `${item?.created_at}`.endsWith("Z")
                                  ? item?.created_at
                                  : item?.created_at + "Z"
                              ).format("DD MMM YYYY")}
                            </Typography>
                            <Typography
                              className="elipsis-title-desc"
                              style={{
                                fontFamily: "Raleway-Variable",
                                fontSize: "16px",
                              }}
                            >
                              {handleLocalize(item?.sub_title)}
                            </Typography>
                          </CardContent>
                        </Card>
                      </div>
                    </Grid>
                  );
                })}
                {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    padding: "20px",
                  }}
                >
                  <Pagination
                    count={state?.totalPage}
                    page={state?.page}
                    onChange={(event, page) => console.log(event)}
                    color="primary"
                    size={"medium"}
                    shape="rounded"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    className={classNames(classes.pagination_container)}
                    variant="outlined"
                    showFirstButton
                    showLastButton
                  />
                </div> */}
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className="main-container" style={{ background: "#f9d9b6" }}>
          <CustomMobileFooter />
        </div>
      </div>
    </>
  );
}

export default BlogMobileView;

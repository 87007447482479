import {
  East,
  ExpandMore,
  LockOpen,
  Logout,
  OpenInNew,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Rating,
  Stack,
  Switch,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box, styled, useMediaQuery, useTheme } from "@mui/system";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import imageCompression from "browser-image-compression";
import { Buffer } from "buffer";
import dayjs from "dayjs";
import { groupBy } from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../Store";
import { strings } from "../../Store/Localization";
import { setUpdateLanguage } from "../../Store/MainAppReducer";
import BankCard from "../../UiComponent/BankCard";
import CustomButton from "../../UiComponent/CustomButton";
import CustomDatePicker from "../../UiComponent/CustomDatePicker";
import CustomFooter from "../../UiComponent/CustomFooter";
import DraggableDialog from "../../UiComponent/DraggableDialog";
import FlagInputField from "../../UiComponent/FlagInput";
import Loader from "../../UiComponent/Loader";
import NewHeader from "../../UiComponent/NewHeader";
import InputField from "../../UiComponent/TextFeild";
import Toast from "../../UiComponent/Toast/Toast";
import {
  IdenfyApprovedIcon,
  IdenfyArchivedIcon,
  IdenfyDeletedIcon,
  IdenfyDeniedIcon,
  IdenfyExpiredIcon,
  IdenfyReviewingIcon,
  IdenfySuspectedIcon,
  RefrestIcon,
} from "../../assets/images/FrankIcons";
import enIcon from "../../assets/images/FrankIcons/en-icon.png";
import frIcon from "../../assets/images/FrankIcons/fr-icon.png";
import noTransaction from "../../assets/images/FrankIcons/notransction.png";
import orangeBackground from "../../assets/images/FrankIcons/orange-background.png";
import { BASE_URL } from "../../services/HttpProvider";
import apiServices from "../../services/RequestHandler";
import { decrypt } from "../../utils/Encrypt";
import FrankConstants from "../../utils/FrankConstants";
import {
  clearAllDataFromLocalStorage,
  getDataFromLocalStorage,
  saveDataToLocalStorage,
} from "../../utils/LocalStore.util";
import {
  color_theme,
  defaultLabelColors,
  getStatusColors,
  no_image_url,
  status_translations,
} from "../../utils/constants";
import {
  currencyFormat,
  handleGetMachineDetails,
  handleLocalize,
  numberFormatWithoutCurrency,
  numberFormatWithoutCurrencyFixed,
  numberFormatWithoutCurrencyFixedOne,
  toBase64,
} from "../../utils/helper";
import { FrankDivider } from "../MarketPlace/Checkout";
import InjectedCheckoutForm from "../MarketPlace/Checkout/StripeCheckout";
import StripeCheckout from "../MyAnnounces/StripeCheckout";
import ProfileMobileView from "./ProfileMobileView";

declare global {
  interface Window {
    idenfyWebSdk: any;
  }
}

const useStyles = makeStyles(() => ({
  scrollableArea: {
    height: "600px", // Give the area a height to enable scrolling
    width: "100%",
    overflowY: "auto", // Enable vertical scrolling
    padding: "10px",

    /* Custom scrollbar for WebKit browsers */
    "&::-webkit-scrollbar": {
      width: "12px", // Width of the scrollbar
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ee6931", // Color of the scrollbar thumb
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f5f5f5", // Background of the scrollbar track
    },

    /* Custom scrollbar for Firefox */
    scrollbarWidth: "thin", // Thin scrollbar for Firefox
    scrollbarColor: "#ee6931 #f5f5f5", // Thumb and track colors for Firefox
  },
}));

export const CustomSwitch = styled(Switch)({
  "& .Mui-checked": {
    color: "#ee6931", // Active color
  },
  "& .Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#ee6931", // Track color when checked
  },
});

export const CustomTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: "#3026f1", // Indicator color
  },
});

export const CustomTab = styled(Tab)({
  fontFamily: "Raleway-Variable",
  fontWeight: 700,
  fontSize: "16px",
  textTransform: "capitalize",
  color: "#626175", // Inactive tab text color
  "&.Mui-selected": {
    color: "#3026f1", // Active tab text color
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

interface VerticalTabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function VerticalTabPanel(props: VerticalTabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function verticalA11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function Profile(props: any) {
  const classes = useStyles();
  const selector = useSelector((state: RootState) => state);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const xl = useMediaQuery(theme.breakpoints.up("xl"));
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      viewPasswordOld: false,
      viewPasswordNew: false,
      viewPasswordConfirm: false,
      loading: true,
      loadingLoader: true,
      numbrOfjobResult: 5,
      numbrOfAnnouceResult: 5,
      accountDetails: null,
      isBankAvailable: false,
      earningLength: 6,
      user: getDataFromLocalStorage("user"),
      initilalTotalFeedBack: 6,
    }
  );
  const stripePromise = loadStripe(
    "pk_test_51PHLwERrtK56w4VJovEUBRsfDb0K90Mo3tt7QrypEedKpj9HuTszcu8bnI8SnXsmA7EWSwZ87LorkcfAHi7zXz4l00kogJUBL5"
    // "pk_live_51PHLwERrtK56w4VJcMQLW2zbn7LTZPNZnBLUmFdMIakX5v5HKsPRllmYUMmWJXvmPVJaptcEN8uxmQdzDNVJYFUC0050uiD5gE"
  );
  const [verticalTabValue, setVerticalTabValue] = React.useState(
    Number(getDataFromLocalStorage("tab_state")?.ver_tab ?? 0)
  );
  const [idenfyToken, setIdenfyToken] = useState(null);

  const dispatch = useDispatch<any>();
  const [value, setValue] = React.useState(
    Number(getDataFromLocalStorage("tab_state")?.hor_tab ?? 0)
  );
  const [anchorEN, setAnchorEN] = React.useState<null | HTMLElement>(null);
  const openForLang = Boolean(anchorEN);

  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(null);
  const [formattedDate, setFormattedDate] = useState<string>("");
  const [upcomingAmount, setUpcomingAmount] = useState(0);
  const [recivedAmount, setRecivedAmount] = useState(0);

  const handleClickForLang = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEN(event.currentTarget);
  };

  const handleCloseForLang = () => {
    setAnchorEN(null);
  };
  const navigate = useNavigate();

  const handleStartVerification = async () => {
    try {
      const user = getDataFromLocalStorage("user");

      // Construct the Authorization header
      let clientId = "hTq22kmSqn0";
      let secretKey = "WblCDVir4Aiy74k3LucS";

      let encodedAuth = new Buffer(clientId + ":" + secretKey).toString(
        "base64"
      );

      const response = await fetch(`https://ivs.idenfy.com/api/v2/token`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Basic " + encodedAuth,
        },
        body: JSON.stringify({
          clientId: `FRNK-${user?.id}`,
        }),
      });

      let result = await response.json();
      const token = result.authToken;

      // // Initialize and start iDenfy if token is available
      if (token) {
        // Check if the iDenfy SDK is loaded
        setIdenfyToken(token);
        setState({ openIdenfyDialog: true });
        // if (window.idenfyWebSdk) {
        //   const idenfyInstance = window.idenfyWebSdk.initialize({
        //     clientId: `FRANK-${user?.id}`, // Replace with your Client ID
        //     authToken: token,
        //   });

        //   idenfyInstance.start();
        // } else {
        //   console.error("iDenfy SDK is not loaded properly.");
        // }
      } else {
        console.error("Failed to retrieve iDenfy token");
      }
    } catch (error) {
      // Provide more detailed error information
      console.error("Error during iDenfy verification:", error);
    }
  };

  const handleGetUserDetails = async (item: any) => {
    try {
      const resp = await apiServices.getFromApi(
        `c2c/get-user-details/${item}`,
        ""
      );
      console.log("resp hun", resp);

      let avg_rating_ = resp.data?.ratings2?.reduce((a: any, b: any) => {
        return a + b.rating;
      }, 0);
      let avg_rating = isNaN(avg_rating_ / resp?.data?.ratings2?.length)
        ? 0
        : avg_rating_ / resp?.data?.ratings2?.length;
      setState({
        allRatings: resp?.data,

        details: {
          ...resp.data,
          avg_rating,
        },
      });
    } catch (error) {}
  };

  const handleLogout = () => {
    try {
      clearAllDataFromLocalStorage();
      navigate("/");
    } catch (error) {}
  };

  const handleAddBank = async () => {
    try {
      let resp = await apiServices.postFromApi(
        "stripe/create-connected-account",
        {},
        ""
      );
      handleURlRedirection(resp?.account);
    } catch (error) {}
  };

  const handleURlRedirection = async (account_data: any) => {
    try {
      let obj = {
        account_id: account_data?.id,
      };

      let resp = await apiServices.postFromApi("stripe/generate-link", obj, "");
      if (resp?.status === 201 || resp?.status === 200) {
        setState({
          loadingLoader: false,
        });

        window.open(
          `${resp?.accountLink?.url}`,
          "_blank",
          "rel=noopener noreferrer"
        );
      }
    } catch (error) {}
  };

  const handleRetreiveAccount = async () => {
    try {
      let resp = await apiServices.postFromApi("stripe/reterive-banks", {}, "");
      if (resp?.status === 200 && resp?.details_submitted === true) {
        setState({
          isBankAvailable: true,
          bankDetails: resp?.externalAccounts?.data,
          loginLink: resp?.login_link,
        });
      }
    } catch (error) {}
  };

  const handleGetHisory = async () => {
    try {
      const resp = await apiServices.getFromApi("/c2c-orders/by-customer", "");
      let order_history = resp?.data?.sort((a: any, b: any) =>
        new Date(a.created_at).getTime() < new Date(b.created_at).getTime()
          ? 1
          : -1
      );
      let un_paid = [],
        paid = [];
      console.log("order_history", order_history);

      for (const i of order_history) {
        if (i.is_draft) {
          if (i.is_particular) {
            un_paid.push({ ...i, order_transaction_id: i.id });
          } else {
            un_paid.push(i);
          }
        } else {
          paid.push(i);
        }
      }
      let grouped = groupBy(un_paid, "order_transaction_id");

      let unPaid_orders = [];
      for (const key in grouped) {
        if (Object.prototype.hasOwnProperty.call(grouped, key)) {
          const item = grouped[key];
          unPaid_orders.push(item);
        }
      }
      setState({
        unPaid_orders,
        order_history: paid,
        refreshing: false,
        no_orders: order_history?.length > 0 ? true : false,
      });
      console.log("order_history", state?.order_history);
    } catch (error) {
      setState({
        refreshing: false,
      });

      console.log(error);
    }
  };

  const handleConfirmPayment = async () => {
    try {
      setState({ loadingLoader: true });
      const resp2 = await apiServices.postFromApi(
        state.stripe_form?.is_particular
          ? `c2c-orders/particular/submit-order`
          : "/c2c-orders/submit-order",
        state.stripe_form?.is_particular
          ? {
              order_id: Number(state.stripe_form?.tr_id),
            }
          : {
              order_transaction_id: Number(state.stripe_form?.tr_id),
              billing_address: null,
            },
        ""
      );
      handleGetHisory();
      setState({
        openFuturePayment: false,
        loadingLoader: false,
        loading: false,
        stripe_form: null,
      });
    } catch (error) {
      setState({
        loadingLoader: false,
      });
      console.log(error);
    }
  };

  const handleTrackOrder = (item: any) => {
    try {
      if (item?.is_particular) {
        navigate(`/track-p-order/${item?.id}`);
        return;
      } else {
        navigate(`/track-order/${item?.id}`);
      }
    } catch (error) {}
  };

  const handleGetProductbyParticular = async () => {
    try {
      let resp = await apiServices.postFromApi(
        `c2c-product-masters/products-by-particular`,
        { owner_id: selector.mainApp.user.id },

        ""
      );
      console.log("My Produvy", resp);

      if (resp?.status === 200 || resp?.status === 201) {
        setState({
          myListing: resp?.data,
        });
      }

      console.log("resp", resp);
    } catch (error) {}
  };

  const handleVerticalTabChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setVerticalTabValue(newValue);
    const saved_tab = getDataFromLocalStorage("tab_state");
    saveDataToLocalStorage("tab_state", {
      hor_tab: saved_tab?.hor_tab,
      ver_tab: newValue,
    });
  };

  const handleDateChange = (newValue: dayjs.Dayjs | null) => {
    setSelectedDate(newValue);
    if (newValue) {
      const formatted = newValue.format("YYYY/MM/DD");
      setFormattedDate(formatted);
    }
  };

  useEffect(() => {
    handleGetProfileInfo();
    handleGetMyEarning();
    handleGetAllDeliveries();
    handleGetAllJobs();
    handleRetreiveAccount();
    handleGetHisory();
    handleGetProductbyParticular();
  }, []);

  const handleGetAllDeliveries = async () => {
    try {
      let resp = await apiServices.getFromApi(
        "c2c-shipments/get-by-customer",
        ""
      );

      if (resp?.status === 200 || resp?.status === 201) {
        setState({
          deliveries: resp?.data,
          loadingLoader: false,
        });
      }
    } catch (error) {}
  };

  const handleGetAllJobs = async () => {
    try {
      let resp = await apiServices.getFromApi(
        "c2c-shipments/get-by-accepter",
        ""
      );

      if (resp?.status === 200 || resp?.status === 201) {
        setState({
          transporterJobs: resp?.data,
          loadingLoader: false,
        });
      }
    } catch (error) {}
  };

  const handleLoginStripe = () => {
    try {
      window.open(`${state?.loginLink}`, "_blank", "rel=noopener noreferrer");
    } catch (error) {}
  };

  useEffect(() => {}, [selector.mainApp.lang]);

  const handlOpenDialogForPassword = () => {
    try {
      setState({ openForgetPasswordDialog: true });
    } catch (error) {}
  };

  const handleChangePassword = async () => {
    try {
      if (!state?.oldPassword) {
        Toast.Error(strings?.enter_o_password);
        return;
      }
      if (!state?.newPassword) {
        Toast.Error(strings?.enter_n_password);
        return;
      }
      if (!state?.confirmPassword) {
        Toast.Error("Please enter confirm password");
        return;
      }
      if (state?.newPassword !== state?.confirmPassword) {
        Toast.Error(strings?.passowrd_not_match);
        return;
      }

      let obj = {
        old_password: state?.oldPassword,
        new_password: state?.newPassword,
        password_confirm: state?.confirmPassword,
      };

      let resp = await apiServices.postFromApi(
        "c2c/change-my-password",
        obj,
        ""
      );
      if (resp?.status === 200 || resp?.status === 201) {
        Toast.showSuccess(strings?.password_changed_seccess);
        setState({
          openForgetPasswordDialog: false,
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
        clearAllDataFromLocalStorage();
        navigate("/login");
      } else {
        Toast.Error(
          resp.error?.error ??
            resp.error?.message ??
            resp?.message ??
            resp?.error?.error ??
            "Internal Server Error"
        );
      }
    } catch (error) {}
  };

  const handleOpenFuturePayment = () => {
    try {
      setState({ openFuturePayment: true });
    } catch (error) {}
  };

  const onInputChangeTwo = (e: any) => {
    try {
      const { value, name } = e.target;

      if (value[0] === " ") {
        return;
      }

      setState({
        [name]: value,
      });
    } catch (error) {}
  };

  const handleGetProfileInfo = async () => {
    try {
      let resp = await apiServices.getFromApi("c2c/me", "");
      saveDataToLocalStorage("user", resp?.data);
      setState({
        userDetails: resp?.data,
        title: resp?.data?.title,
        first_name: resp?.data?.first_name,
        surname: resp?.data?.surname,
        phone_code: resp?.data?.phone_code,
        phone: resp?.data?.phone,
        email: resp?.data?.email,
        date_of_birth: resp?.data?.date_of_birth,
        profile_pic: resp?.data?.profile_pic,
        loadingLoader: false,
      });

      setSelectedDate(dayjs(resp?.data?.date_of_birth));
      handleGetUserDetails(resp?.data?.id);
      console.log("userDetails", state?.userDetails);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateProfile = async () => {
    try {
      if (!state?.first_name) {
        Toast.Error(strings.first_name_error);
        return;
      }
      if (!state?.surname) {
        Toast.Error(strings.surname_error);
        return;
      }
      setState({
        loadingLoader: true,
      });
      let obj = {
        title: state?.title,
        first_name: state?.first_name,
        surname: state?.surname,
        profile_pic: state?.profile_pic,
        date_of_birth: formattedDate
          ? formattedDate
          : state?.userDetails?.date_of_birth,
      };

      let resp = await apiServices.patchFromApi("c2c/update-me", obj, "");
      if (resp?.status === 200 || resp.status === 201) {
        Toast.showSuccess("Profile Updated Succesfully!");
        handleGetProfileInfo();
        setState({
          loadingLoader: false,
        });
      }
      setState({
        loadingLoader: false,
      });
    } catch (error) {
      setState({
        loadingLoader: false,
      });
    }
  };

  const handleGetMyEarning = async () => {
    try {
      const resp = await apiServices.getFromApi("c2c/get-user-earnings", "");
      if (resp?.status === 200 || resp?.status === 201) {
        setState({
          x: resp?.data,
        });
      }
    } catch (error) {}
  };
  const calculateReceivedAmount = () => {
    return state?.x?.reduce(
      (total: any, delivery: any) => total + delivery.amount_paid,
      0
    );
  };
  const calculateUpcomingAmount = () => {
    return state?.x?.reduce(
      (total: any, delivery: any) => total + delivery.amount,
      0
    );
  };
  useEffect(() => {
    setUpcomingAmount(calculateUpcomingAmount());
    setRecivedAmount(calculateReceivedAmount());
  }, [state?.x]);

  const handleInitStripeSetupPayment = async () => {
    try {
      const resp = await apiServices.postFromApi(
        "stripe/payment-sheet",
        {},
        ""
      );

      const { setupIntent, ephemeralKey, customer } = resp;
      setState({
        options: {
          setupIntent,
          ephemeralKey,
          customer,
        },
        openFuturePayment: true,
      });
      return {
        setupIntent,
        ephemeralKey,
        customer,
      };
    } catch (error) {
      console.log(error);
    }
  };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    const saved_tab = getDataFromLocalStorage("tab_state");
    saveDataToLocalStorage("tab_state", {
      hor_tab: newValue,
      ver_tab: saved_tab?.ver_tab,
    });
  };
  const handleChangeMobileCode = (dialcode: any, codeName: any) => {
    try {
      setState({
        [codeName]: dialcode,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleChangeMobile = (event: any) => {
    try {
      setState({
        [event.target.name]: event.target.value,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const enableFlagInput = (state: any, value: any) => {
    try {
      setState({
        [state]: value,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleCloseDialog = () => {
    try {
      setState({ openFuturePayment: false });
    } catch (error) {}
  };

  const handleLaunchCamera = async (e: any, stateName: string) => {
    try {
      setState((prevState: any) => ({ ...prevState, loading: true }));
      const imageFile = e.target.files[0];

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      const compressedFile = await imageCompression(imageFile, options);

      let file = compressedFile || null;
      let uri = (await toBase64(file)) as any;

      if (!uri) {
        return;
      }

      const modifiedFile = new File(
        [file],
        `${stateName}-` + new Date().getTime(),
        { type: file.type }
      );
      let formData = new FormData();

      formData.append("files", modifiedFile);

      const resp = await axios({
        baseURL: BASE_URL,
        headers: {
          "Content-Type": "multipart/form-data", // this is important
        },
        method: "post",
        url: "upload/upload-multiple",
        data: formData,
      });

      setState({ profile_pic: resp?.data?.data[0]?.uri });

      setState((prevState: any) => ({
        ...prevState,
        [`${stateName}_obj`]: { modifiedFile, uri },
        [`show_${stateName}`]: true,
        loading: false,
      }));
    } catch (error) {
      console.log(error);
      setState((prevState: any) => ({ ...prevState, loading: false }));
    }
  };
  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleMakeTransferRequest = async () => {
    try {
      let resp = await apiServices.postFromApi(
        "/c2c-payouts/make-request",
        {
          user_name: `${
            selector?.profileInfo?.profile_data?.first_name ?? ""
          } ${selector?.profileInfo?.profile_data?.user_name ?? ""}`,
        },
        ""
      );

      saveDataToLocalStorage("disable_transfer", state.x?.length);
      setState({
        transfer_btn: true,
      });
      Toast.showSuccess(`Demande de transfert initiée ! `);
    } catch (error) {
      console.log(error);
    }
  };
  const transSizes = (item: any) => {
    console.log(item);

    const deliveryType = FrankConstants.packageSize(
      selector.mainApp.lang
    )?.find((a: any) => a.size === item?.cat_name);
    return deliveryType?.size_trans;
  };
  return (
    <>
      <Helmet>
        <title>Profil Utilisateur | Gérez Votre Compte sur Frank</title>
        <link
          rel="canonical"
          href="https://www.v2internal.frankme.com/profile"
        />
        <meta
          name="description"
          content="Gérez votre compte Frank, consultez vos annonces, vos messages et vos informations personnelles. Personnalisez votre expérience de livraison collaborative."
        />
      </Helmet>

      <div
        style={{ background: defaultLabelColors.main_background_two }}
        className="web-screen"
      >
        <Loader loading={state?.loadingLoader} />
        <Grid container spacing={2} item>
          <Grid
            xs={12}
            item
            style={{
              paddingLeft: "40px",
              paddingRight: "40px",
              paddingTop: "30px",
            }}
          >
            <NewHeader />
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 24,
          }}
        >
          <div
            style={{
              width: lg ? "85%" : md ? "85%" : "65%",
            }}
          >
            <Box>
              <CustomTabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <CustomTab label="Profil" />
                <CustomTab label="Porte monnaie" />
                <CustomTab label="Mes annonces" />
                <CustomTab label="Evaluations" />
              </CustomTabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <div
                style={{
                  background: "#fff",
                  borderRadius: "16px",
                  padding: "24px",
                  gap: 24,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <h1
                    style={{
                      margin: "0px",
                      fontSize: 20,
                      fontWeight: "normal",
                      fontFamily: "Raleway-Bold",
                    }}
                  >
                    {strings?.my_profile}
                  </h1>

                  <Tooltip title="Déconnexion">
                    {/* <IconButton onClick={handleLogout}>
                      <Logout
                        style={{ color: defaultLabelColors.main_blue }}
                        fontSize={"medium"}
                      />
                      
                    </IconButton> */}
                    <div
                      onClick={() => {handleLogout()}}
                      style={{
                        fontSize: 14,
                        fontFamily: "Raleway-Semi-Bold",
                        color: defaultLabelColors?.main_blue,
                        cursor: "pointer",
                      }}
                    >
                      Déconnexion
                    </div>
                  </Tooltip>
                </div>
                <Grid container spacing={2}>
                  <Grid
                    xs={12}
                    lg={2}
                    item
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        border: `1px solid ${color_theme.black_10}`,
                        borderRadius: "100%",
                        height: 150,
                        width: 150,
                      }}
                    >
                      <img
                        src={
                          state?.profile_pic
                            ? state?.profile_pic
                            : state?.userDetails?.profile_pic ?? no_image_url
                        }
                        alt="Profile"
                        style={{
                          width: "150px",
                          height: "150px",
                          borderRadius: "100%",
                          objectFit: "contain",
                        }}
                      />

                      <IconButton
                        onClick={triggerFileInput}
                        aria-label="upload picture"
                        component="span"
                        sx={{
                          position: "absolute",
                          bottom: 0,
                          right: 0,
                        }}
                      >
                        <svg
                          width="37"
                          height="36"
                          viewBox="0 0 37 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            width="36"
                            height="36"
                            rx="18"
                            fill="#F4F3F6"
                          />
                          <path
                            d="M8.5 14.3772C8.5 14.0269 8.5 13.8517 8.51462 13.7042C8.6556 12.2813 9.78127 11.1556 11.2042 11.0146C11.3517 11 11.5364 11 11.9056 11C12.0479 11 12.119 11 12.1794 10.9963C12.9506 10.9496 13.626 10.4629 13.9141 9.746C13.9367 9.68986 13.9578 9.62657 14 9.5C14.0422 9.37343 14.0633 9.31014 14.0859 9.254C14.374 8.53712 15.0494 8.05037 15.8206 8.00366C15.881 8 15.9477 8 16.0811 8H20.9189C21.0523 8 21.119 8 21.1794 8.00366C21.9506 8.05037 22.626 8.53712 22.9141 9.254C22.9367 9.31014 22.9578 9.37343 23 9.5C23.0422 9.62657 23.0633 9.68986 23.0859 9.746C23.374 10.4629 24.0494 10.9496 24.8206 10.9963C24.881 11 24.9521 11 25.0944 11C25.4636 11 25.6483 11 25.7958 11.0146C27.2187 11.1556 28.3444 12.2813 28.4854 13.7042C28.5 13.8517 28.5 14.0269 28.5 14.3772V22.2C28.5 23.8802 28.5 24.7202 28.173 25.362C27.8854 25.9265 27.4265 26.3854 26.862 26.673C26.2202 27 25.3802 27 23.7 27H13.3C11.6198 27 10.7798 27 10.138 26.673C9.57354 26.3854 9.1146 25.9265 8.82698 25.362C8.5 24.7202 8.5 23.8802 8.5 22.2V14.3772Z"
                            stroke="#2D26E6"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M18.5 22.5C20.7091 22.5 22.5 20.7091 22.5 18.5C22.5 16.2909 20.7091 14.5 18.5 14.5C16.2909 14.5 14.5 16.2909 14.5 18.5C14.5 20.7091 16.2909 22.5 18.5 22.5Z"
                            stroke="#2D26E6"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </IconButton>
                      <input
                        type="file"
                        accept="image/*"
                        ref={fileInputRef}
                        onChange={(e) => handleLaunchCamera(e, "profile")}
                        style={{ display: "none" }}
                      />
                    </div>

                    <div
                      style={{
                        padding: "10px 0px 10px 0px",
                        textAlign: "center",
                      }}
                    >
                      <Stack spacing={1}>
                        <Rating
                          style={{ borderRadius: 12 }}
                          name="half-rating-read"
                          defaultValue={selector?.mainApp?.user?.rating}
                          precision={0.5}
                          readOnly
                          size="large"
                        />
                      </Stack>

                      <p
                        style={{
                          textAlign: "center",
                          fontSize: "16px",
                          fontFamily: "Raleway-Bold",
                        }}
                      >
                        {numberFormatWithoutCurrencyFixed(
                          state?.userDetails?.rating?.toFixed(1) ?? 0
                        ) ?? 0}{" "}
                        avis
                      </p>
                    </div>
                  </Grid>
                  <Grid xs={12} lg={10} item container spacing={2}>
                    <Grid xs={12} md={4} lg={6} item>
                      <div
                        style={{
                          height: 56,
                          width: "100%",
                          background: defaultLabelColors.input_feild_bg,
                          borderRadius: "16px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <FormControl
                          size="small"
                          style={{
                            justifyContent: "flex-start",
                          }}
                        >
                          <RadioGroup
                            style={{
                              paddingLeft: "20px",
                              display: "flex",
                              flexDirection: "row",
                              columnGap: 12,
                            }}
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="title"
                            value={state?.title ?? "Male"} // Controlled value
                            onChange={(e) =>
                              setState({
                                title: e.target.value, // Update title inside userDetails
                              })
                            }
                          >
                            {[
                              { value: "Male", label: strings.male },
                              { value: "Female", label: strings.female },
                              { value: "Others", label: strings.other },
                            ].map((j) => (
                              <FormControlLabel
                                style={{ fontSize: "12px" }}
                                control={
                                  <Radio
                                    size="small"
                                    sx={{
                                      color: "black",
                                      "&.Mui-checked": {
                                        color: "black",
                                      },
                                    }}
                                  />
                                }
                                value={j.value}
                                label={j.label}
                                sx={{
                                  "& .MuiFormControlLabel-label": {
                                    fontFamily: "Raleway-Variable",
                                    fontSize: "small",
                                  },
                                }}
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </Grid>
                    <Grid xs={12} md={4} lg={6} item>
                      <div
                        style={{
                          height: 56,
                          width: "100%",
                          background: defaultLabelColors.input_feild_bg,
                          borderRadius: 16,
                        }}
                      >
                        <FlagInputField
                          divStyle={{
                            background: defaultLabelColors.input_feild_bg,
                            borderRadius: "16px",
                            border: "none",
                            width: "calc(100% - 10px)",
                            height: 56,
                            marginTop: 0,
                          }}
                          isDisabled
                          classes={classes}
                          inputStyle={{
                            background: defaultLabelColors.input_feild_bg,
                          }}
                          required={false}
                          name={"phone"}
                          codeName={"phone_code"}
                          value={state?.phone || ""}
                          countryCode={state?.phone_code || "+92"}
                          handleChangeMobileCode={(dialcode, codeName) =>
                            handleChangeMobileCode(dialcode, codeName)
                          }
                          handleChangeMobile={(event) =>
                            handleChangeMobile(event)
                          }
                          enableFlagInput={enableFlagInput}
                        />
                      </div>
                    </Grid>
                    <Grid xs={12} md={4} lg={6} item>
                      <div
                        style={{
                          height: 56,
                          width: "100%",
                          background: defaultLabelColors.input_feild_bg,
                          borderRadius: 16,
                        }}
                      >
                        <InputField
                          placeHolder={"Prénom"}
                          input_value={state?.first_name}
                          input_name={"first_name"}
                          input_style={{
                            background: defaultLabelColors.input_feild_bg,
                            width: "100%",
                            borderRadius: "16px",
                            border: "none",
                            margin: 0,
                          }}
                          onchange={onInputChangeTwo}
                        />
                      </div>
                    </Grid>
                    <Grid xs={12} md={4} lg={6} item>
                      <div
                        style={{
                          height: 56,
                          width: "100%",
                          background: defaultLabelColors.input_feild_bg,
                          borderRadius: 16,
                        }}
                      >
                        <InputField
                          placeHolder={"Prénom"}
                          input_value={state?.email}
                          input_name={"email"}
                          input_style={{
                            background: defaultLabelColors.input_feild_bg,
                            width: "100%",
                            borderRadius: "16px",
                            border: "none",
                            margin: 0,
                          }}
                          onchange={onInputChangeTwo}
                        />
                      </div>
                    </Grid>
                    <Grid xs={12} md={4} lg={6} item>
                      <div
                        style={{
                          height: 56,
                          width: "100%",
                          background: defaultLabelColors.input_feild_bg,
                          borderRadius: 16,
                        }}
                      >
                        <InputField
                          placeHolder={"Nom"}
                          input_value={state?.surname}
                          input_name={"surname"}
                          input_style={{
                            background: defaultLabelColors.input_feild_bg,
                            width: "100%",
                            borderRadius: "16px",
                            border: "none",
                            margin: 0,
                          }}
                          onchange={onInputChangeTwo}
                        />
                      </div>
                    </Grid>
                    <Grid xs={12} md={4} lg={6} item>
                      <div
                        style={{
                          height: 56,
                          width: "100%",
                          background: defaultLabelColors.input_feild_bg,
                          borderRadius: 16,
                        }}
                      >
                        <CustomDatePicker
                          onChange={handleDateChange}
                          value={selectedDate}
                          disableFuture={true}
                          fontFamily="Raleway-Italic"
                          backgroundColor={defaultLabelColors.input_feild_bg}
                        />
                      </div>
                    </Grid>
                    <Grid xs={12} item>
                      <CustomButton
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#fff",
                          fontFamily: "Raleway-Variable",
                          background: "#ee6931",
                          border: "none",
                          borderRadius: "12px",
                          padding: "10px 40px 10px 40px",
                          marginLeft: "10px",
                        }}
                        value={"Enregistrer"}
                        onClick={handleUpdateProfile}
                      />
                    </Grid>
                  </Grid>

                  {/* 
                  <Grid
                    xs={12}
                    md={4}
                    lg={5}
                    item
                    style={{ paddingLeft: "10px" }}
                  >
                    <Grid container spacing={1}>
                      <Grid xs={12} item>
                       
                      </Grid>
                      
                      <Grid xs={12} item>
                        <CustomButton
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            color: "#fff",
                            fontFamily: "Raleway-Variable",
                            background: "#ee6931",
                            border: "none",
                            borderRadius: "12px",
                            padding: "10px 40px 10px 40px",
                            marginLeft: "10px",
                          }}
                          value={"Enregistrer"}
                          onClick={handleUpdateProfile}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={12} md={4} lg={5} item>
                    <Grid container spacing={1}>
                      <Grid xs={12} item style={{ marginLeft: "10px" }}>
                        <FlagInputField
                          divStyle={{
                            background: "#F4F3F6",
                            borderRadius: "16px",
                            border: "none",
                            padding: "8px",
                            width: "98%",
                          }}
                          isDisabled
                          classes={classes}
                          inputStyle={{ background: "#F4F3F6" }}
                          required={false}
                          name={"phone"}
                          codeName={"phone_code"}
                          value={state?.phone || ""}
                          countryCode={state?.phone_code || "+92"}
                          handleChangeMobileCode={(dialcode, codeName) =>
                            handleChangeMobileCode(dialcode, codeName)
                          }
                          handleChangeMobile={(event) =>
                            handleChangeMobile(event)
                          }
                          enableFlagInput={enableFlagInput}
                        />
                      </Grid>
                      <Grid xs={12} item>
                        <InputField
                          placeHolder={"example@gmail.com"}
                          input_value={state?.email}
                          input_name={"email"}
                          input_style={{
                            background: "#F4F3F6",
                            width: "100%",
                            borderRadius: "16px",
                            border: "none",
                          }}
                          readonly
                          // onchange={onInputChange}
                        />
                      </Grid>
                      <Grid xs={12} item style={{ paddingLeft: "20px" }}>
                      
                      </Grid>
                    </Grid>
                  </Grid>
                  
                  */}
                </Grid>
              </div>

              <div
                style={{
                  background: color_theme.white,
                  borderRadius: 16,
                  marginTop: 16,
                  padding: 16,
                }}
              >
                {state?.userDetails?.idenfy_verification === true &&
                state?.userDetails?.idenfy_verification_status ===
                  "APPROVED" ? (
                  <div style={{ display: "flex", gap: 20 }}>
                    <div
                      style={{
                        flex: 1,
                        fontFamily: "Raleway-Extra-Bold",
                        color: "#000",
                        fontSize: "32px",
                      }}
                    >
                      ID Verification
                      <p
                        style={{
                          fontFamily: "Raleway-Variable",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      >
                        Your Verification Status is Approved
                      </p>
                    </div>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <IdenfyApprovedIcon />
                    </div>
                  </div>
                ) : state?.userDetails?.idenfy_verification === false &&
                  state?.userDetails?.idenfy_verification_status ===
                    "DENIED" ? (
                  <>
                    <div style={{ display: "flex", gap: 20 }}>
                      <div
                        style={{
                          flex: 1,
                          fontFamily: "Raleway-Extra-Bold",
                          color: "#000",
                          fontSize: "32px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        ID Verification
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingLeft: "20px",
                          }}
                        >
                          <IdenfyDeniedIcon />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "18px",
                        fontWeight: "500",
                        marginTop: "10px",
                      }}
                    >
                      Your Verification Status is Denied
                    </div>
                    <p>
                      <CustomButton
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#fff",
                          fontFamily: "Raleway-Variable",
                          background: "#ee6931",
                          border: "none",
                          borderRadius: "12px",
                          padding: "10px 40px 10px 40px",
                          marginLeft: "10px",
                        }}
                        value={"Start Verification"}
                        onClick={handleStartVerification}
                      />
                    </p>
                  </>
                ) : state?.userDetails?.idenfy_verification === false &&
                  state?.userDetails?.idenfy_verification_status ===
                    "SUSPECTED" ? (
                  <>
                    <div style={{ display: "flex", gap: 20 }}>
                      <div
                        style={{
                          flex: 1,
                          fontFamily: "Raleway-Extra-Bold",
                          color: "#000",
                          fontSize: "32px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        ID Verification
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingLeft: "20px",
                          }}
                        >
                          <IdenfySuspectedIcon />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "18px",
                        fontWeight: "500",
                        marginTop: "10px",
                      }}
                    >
                      Your Verification Status is Suspected
                    </div>
                    <p>
                      <CustomButton
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#fff",
                          fontFamily: "Raleway-Variable",
                          background: "#ee6931",
                          border: "none",
                          borderRadius: "12px",
                          padding: "10px 40px 10px 40px",
                          marginLeft: "10px",
                        }}
                        value={"Start Verification"}
                        onClick={handleStartVerification}
                      />
                    </p>
                  </>
                ) : state?.userDetails?.idenfy_verification === false &&
                  state?.userDetails?.idenfy_verification_status ===
                    "REVIEWING" ? (
                  <>
                    <div style={{ display: "flex", gap: 20 }}>
                      <div
                        style={{
                          flex: 1,
                          fontFamily: "Raleway-Extra-Bold",
                          color: "#000",
                          fontSize: "32px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        ID Verification
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingLeft: "20px",
                          }}
                        >
                          <IdenfyReviewingIcon />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "18px",
                        fontWeight: "500",
                        marginTop: "10px",
                      }}
                    >
                      Your Verification Status is Reviewing
                    </div>
                    <p>
                      <CustomButton
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#fff",
                          fontFamily: "Raleway-Variable",
                          background: "#ee6931",
                          border: "none",
                          borderRadius: "12px",
                          padding: "10px 40px 10px 40px",
                          marginLeft: "10px",
                        }}
                        value={"Start Verification"}
                        onClick={handleStartVerification}
                      />
                    </p>
                  </>
                ) : state?.userDetails?.idenfy_verification === false &&
                  state?.userDetails?.idenfy_verification_status ===
                    "DELETED" ? (
                  <>
                    <div style={{ display: "flex", gap: 20 }}>
                      <div
                        style={{
                          flex: 1,
                          fontFamily: "Raleway-Extra-Bold",
                          color: "#000",
                          fontSize: "32px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        ID Verification
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingLeft: "20px",
                          }}
                        >
                          <IdenfyDeletedIcon />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "18px",
                        fontWeight: "500",
                        marginTop: "10px",
                      }}
                    >
                      Your Verification Status is Deleted
                    </div>
                    <p>
                      <CustomButton
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#fff",
                          fontFamily: "Raleway-Variable",
                          background: "#ee6931",
                          border: "none",
                          borderRadius: "12px",
                          padding: "10px 40px 10px 40px",
                          marginLeft: "10px",
                        }}
                        value={"Start Verification"}
                        onClick={handleStartVerification}
                      />
                    </p>
                  </>
                ) : state?.userDetails?.idenfy_verification === false &&
                  state?.userDetails?.idenfy_verification_status ===
                    "ARCHIVED" ? (
                  <>
                    <div style={{ display: "flex", gap: 20 }}>
                      <div
                        style={{
                          flex: 1,
                          fontFamily: "Raleway-Extra-Bold",
                          color: "#000",
                          fontSize: "32px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        ID Verification
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingLeft: "20px",
                          }}
                        >
                          <IdenfyArchivedIcon />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "18px",
                        fontWeight: "500",
                        marginTop: "10px",
                      }}
                    >
                      Your Verification Status is Archived
                    </div>
                    <p>
                      <CustomButton
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#fff",
                          fontFamily: "Raleway-Variable",
                          background: "#ee6931",
                          border: "none",
                          borderRadius: "12px",
                          padding: "10px 40px 10px 40px",
                          marginLeft: "10px",
                        }}
                        value={"Start Verification"}
                        onClick={handleStartVerification}
                      />
                    </p>
                  </>
                ) : state?.userDetails?.idenfy_verification === false &&
                  state?.userDetails?.idenfy_verification_status ===
                    "EXPIRED" ? (
                  <>
                    <div style={{ display: "flex", gap: 20 }}>
                      <div
                        style={{
                          flex: 1,
                          fontFamily: "Raleway-Extra-Bold",
                          color: "#000",
                          fontSize: "32px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        ID Verification
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingLeft: "20px",
                          }}
                        >
                          <IdenfyExpiredIcon />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "18px",
                        fontWeight: "500",
                        marginTop: "10px",
                      }}
                    >
                      Your Verification Status is Expired
                    </div>
                    <p>
                      <CustomButton
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#fff",
                          fontFamily: "Raleway-Variable",
                          background: "#ee6931",
                          border: "none",
                          borderRadius: "12px",
                          padding: "10px 40px 10px 40px",
                          marginLeft: "10px",
                        }}
                        value={"Start Verification"}
                        onClick={handleStartVerification}
                      />
                    </p>
                  </>
                ) : state?.userDetails?.idenfy_verification === false &&
                  state?.userDetails?.idenfy_verification_status ===
                    "ACTIVE" ? (
                  <>
                    <div style={{ display: "flex", gap: 20 }}>
                      <div
                        style={{
                          flex: 1,
                          fontFamily: "Raleway-Extra-Bold",
                          color: "#000",
                          fontSize: "32px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        ID Verification
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingLeft: "20px",
                          }}
                        >
                          <IdenfyApprovedIcon />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "18px",
                        fontWeight: "500",
                        marginTop: "10px",
                      }}
                    >
                      Your Verification Status is Active
                    </div>
                  </>
                ) : (
                  <Grid container spacing={2}>
                    <Grid xs={12} item>
                      <h2 className="profile-headings">
                        Vérification d'identité
                      </h2>
                    </Grid>

                    <Grid xs={12} item>
                      <p className="profile-text">
                        Pour garantir des validations rapides des documents,
                        envoyez des photos ou des scans
                      </p>
                      <div>
                        <p className="profile-text">
                          <span style={{ fontWeight: "600" }}>
                            {" "}
                            1. En couleur :
                          </span>{" "}
                          Les documents en noir et blanc ne seront pas acceptés.
                        </p>
                        <p className="profile-text">
                          <span style={{ fontWeight: "600" }}>
                            2. Complet :
                          </span>{" "}
                          Assurez vous qu’aucune information ne soit masqué.
                        </p>
                        <p className="profile-text">
                          <span style={{ fontWeight: "600" }}>
                            3. Lisible et de bonne qualité :
                          </span>{" "}
                          Ni flou, ni sombre, ni abîmé et sans reflet.
                        </p>
                      </div>
                    </Grid>
                    <Grid xs={12} item style={{ display: "flex" }}>
                      <CustomButton
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#fff",
                          fontFamily: "Raleway-Variable",
                          background: "#ee6931",
                          border: "none",
                          borderRadius: "12px",
                          padding: "10px 40px 10px 40px",
                          marginLeft: "10px",
                        }}
                        value={"Commencer la verification"}
                        onClick={handleStartVerification}
                      />
                    </Grid>
                  </Grid>
                )}
              </div>

              <div
                style={{
                  background: color_theme.white,
                  borderRadius: 16,
                  marginTop: 16,
                  padding: 16,
                }}
              >
                <Grid container spacing={2}>
                  <Grid xs={12} item>
                    <h2
                      style={{
                        fontFamily: "Raleway-Extra-Bold",
                        fontWeight: 500,
                      }}
                    >
                      {strings?.settings}
                    </h2>
                  </Grid>

                  <Grid xs={12} item>
                    <div style={{ display: "flex", width: "100%" }}>
                      <div style={{ flex: 1 }}>
                        <h2 className="profile-text">{strings?.account}</h2>
                      </div>
                      <div>
                        <p
                          className="profile-text"
                          style={{
                            color: "#ee6931",
                            fontFamily: "Raleway-Bold",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setState({ openForgetPasswordDialog: true });
                          }}
                        >
                          {strings?.change_password}
                        </p>
                      </div>
                    </div>

                    <div style={{ display: "none", width: "100%" }}>
                      <div style={{ flex: 1 }}>
                        <p className="profile-text">{strings?.notification}</p>
                      </div>
                      <div>
                        <CustomSwitch />
                      </div>
                    </div>

                    <div style={{ display: "flex", width: "100%" }}>
                      <div style={{ flex: 1 }}>
                        <p className="profile-text">{strings?.payments}</p>
                      </div>
                      <div>
                        <p
                          className="profile-text"
                          style={{
                            color: "#ee6931",
                            cursor: "pointer",
                            fontFamily: "Raleway-Bold",
                          }}
                          onClick={handleInitStripeSetupPayment}
                        >
                          {strings?.view}
                        </p>
                      </div>
                    </div>

                    <div style={{ display: "flex", width: "100%" }}>
                      <div style={{ flex: 1 }}>
                        <p className="profile-text">{strings?.language}</p>
                      </div>
                      <div>
                        <p
                          className="profile-text"
                          style={{
                            fontFamily: "Raleway-Bold",
                            color: "#ee6931",
                            cursor: "pointer",
                          }}
                          onClick={handleClickForLang}
                        >
                          Changer
                        </p>
                      </div>
                      <Menu
                        id="account-menu"
                        MenuListProps={{
                          "aria-labelledby": "fade-button",
                        }}
                        anchorEl={anchorEN}
                        open={openForLang}
                        onClose={handleCloseForLang}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            // width: "200px",
                            borderRadius: "12px",
                            overflow: "visible",
                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                            mt: 1.5,
                            "& .MuiAvatar-root": {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                            },
                            "&::before": {
                              content: '""',
                              display: "block",
                              position: "absolute",
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              bgcolor: "background.paper",
                              transform: "translateY(-50%) rotate(45deg)",
                              zIndex: 0,
                            },
                          },
                        }}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            dispatch(setUpdateLanguage("fr"));
                            handleCloseForLang();
                          }}
                        >
                          <div className="display-flex" style={{ gap: 10 }}>
                            <img
                              src={frIcon}
                              height={30}
                              width={30}
                              alt="fr-icon"
                            />
                            FR
                          </div>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            dispatch(setUpdateLanguage("en"));
                            handleCloseForLang();
                          }}
                        >
                          <div className="display-flex" style={{ gap: 10 }}>
                            <img
                              src={enIcon}
                              height={20}
                              width={30}
                              alt="en-icon"
                            />
                            EN
                          </div>
                        </MenuItem>
                      </Menu>
                    </div>

                    <div style={{ display: "flex", width: "100%" }}>
                      <div style={{ flex: 1 }}>
                        <p className="profile-text">Conditions générales</p>
                      </div>
                      <div>
                        <p
                          className="profile-text"
                          style={{
                            fontFamily: "Raleway-Bold",
                            color: "#ee6931",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            window.open(
                              "https://frankme.com/terms-and-condition"
                            );
                          }}
                        >
                          {strings?.view}
                        </p>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
              <Grid
                container
                spacing={1}
                style={{ columnGap: "50px", height: "100vh" }}
              >
                <Grid
                  xs={12}
                  md={7}
                  lg={7}
                  item
                  style={{
                    background: "#fff",
                    borderRadius: "16px",
                    padding: "20px",
                    // marginRight:'10px'
                  }}
                >
                  <Grid container spacing={2} style={{ maxHeight: "90vh" }}>
                    <Grid xs={12} item>
                      <h2
                        className="profile-headings"
                        style={{
                          fontSize: "24px",
                          fontWeight: 500,
                          fontFamily: "Raleway-Extra-Bold",
                        }}
                      >
                        Mes gains
                      </h2>
                    </Grid>
                    <div
                      style={{
                        overflowY: `${
                          state?.x?.length > 6 ? "scroll" : "hidden"
                        }`,
                        maxHeight: "85vh",
                        width: "100%",
                      }}
                    >
                      {state?.x?.length < 1 ? (
                        <div
                          style={{
                            width: "100%",
                            marginTop: "5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={noTransaction}
                            alt="no-transactions"
                            style={{
                              width: "90%",
                              objectFit: "contain",
                              opacity: "0.5",
                            }}
                          />
                        </div>
                      ) : (
                        state?.x
                          ?.slice(0, state?.earningLength)
                          ?.map((i: any, index: any) => {
                            return (
                              <>
                                <Grid
                                  xs={12}
                                  item
                                  style={{
                                    borderBottom: "1px solid #E0E0E0",
                                    margin: "0px 20px 0px 20px",
                                  }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <div style={{ flex: 1 }}>
                                      <p
                                        className="profile-headings"
                                        style={{ fontSize: "18px" }}
                                      >
                                        {" "}
                                        {numberFormatWithoutCurrency(
                                          i?.order_amount_detail?.sub_total -
                                            i?.order_amount_detail
                                              ?.commission_amount -
                                            i?.order_amount_detail
                                              ?.commission_tax_amount
                                        ) ?? "0.00"}{" "}
                                        &nbsp; €
                                      </p>
                                      <p className="profile-text">
                                        {" "}
                                        {moment(i?.exp_dropoff_date).format(
                                          "DD/MM/YYYY"
                                        ) ?? "10/02/22"}
                                      </p>
                                    </div>
                                    <div className="display-flex">
                                      <span
                                        style={{
                                          padding: "10px 15px 10px 15px",
                                          borderRadius: "12px",
                                          background:
                                            !i?.is_paid && !i?.stripe_status
                                              ? "#FBACAC"
                                              : "#BBF4D0",
                                          color:
                                            !i?.is_paid && !i?.stripe_status
                                              ? "#B91C1C"
                                              : "darkgreen",
                                          textAlign: "center",
                                          fontFamily: "Raleway-Bold",
                                        }}
                                      >
                                        {!i?.is_paid && !i?.stripe_status
                                          ? "Non payé"
                                          : "Non payé"}
                                      </span>
                                    </div>
                                  </div>
                                </Grid>
                              </>
                            );
                          })
                      )}

                      {state?.earning_data?.length > 6 ? (
                        <Grid xs={12} item className="display-flex">
                          <p
                            className="profile-headings display-flex"
                            style={{
                              color: "#3026f1",
                              textAlign: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setState({
                                earningLength: state?.earningLength + 6,
                              });
                            }}
                          >
                            <RefrestIcon /> Charger plus de transactions
                          </p>
                        </Grid>
                      ) : null}
                    </div>
                  </Grid>
                </Grid>

                <Grid
                  xs={12}
                  md={4}
                  lg={4}
                  item
                  style={{
                    padding: "20px",
                    background: "#fff",
                    borderRadius: "16px",
                    height: "100%",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid xs={12} item>
                      <h2
                        className="profile-headings"
                        style={{
                          fontSize: "24px",
                          fontWeight: 500,
                          fontFamily: "Raleway-Extra-Bold",
                        }}
                      >
                        {strings?.your_wallet}
                      </h2>
                    </Grid>

                    <Grid xs={12} item>
                      <div
                        style={{
                          background: "#F4F3F6",
                          borderRadius: "12px",
                          padding: "30px 20px",
                          maxWidth: "100%",
                          boxSizing: "border-box",
                          textAlign: "center",
                          overflow: "hidden", // Hides overflow
                          wordWrap: "break-word", // Wraps long words to prevent overflow
                        }}
                      >
                        <p
                          style={{
                            fontSize: "2rem", // Responsive font sizing (use rems)
                            fontFamily: "Raleway-Semi-Bold",
                            textAlign: "center",
                            color: "gray",
                            margin: 0, // Remove default paragraph margins
                            lineHeight: "1.2", // Adjust line height for better readability
                          }}
                        >
                          <Tooltip
                            title={"The amount you have received from Frank"}
                          >
                            <span
                              style={{
                                color: defaultLabelColors.main_orange,
                                fontSize: "inherit", // Inherits the parent font size
                              }}
                            >
                              {numberFormatWithoutCurrency(recivedAmount) ??
                                "0,00"}
                              €
                            </span>
                          </Tooltip>
                          /
                          <Tooltip
                            title={"The amount you will receive from Frank"}
                          >
                            <span
                              style={{
                                color: defaultLabelColors.main_blue,
                                fontSize: "inherit", // Inherits the parent font size
                              }}
                            >
                              {numberFormatWithoutCurrency(upcomingAmount) ??
                                "0,00"}
                              €
                            </span>
                          </Tooltip>
                        </p>
                      </div>
                    </Grid>
                    <Grid xs={12} item>
                      {state?.bankDetails ? (
                        <>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: 7,
                              fontFamily: "Raleway-Variable",
                              cursor: "pointer",
                              marginBottom: "5px",
                            }}
                            onClick={handleLoginStripe}
                          >
                            Link Bank Accounts{" "}
                            <OpenInNew
                              fontSize="small"
                              style={{
                                color: defaultLabelColors.main_orange,
                              }}
                            />
                          </div>
                          {state?.bankDetails?.map((i: any) => {
                            return (
                              <div style={{ width: "100% " }}>
                                <BankCard
                                  BankName={i?.bank_name}
                                  cardNumber={i?.last4}
                                  AcountName={i?.account_holder_name}
                                />
                              </div>
                            );
                          })}
                          <CustomButton
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#fff",
                              fontFamily: "Raleway-Variable",
                              background: "#ee6931",
                              border: "none",
                              borderRadius: "12px",
                              padding: "10px 40px 10px 40px",
                              width: "100%",
                              marginTop: "10px",
                            }}
                            value={"Transfer"}
                            disable={state?.transfer_btn}
                            onClick={handleMakeTransferRequest}
                          />{" "}
                        </>
                      ) : (
                        <div
                          style={{
                            paddingTop: "40px",
                            position: "relative",
                            zIndex: 111,
                            flexDirection: "column",
                          }}
                          className="display-flex"
                        >
                          <img
                            style={{
                              position: "absolute",
                              width: "100%",
                              height: "220px",
                            }}
                            src={orangeBackground}
                            alt=""
                          />
                          <p
                            style={{
                              fontSize: "22px",
                              fontFamily: "Raleway-Extra-Bold",
                              color: "#fff",
                              zIndex: 111,
                            }}
                          >
                            Merci Frank
                          </p>

                          <CustomButton
                            style={{
                              background: "#fff",
                              color: "#ee6931",
                              fontSize: "16px",
                              fontFamily: "Raleway-Variable",
                              border: "none",
                              borderRadius: "12px",
                              // padding: "10px 10px 10px 10px",
                              // width: "max-content",
                              // maxWidth: "450px",
                              height: "50px",
                            }}
                            value={"Transférer vers un compte bancaire"}
                            onClick={() => {
                              setState({
                                loadingLoader: true,
                              });
                              handleAddBank();
                            }}
                          />
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={2}>
              <Grid container spacing={2}>
                <Grid xs={12} md={4} item>
                  <div
                    style={{
                      background: color_theme.white,
                      borderRadius: 16,
                    }}
                  >
                    <Box
                      sx={{
                        flexGrow: 1,
                        bgcolor: "background.paper",
                        display: "flex",
                        height: 224,
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={verticalTabValue}
                        onChange={handleVerticalTabChange}
                        aria-label="Vertical profile tabs"
                        TabIndicatorProps={{ style: { display: "none" } }}
                        sx={{
                          borderRight: 1,
                          borderColor: "divider",
                          width: "100%",
                          paddingLeft: "20px",
                          paddingRight: "20px",
                        }}
                      >
                        <Tab
                          sx={{
                            textTransform: "inherit",
                            fontSize: "14px",
                            color: "#000",
                            fontFamily: "Raleway-Semi-Bold",
                            textAlign: "left", // Align text to the left
                            alignItems: "flex-start", // Align tab to start
                            justifyContent: "flex-start", // Justify tab to start
                            "&.Mui-selected": {
                              borderBottom: "3px solid #ee6931", // Blue underline for selected tab
                              color: "#ee6931",
                              fontFamily: "Raleway-Variable",
                            },
                          }}
                          label="Envoyer un colis  ·  Mes annonces "
                          {...verticalA11yProps(0)}
                        />
                        <Tab
                          sx={{
                            textTransform: "capitalize",
                            fontSize: "14px",
                            color: "#000",
                            fontFamily: "Raleway-Semi-Bold",
                            textAlign: "left", // Align text to the left
                            alignItems: "flex-start", // Align tab to start
                            justifyContent: "flex-start", // Justify tab to start
                            "&.Mui-selected": {
                              borderBottom: "3px solid #ee6931", // Blue underline for selected tab
                              color: "#ee6931",
                            },
                          }}
                          label="Transporter un colis  ·  Mes trajets "
                          {...verticalA11yProps(1)}
                        />
                        {/*
                        comment by asad iqbal 1/14/2025 on Ben Demand ( Launching Frank C2C ppt)
                        <Tab
                          sx={{
                            textTransform: "capitalize",
                            fontSize: "14px",
                            color: "#000",
                            fontFamily: "Raleway-Semi-Bold",
                            textAlign: "left", // Align text to the left
                            alignItems: "flex-start", // Align tab to start
                            justifyContent: "flex-start", // Justify tab to start
                            "&.Mui-selected": {
                              borderBottom: "3px solid #ee6931", // Blue underline for selected tab
                              color: "#ee6931",
                            },
                          }}
                          label="Marketplace  ·  Mes achats"
                          {...verticalA11yProps(2)}
                        />
                        <Tab
                          sx={{
                            textTransform: "capitalize",
                            fontSize: "14px",
                            color: "#000",
                            fontFamily: "Raleway-Semi-Bold",
                            textAlign: "left", // Align text to the left
                            alignItems: "flex-start", // Align tab to start
                            justifyContent: "flex-start", // Justify tab to start
                            "&.Mui-selected": {
                              borderBottom: "3px solid #ee6931", // Blue underline for selected tab
                              color: "#ee6931",
                            },
                          }}
                          label="Marketplace  ·  Mes ventes"
                          {...verticalA11yProps(3)}
                        /> */}
                      </Tabs>
                    </Box>
                  </div>
                </Grid>

                <Grid xs={12} md={8} item>
                  <div
                    style={{ background: color_theme.white, borderRadius: 16 }}
                  >
                    <VerticalTabPanel value={verticalTabValue} index={0}>
                      <div className={classes?.scrollableArea}>
                        <div
                          style={{
                            fontFamily: "Raleway-Extra-Bold",
                            fontSize: "16px",
                            color: defaultLabelColors.main_orange,
                          }}
                        >
                          Envoyer un colis · Mes annonces
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            rowGap: 14,
                          }}
                        >
                          {state?.deliveries
                            ?.slice(0, state?.numbrOfAnnouceResult)
                            .map((i: any) => {
                              return (
                                <>
                                  {i?.shipment_items?.length > 1 ? (
                                    <div
                                      style={{
                                        marginTop: "5px",
                                        alignItems: "center",
                                        backgroundColor: " #fff",
                                        borderRadius: 12,
                                        fontFamily: "Raleway-Variable",
                                        cursor: "pointer",
                                        minHeight: "100px",
                                        border: "1px solid #D9D9D9",
                                        padding: 0,
                                      }}
                                    >
                                      <Accordion
                                        elevation={0}
                                        style={{
                                          minHeight: "100px",
                                          padding: 0,
                                          margin: 0,
                                        }}
                                      >
                                        <AccordionSummary
                                          style={{
                                            margin: `0px !important`,
                                            width: "100%",
                                          }}
                                          sx={{
                                            "& .MuiAccordionSummary-expandIconWrapper":
                                              {
                                                marginTop: -6,
                                              },
                                          }}
                                          expandIcon={<ExpandMore />}
                                          aria-controls="panel1-content"
                                          id="panel1-header"
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              borderRadius: 12,
                                              fontFamily: "Raleway-Variable",
                                              cursor: "pointer",
                                              width: "100%",
                                            }}
                                          >
                                            <div
                                              className="card-details"
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                rowGap: 4,
                                              }}
                                            >
                                              <div
                                                style={{
                                                  fontFamily:
                                                    "Raleway-Variable",
                                                  fontSize: 14,
                                                }}
                                              >
                                                Lot de{" "}
                                                {i?.shipment_items?.length ?? 1}{" "}
                                                colis - N°-{i?.id}
                                              </div>

                                              <div
                                                className="card-title"
                                                style={{
                                                  fontSize: 16,
                                                  display: "flex",
                                                  flex: 1,
                                                  fontFamily:
                                                    "Raleway-Semi-Bold",
                                                }}
                                              >
                                                Total proposé : {i?.ship_price}
                                              </div>
                                              <div
                                                className="card-dimensions"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  fontSize: "16px",
                                                  color: "#000",
                                                }}
                                              >
                                                <div>
                                                  De &nbsp;
                                                  {i?.dropoff?.city ?? "city"}
                                                </div>
                                                &nbsp; - &nbsp;
                                                <div>{i?.pickup?.city}</div>
                                              </div>
                                            </div>
                                            <div>
                                              <div
                                                style={{
                                                  borderRadius: "4px",
                                                  padding: "5px",
                                                  fontFamily:
                                                    "Raleway-Semi-Bold",
                                                  fontSize: 12,
                                                  background:
                                                    getStatusColors[
                                                      i?.ship_status
                                                    ]?.bg,
                                                  color:
                                                    getStatusColors[
                                                      i?.ship_status
                                                    ]?.txt,
                                                }}
                                              >
                                                {handleLocalize(
                                                  status_translations[
                                                    i?.ship_status
                                                  ]
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </AccordionSummary>
                                        <AccordionDetails
                                          style={{
                                            paddingLeft: 20,

                                            rowGap: 16,
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                        >
                                          {i?.shipment_items?.map((j: any) => {
                                            return (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  fontFamily:
                                                    "Raleway-Variable",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                  navigate(`/anounce/${i?.id}`);
                                                }}
                                              >
                                                <img
                                                  src={j?.prod_images[0]}
                                                  style={{
                                                    height: "90px",
                                                    width: "100px",
                                                    marginRight: "16px",
                                                    objectFit: "cover",
                                                    borderRadius: 16,
                                                  }}
                                                  alt="prod-img"
                                                />
                                                <div
                                                  className="card-details"
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    rowGap: 4,
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      fontFamily:
                                                        "Raleway-Variable",
                                                      fontSize: 14,
                                                    }}
                                                  >
                                                    {transSizes(j)}
                                                  </div>
                                                  <div
                                                    className="card-title"
                                                    style={{
                                                      fontFamily:
                                                        "Raleway-Semi-Bold",
                                                      fontSize: 16,
                                                    }}
                                                  >
                                                    {handleLocalize({
                                                      fr: j?.item_name_fr,
                                                      en: j?.item_name_en,
                                                    }) ??
                                                      j?.prod_name ??
                                                      ""}
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          })}
                                        </AccordionDetails>
                                      </Accordion>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        backgroundColor: " #fff",
                                        borderRadius: 12,
                                        padding: "20px",
                                        fontFamily: "Raleway-Variable",
                                        cursor: "pointer",
                                        minHeight: "100px",
                                        border: "1px solid #D9D9D9",
                                      }}
                                      onClick={() => {
                                        navigate(`/anounce/${i?.id}`);
                                      }}
                                    >
                                      <img
                                        src={
                                          i?.shipment_items[0]?.prod_images[0]
                                        }
                                        style={{
                                          height: "90px",
                                          width: "100px",
                                          marginRight: "16px",
                                          objectFit: "cover",
                                          borderRadius: 16,
                                        }}
                                        alt="prod-img"
                                      />
                                      <div
                                        className="card-details"
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          rowGap: 4,
                                        }}
                                      >
                                        <div
                                          style={{
                                            fontFamily: "Raleway-Variable",
                                            fontSize: 14,
                                          }}
                                        >
                                          N°-{i?.id} - Taile :{" "}
                                          {transSizes(i?.shipment_items?.[0])}
                                        </div>
                                        <div
                                          className="card-title"
                                          style={{
                                            fontFamily: "Raleway-Semi-Bold",
                                            fontSize: 16,
                                          }}
                                        >
                                          {handleLocalize({
                                            fr: i?.shipment_items[0]
                                              ?.item_name_fr,
                                            en: i?.shipment_items[0]
                                              ?.item_name_en,
                                          }) ??
                                            i?.shipment_items[0]?.prod_name ??
                                            ""}{" "}
                                        </div>
                                        {/* <div className="card-dimensions">
                                        {moment(i?.created_at).format(
                                          "DD/MM/YYYY"
                                        ) ?? ""}
                                      </div> */}
                                        <div
                                          className="card-title"
                                          style={{
                                            fontSize: 16,
                                            display: "flex",
                                            flex: 1,
                                            fontFamily: "Raleway-Semi-Bold",
                                          }}
                                        >
                                          Total proposé : {i?.ship_price}
                                        </div>
                                        <div
                                          className="card-dimensions"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            fontSize: "16px",
                                            color: "#000",
                                          }}
                                        >
                                          <div>
                                            De &nbsp;
                                            {i?.dropoff?.city ?? "city"}
                                          </div>
                                          &nbsp; - &nbsp;
                                          <div>{i?.pickup?.city}</div>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          borderRadius: "4px",
                                          padding: "5px",
                                          fontFamily: "Raleway-Semi-Bold",
                                          fontSize: 12,
                                          background:
                                            getStatusColors[i?.ship_status]?.bg,
                                          color:
                                            getStatusColors[i?.ship_status]
                                              ?.txt,
                                        }}
                                      >
                                        {handleLocalize(
                                          status_translations[i?.ship_status]
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </>
                              );
                            })}
                          {state?.deliveries?.length < 6 ||
                          state?.deliveries?.length < 1 ? null : (
                            <div
                              style={{
                                textAlign: "center",
                                fontFamily: "Raleway-Variable",
                                color: defaultLabelColors.main_blue,
                                fontSize: "18px",
                                fontWeight: "600",
                                // padding: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: 10,
                                marginTop: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setState({
                                  numbrOfAnnouceResult:
                                    state?.numbrOfAnnouceResult + 5,
                                });
                              }}
                            >
                              <RefrestIcon
                                fill={defaultLabelColors.main_blue}
                              />{" "}
                              Charger plus de transactions
                            </div>
                          )}
                        </div>
                      </div>
                    </VerticalTabPanel>
                    <VerticalTabPanel value={verticalTabValue} index={1}>
                      <div className={classes?.scrollableArea}>
                        <div
                          style={{
                            fontFamily: "Raleway-Extra-Bold",
                            fontSize: "16px",
                            color: defaultLabelColors.main_orange,
                          }}
                        >
                          Transporter un colis · Mes trajets
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            rowGap: 14,
                          }}
                        >
                          {state?.transporterJobs
                            ?.slice(0, state?.numbrOfjobResult)
                            .map((i: any) => {
                              return (
                                <>
                                  {i?.shipment_items?.length > 1 ? (
                                    <>
                                      <div
                                        style={{
                                          marginTop: "5px",
                                          alignItems: "center",
                                          backgroundColor: " #fff",
                                          borderRadius: 12,
                                          fontFamily: "Raleway-Variable",
                                          cursor: "pointer",
                                          minHeight: "100px",
                                          border: "1px solid #D9D9D9",
                                          padding: 0,
                                        }}
                                      >
                                        <Accordion
                                          elevation={0}
                                          style={{
                                            minHeight: "100px",
                                            padding: 0,
                                            margin: 0,
                                          }}
                                        >
                                          <AccordionSummary
                                            style={{
                                              margin: `0px !important`,
                                              width: "100%",
                                            }}
                                            sx={{
                                              "& .MuiAccordionSummary-expandIconWrapper":
                                                {
                                                  marginTop: -6,
                                                },
                                            }}
                                            expandIcon={<ExpandMore />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                borderRadius: 12,
                                                fontFamily: "Raleway-Variable",
                                                cursor: "pointer",
                                                width: "100%",
                                              }}
                                            >
                                              <div
                                                className="card-details"
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  rowGap: 4,
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    fontFamily:
                                                      "Raleway-Variable",
                                                    fontSize: 14,
                                                  }}
                                                >
                                                  Lot de{" "}
                                                  {`(${i?.shipment_items?.length})`}{" "}
                                                  colis - N°-{i?.id}
                                                </div>
                                                <div
                                                  style={{
                                                    fontFamily:
                                                      "Raleway-Semi-Bold",
                                                    fontSize: 14,
                                                  }}
                                                >
                                                  Total à recevoir :{" "}
                                                  {i?.agree_ship_price
                                                    ? i?.agree_ship_price
                                                    : i?.ship_price ?? "0"}{" "}
                                                  €
                                                </div>

                                                <div
                                                  className="card-dimensions"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    fontSize: "16px",
                                                    color: "#000",
                                                  }}
                                                >
                                                  <div>
                                                    De &nbsp;
                                                    {i?.dropoff?.city ?? "city"}
                                                  </div>
                                                  - &nbsp;
                                                  <div>
                                                    à &nbsp;
                                                    {i?.pickup?.city}
                                                  </div>
                                                </div>
                                              </div>
                                              <div>
                                                <div
                                                  style={{
                                                    borderRadius: "4px",
                                                    padding: "5px",
                                                    fontFamily:
                                                      "Raleway-Semi-Bold",
                                                    fontSize: 12,
                                                    background:
                                                      getStatusColors[
                                                        i?.ship_status
                                                      ]?.bg,
                                                    color:
                                                      getStatusColors[
                                                        i?.ship_status
                                                      ]?.txt,
                                                  }}
                                                >
                                                  {handleLocalize(
                                                    status_translations[
                                                      i?.ship_status
                                                    ]
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </AccordionSummary>
                                          <AccordionDetails
                                            style={{
                                              paddingLeft: 20,

                                              rowGap: 16,
                                              display: "flex",
                                              flexDirection: "column",
                                            }}
                                          >
                                            {i?.shipment_items?.map(
                                              (j: any) => {
                                                return (
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      fontFamily:
                                                        "Raleway-Variable",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                      navigate(`/job/${i?.id}`);
                                                    }}
                                                  >
                                                    <img
                                                      src={j?.prod_images[0]}
                                                      style={{
                                                        height: "90px",
                                                        width: "100px",
                                                        marginRight: "16px",
                                                        objectFit: "cover",
                                                        borderRadius: 16,
                                                      }}
                                                      alt="prod-img"
                                                    />
                                                    <div
                                                      className="card-details"
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        rowGap: 4,
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          fontFamily:
                                                            "Raleway-Variable",
                                                          fontSize: 14,
                                                        }}
                                                      >
                                                        {transSizes(j)}
                                                      </div>
                                                      <div
                                                        className="card-title"
                                                        style={{
                                                          fontFamily:
                                                            "Raleway-Semi-Bold",
                                                          fontSize: 16,
                                                        }}
                                                      >
                                                        {handleLocalize({
                                                          fr: j?.item_name_fr,
                                                          en: j?.item_name_en,
                                                        }) ??
                                                          j?.prod_name ??
                                                          ""}
                                                      </div>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </AccordionDetails>
                                        </Accordion>
                                        ;
                                      </div>
                                    </>
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        backgroundColor: " #fff",
                                        borderRadius: 12,
                                        padding: "20px",
                                        fontFamily: "Raleway-Variable",
                                        cursor: "pointer",
                                        minHeight: "100px",
                                        border: "1px solid #D9D9D9",
                                      }}
                                      onClick={() => {
                                        navigate(`/job/${i?.id}`);
                                      }}
                                    >
                                      <img
                                        src={
                                          i?.shipment_items[0]?.prod_images[0]
                                        }
                                        style={{
                                          height: "90px",
                                          width: "100px",
                                          marginRight: "16px",
                                          objectFit: "cover",
                                        }}
                                        alt="prod-img"
                                      />
                                      <div className="card-details">
                                        <div
                                          style={{
                                            fontFamily: "Raleway-Variable",
                                            fontSize: 16,
                                          }}
                                        >
                                          N°-{i?.id} - Taile :{" "}
                                          {transSizes(i?.shipment_items?.[0])}
                                        </div>
                                        <div
                                          className="card-title"
                                          style={{
                                            fontFamily: "Raleway-Semi-Bold",
                                            fontSize: 16,
                                          }}
                                        >
                                          {handleLocalize({
                                            fr: i?.shipment_items[0]
                                              ?.item_name_fr,
                                            en: i?.shipment_items[0]
                                              ?.item_name_en,
                                          }) ??
                                            i?.shipment_items[0]?.prod_name ??
                                            ""}{" "}
                                        </div>
                                        {/* <div className="card-dimensions">
                                        {moment(i?.created_at).format(
                                          "DD/MM/YYYY"
                                        ) ?? ""}
                                      </div> */}
                                        <div
                                          style={{
                                            fontFamily: "Raleway-Semi-Bold",
                                            fontSize: 14,
                                          }}
                                        >
                                          Total à recevoir :{" "}
                                          {i?.agree_ship_price
                                            ? i?.agree_ship_price
                                            : i?.ship_price ?? "0"}{" "}
                                          €
                                        </div>
                                        <div
                                          className="card-dimensions"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div>
                                            De &nbsp;
                                            {i?.dropoff?.city ?? "city"}
                                          </div>
                                          - &nbsp;
                                          <div>
                                            à &nbsp;
                                            {i?.pickup?.city}
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          borderRadius: "4px",
                                          padding: "5px",
                                          fontFamily: "Raleway-Semi-Bold",
                                          fontSize: 12,
                                          background:
                                            getStatusColors[i?.ship_status]?.bg,
                                          color:
                                            getStatusColors[i?.ship_status]
                                              ?.txt,
                                        }}
                                      >
                                        {handleLocalize(
                                          status_translations[i?.ship_status]
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </>
                              );
                            })}
                        </div>
                        {state?.transporterJobs?.length < 6 ||
                        state?.transporterJobs?.length < 1 ? null : (
                          <div
                            style={{
                              textAlign: "center",
                              fontFamily: "Raleway-Variable",
                              color: defaultLabelColors.main_blue,
                              fontSize: "18px",
                              fontWeight: "600",
                              // padding: "20px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: 10,
                              cursor: "pointer",
                              marginTop: "10px",
                            }}
                            onClick={() => {
                              setState({
                                numbrOfjobResult: state?.numbrOfjobResult + 5,
                              });
                            }}
                          >
                            <RefrestIcon fill={defaultLabelColors.main_blue} />{" "}
                            Charger plus de transactions
                          </div>
                        )}
                      </div>
                    </VerticalTabPanel>
                    <VerticalTabPanel value={verticalTabValue} index={2}>
                      <div
                        className={classes.scrollableArea}
                        style={{ maxHeight: "600px", overflowY: "scroll" }}
                      >
                        <div
                          style={{
                            fontFamily: "Raleway-Extra-Bold",
                            fontSize: "16px",
                            color: defaultLabelColors.main_orange,
                          }}
                        >
                          Marketplace · Mes achats
                        </div>

                        {state?.unPaid_orders?.map(
                          (un_item: any, unp_idx: number) => {
                            return (
                              <div
                                style={{
                                  borderRadius: 16,
                                  backgroundColor: color_theme.card_background,
                                  padding: 10,
                                  borderColor: color_theme.black_20,
                                  borderWidth: 1,
                                  gap: 14,
                                }}
                                key={`UNPAID_ORDER_${unp_idx + 1}`}
                              >
                                <div
                                  style={{
                                    padding: 10,
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: 18,
                                      fontFamily: "Raleway-Bold",
                                      color: color_theme.dark_blue,
                                      margin: 0,
                                    }}
                                  >
                                    Payment Due
                                  </p>
                                  <div
                                    style={{
                                      alignItems: "flex-end",
                                      rowGap: 4,
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: 12,
                                        fontFamily: "Raleway-Medium",
                                        margin: 0,
                                      }}
                                    >
                                      Expires at
                                    </p>
                                    <p
                                      style={{
                                        fontSize: 12,
                                        fontFamily: "Raleway-Medium",
                                        color: color_theme.main_orange,
                                        margin: 0,
                                      }}
                                    >
                                      {un_item?.[0]?.expire_at
                                        ? moment(
                                            `${un_item?.[0]?.expire_at}`.endsWith(
                                              "Z"
                                            )
                                              ? un_item?.[0]?.expire_at
                                              : un_item?.[0]?.expire_at + "Z"
                                          ).format("DD/MM/YYYY HH:mm")
                                        : "N/A"}
                                    </p>
                                  </div>
                                </div>

                                <div
                                  style={{
                                    gap: 16,
                                  }}
                                >
                                  {un_item?.map((item: any, index: number) => (
                                    <div
                                      key={
                                        "UNPAID_ORDER_HISTORY_ITEM_" + item.id
                                      }
                                      style={{
                                        borderRadius: 16,
                                        backgroundColor: color_theme.white,
                                        borderWidth: 1,
                                        borderColor: color_theme.card_border,
                                        padding: 10,
                                        gap: 16,
                                      }}
                                      // onClick={async () => {}}
                                    >
                                      <div
                                        style={{
                                          flexDirection: "row",
                                          columnGap: 10,
                                          alignItems: "center",
                                          display: "flex",
                                        }}
                                      >
                                        <img
                                          src={
                                            item?.shop_details?.image ??
                                            item?.items?.[0]?.profile_pic ??
                                            "https://www.iconpacks.net/icons/2/free-store-icon-1977-thumb.png"
                                          }
                                          style={{
                                            height: 30,
                                            width: 30,
                                            objectFit: "contain",
                                            borderRadius: 25,
                                            borderWidth: 1,
                                            borderColor: color_theme.black_40,
                                          }}
                                        />
                                        <p
                                          style={{
                                            fontSize: 16,
                                            fontFamily: "Raleway-Semi-Bold",
                                            color: color_theme.main_orange,
                                            textTransform: "capitalize",
                                            margin: 0,
                                          }}
                                        >
                                          {item?.shop_details?.shop_name ??
                                            item?.shop?.shop_name ??
                                            item?.items?.[0]?.added_by ??
                                            "N/A"}
                                        </p>
                                      </div>

                                      {item?.items?.map(
                                        (i: any, prod_idx: any) => (
                                          <div
                                            style={{
                                              marginTop: "5px",
                                              display: "flex",
                                              alignItems: "center",
                                              backgroundColor: " #fff",
                                              borderRadius: "16px",
                                              padding: "8px",
                                              fontFamily: "Raleway-Variable",
                                              cursor: "pointer",
                                              minHeight: "100px",
                                            }}
                                            onClick={() => {
                                              // handleTrackOrder(i);
                                            }}
                                          >
                                            <img
                                              src={
                                                i?.images?.[0] ?? no_image_url
                                              }
                                              style={{
                                                height: "80px",
                                                width: "80px",
                                                borderRadius: 12,
                                                marginRight: "16px",
                                                objectFit: "contain",
                                              }}
                                              alt="prod-img"
                                            />
                                            <div className="card-details">
                                              <div
                                                style={{
                                                  fontFamily:
                                                    "Raleway-Variable",
                                                  fontSize: 14,
                                                  display: "flex",
                                                  color: "#000",
                                                }}
                                              >
                                                N°-{un_item?.[0]?.id} -{" "}
                                                {handleLocalize(
                                                  un_item?.[0]
                                                    ?.service_method_name
                                                )}
                                              </div>
                                              <div
                                                className="card-title"
                                                style={{
                                                  fontFamily: "Raleway-Bold",
                                                  fontSize: 16,
                                                }}
                                              >
                                                {handleLocalize(
                                                  item?.product_title
                                                )}
                                              </div>

                                              <div
                                                style={{
                                                  fontFamily: "Raleway-Bold",
                                                  fontSize: 14,
                                                }}
                                              >
                                                {currencyFormat(
                                                  Number(
                                                    un_item?.[0]?.promo
                                                      ? un_item?.[0]
                                                          ?.total_product_price
                                                        ? Number(
                                                            un_item?.[0]
                                                              ?.total_product_price -
                                                              un_item?.[0]
                                                                ?.promo
                                                                ?.discount_amount
                                                          )
                                                        : Number(
                                                            un_item?.[0]
                                                              ?.item?.[0]
                                                              ?.total_price ?? 0
                                                          ) -
                                                          Number(
                                                            un_item?.[0]?.promo
                                                              ?.discount_amount
                                                          )
                                                      : un_item?.[0]
                                                          ?.total_product_price
                                                      ? Number(
                                                          un_item?.[0]
                                                            ?.total_product_price
                                                        )
                                                      : Number(
                                                          un_item?.[0]
                                                            ?.item?.[0]
                                                            ?.total_price ?? 0
                                                        )
                                                  )
                                                )}{" "}
                                                €
                                              </div>
                                            </div>
                                            <div
                                              style={{
                                                borderRadius: 4,
                                                padding: "4px 8px",
                                                fontFamily: "Raleway",
                                                fontSize: 12,
                                                background:
                                                  getStatusColors["Accepted"]
                                                    ?.bg,
                                                color:
                                                  getStatusColors["Accepted"]
                                                    ?.txt,
                                              }}
                                            >
                                              Payment Due
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  ))}
                                  <button
                                    style={{
                                      padding: 10,
                                      backgroundColor: color_theme.main_orange,
                                      borderRadius: 6,
                                      placeContent: "center",
                                      display: "flex",
                                      placeItems: "center",
                                      marginTop: 15,
                                      fontSize: 14,
                                      fontFamily: "Raleway-Semi-Bold",
                                      lineHeight: "18px",
                                      color: color_theme.white,
                                      width: "100%",
                                      cursor: "pointer",
                                      outline: "none",
                                      border: "none",
                                    }}
                                    type="button"
                                    onClick={async () => {
                                      try {
                                        if (un_item?.[0]?.pi_key) {
                                          let decryptData = JSON.parse(
                                            decrypt(un_item?.[0]?.pi_key)
                                          );

                                          if (typeof decryptData === "string") {
                                            decryptData =
                                              JSON.parse(decryptData);
                                          }

                                          const {
                                            setupIntent,
                                            paymentIntent,
                                            ephemeralKey,
                                            customer,
                                          } = decryptData ?? {};

                                          setState({
                                            openFuturePayment: true,
                                            stripe_type: "order_payment",
                                            stripe_form: {
                                              paymentIntent,
                                              is_particular:
                                                un_item?.[0]?.is_particular,
                                              tr_id:
                                                un_item?.[0]?.is_particular ===
                                                true
                                                  ? Number(un_item?.[0]?.id)
                                                  : un_item?.[0]
                                                      ?.order_transaction_id,
                                            },
                                          });
                                        } else {
                                          let d = un_item[0];
                                          let total_amount =
                                            Number(
                                              d?.total_product_price ?? 0
                                            ) +
                                            Number(d?.delivery_fee ?? 0) +
                                            Number(
                                              d?.customer_commission_amount ??
                                                d?.platform_fee ??
                                                1.99
                                            );
                                          const response =
                                            await apiServices.postFromApi(
                                              "stripe/payment-sheet-with-card-frankShop",
                                              {
                                                amount: Number(total_amount),
                                                transaction_id: Number(
                                                  d?.id ?? 0
                                                ),
                                                machine_detail:
                                                  await handleGetMachineDetails(),
                                              },
                                              ""
                                            );
                                          let decryptData = JSON.parse(
                                            decrypt(response?.pi_key)
                                          );

                                          if (typeof decryptData === "string") {
                                            decryptData =
                                              JSON.parse(decryptData);
                                          }

                                          const {
                                            setupIntent,
                                            paymentIntent,
                                            ephemeralKey,
                                            customer,
                                          } = decryptData ?? {};

                                          setState({
                                            openFuturePayment: true,
                                            stripe_type: "order_payment",
                                            stripe_form: {
                                              paymentIntent,
                                              is_particular: d?.is_particular,
                                              tr_id:
                                                un_item?.[0]?.is_particular ===
                                                true
                                                  ? Number(un_item?.[0]?.id)
                                                  : un_item?.[0]
                                                      ?.order_transaction_id,
                                            },
                                          });
                                        }
                                      } catch (error) {
                                        console.log(error);
                                      }
                                    }}
                                    // disabled={!props.stripe}
                                  >
                                    Pay Now
                                  </button>
                                </div>
                              </div>
                            );
                          }
                        )}
                        {state.unPaid_orders?.length > 0 && (
                          <div style={{ marginTop: 10 }}>{FrankDivider()}</div>
                        )}

                        {state?.order_history?.map((i: any) => {
                          return (
                            <>
                              <div
                                style={{
                                  marginTop: "5px",
                                  display: "flex",
                                  alignItems: "center",
                                  backgroundColor: " #fff",
                                  borderRadius: "16px",
                                  padding: "8px",
                                  fontFamily: "Raleway-Variable",
                                  cursor: "pointer",
                                  minHeight: "100px",
                                }}
                                onClick={() => {
                                  handleTrackOrder(i);
                                }}
                              >
                                <img
                                  src={i?.items?.[0]?.images?.[0]}
                                  style={{
                                    height: "80px",
                                    width: "80px",
                                    borderRadius: 12,
                                    marginRight: "16px",
                                    objectFit: "contain",
                                  }}
                                  alt="prod-img"
                                />
                                <div className="card-details">
                                  <div
                                    style={{
                                      fontFamily: "Raleway-Variable",
                                      fontSize: 14,
                                      display: "flex",
                                      color: "#000",
                                    }}
                                  >
                                    N°-{i?.id} -{" "}
                                    {handleLocalize({
                                      en: i?.service_method_name?.en,
                                      fr: i?.service_method_name?.fr,
                                    })}{" "}
                                    -{" "}
                                    {i?.is_particular && (
                                      <p
                                        style={{
                                          margin: 0,
                                          fontFamily: "Raleway-Variable",
                                          color: defaultLabelColors.main_orange,
                                          fontSize: 16,
                                        }}
                                      >
                                        Seconde Main
                                      </p>
                                    )}
                                  </div>
                                  <div
                                    className="card-title"
                                    style={{
                                      fontFamily: "Raleway-Bold",
                                      fontSize: 16,
                                    }}
                                  >
                                    {handleLocalize({
                                      fr: i?.items?.[0]?.product_title?.en,
                                      en: i?.item?.[0]?.product_title?.fr,
                                    }) ??
                                      i?.items?.[0]?.product_title?.en ??
                                      ""}{" "}
                                  </div>

                                  <div
                                    style={{
                                      fontFamily: "Raleway-Bold",
                                      fontSize: 14,
                                    }}
                                  >
                                    {currencyFormat(
                                      Number(
                                        i?.promo
                                          ? i?.total_product_price
                                            ? Number(
                                                i?.total_product_price -
                                                  i?.promo?.discount_amount
                                              )
                                            : Number(
                                                i?.item?.[0]?.total_price ?? 0
                                              ) -
                                              Number(i?.promo?.discount_amount)
                                          : i?.total_product_price
                                          ? Number(i?.total_product_price)
                                          : Number(
                                              i?.item?.[0]?.total_price ?? 0
                                            )
                                      )
                                    )}{" "}
                                    €
                                  </div>
                                </div>
                                <div
                                  style={{
                                    borderRadius: 12,
                                    padding: "5px",
                                    fontFamily: "Raleway-Extra-Bold",
                                    fontSize: "16px",
                                    background:
                                      getStatusColors[i?.order_status]?.bg,
                                    color:
                                      getStatusColors[i?.order_status]?.txt,
                                  }}
                                >
                                  {i?.is_draft
                                    ? "Payment Due"
                                    : handleLocalize(
                                        status_translations[i?.order_status]
                                      )}
                                </div>
                              </div>
                              <div
                                style={{
                                  height: "1px",
                                  background: "lightgrey",
                                }}
                              ></div>
                            </>
                          );
                        })}
                      </div>
                    </VerticalTabPanel>
                    <VerticalTabPanel value={verticalTabValue} index={3}>
                      <div
                        className={classes.scrollableArea}
                        style={{ maxHeight: "600px", overflowY: "scroll" }}
                      >
                        <div
                          style={{
                            fontFamily: "Raleway-Extra-Bold",
                            fontSize: "16px",
                            color: defaultLabelColors.main_orange,
                          }}
                        >
                          Marketplace · Mes Ventes
                        </div>
                        {state?.myListing?.map((i: any) => {
                          return (
                            <>
                              <div
                                style={{
                                  marginTop: "5px",
                                  display: "flex",
                                  alignItems: "center",
                                  backgroundColor: " #fff",
                                  borderRadius: "16px",
                                  padding: "8px",
                                  fontFamily: "Raleway-Variable",
                                  cursor: "pointer",
                                  minHeight: "100px",
                                }}
                                onClick={() => {
                                  navigate(`/my-p-product/${i?.id}`);
                                }}
                              >
                                <img
                                  src={i?.images?.[0]}
                                  style={{
                                    height: "76px",
                                    width: "139px",
                                    borderRadius: 12,
                                    marginRight: "16px",
                                    objectFit: "contain",
                                  }}
                                  alt="prod-img"
                                />
                                <div className="card-details">
                                  <div
                                    style={{
                                      fontFamily: "Raleway-Bold",
                                      fontSize: 16,
                                      gap: 5,
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <p
                                      style={{
                                        whiteSpace: "nowrap",
                                        maxWidth: "250px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        margin: 0,
                                      }}
                                    >
                                      {handleLocalize({
                                        en: i?.product_title?.en,
                                        fr: i?.product_title?.fr,
                                      })}
                                    </p>
                                    -
                                    <span>
                                      {numberFormatWithoutCurrencyFixed(
                                        i?.base_price
                                      )}{" "}
                                      €
                                    </span>
                                  </div>

                                  <div
                                    className="card-title"
                                    style={{
                                      fontFamily: "Raleway-Variable",
                                      fontSize: 16,
                                    }}
                                  >
                                    {handleLocalize(i?.brand_name)}
                                  </div>
                                  <div
                                    className="card-title"
                                    style={{
                                      fontFamily: "Raleway-Variable",
                                      fontSize: 16,
                                    }}
                                  >
                                    {moment(i?.created_at).format("DD/MM/YYYY")}
                                  </div>
                                </div>
                                {/* <div
                                  style={{
                                    borderRadius: 12,
                                    padding: "5px",
                                    fontFamily: "Raleway-Extra-Bold",
                                    fontSize: "16px",
                                    background:
                                      getStatusColors[i?.order_status].bg,
                                    color: getStatusColors[i?.order_status].txt,
                                  }}
                                >
                                  {handleLocalize(
                                    status_translations[i?.order_status]
                                  )}
                                </div> */}
                              </div>
                              <div
                                style={{
                                  height: "1px",
                                  background: "lightgrey",
                                }}
                              ></div>
                            </>
                          );
                        })}
                      </div>
                    </VerticalTabPanel>
                  </div>
                </Grid>
              </Grid>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={3}>
              <Grid container>
                <Grid
                  xs={12}
                  item
                  style={{
                    background: "#fff",
                    borderRadius: "16px",
                    height: "100%",
                    padding: 15,
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Raleway-Extra-Bold",
                      fontSize: 48,
                      marginTop: 0,
                      display: "flex",
                      alignItems: "flex-end",
                    }}
                  >
                    {numberFormatWithoutCurrencyFixedOne(
                      state?.userDetails?.rating
                    ) ?? "0.00"}{" "}
                    <span
                      style={{
                        fontSize: "16px",
                        fontFamily: "Raleway-Semi-Bold",
                      }}
                    >
                      {" "}
                      {`( ${state?.allRatings?.ratings2?.length ?? "0"} )`}{" "}
                    </span>
                    <Stack spacing={1}>
                      <Rating
                        style={{ borderRadius: 12 }}
                        name="half-rating-read"
                        defaultValue={state?.userDetails?.rating ?? 0 ?? 2}
                        precision={0.5}
                        readOnly
                        size="large"
                      />
                    </Stack>
                  </p>
                  <div style={{ padding: 20 }}>
                    {" "}
                    {state?.allRatings?.ratings2?.length < 1 ? (
                      <p
                        style={{
                          fontFamily: "Raleway-Semi-Bold",
                          fontSize: 26,
                        }}
                      >
                        No Review Received yet!
                      </p>
                    ) : (
                      state?.allRatings?.ratings2
                        ?.slice(0, state?.initilalTotalFeedBack)
                        ?.map((i: any) => {
                          return (
                            <div
                              style={{
                                width: "100%",
                                borderBottom: "1px solid #E0E0E0",
                                paddingBottom: 10,
                                display: "flex",
                                gap: 10,
                                marginTop: 10,
                                marginBottom: 5,
                              }}
                            >
                              <Box
                                sx={{
                                  position: "relative",
                                  width: 60,
                                  height: 60,
                                  borderRadius: "50%",
                                  overflow: "hidden",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  boxShadow: 3,
                                  border: "1px solid #E0E0E0",
                                }}
                              >
                                <img
                                  src={
                                    i?.profile_pic ??
                                    "https://i.pinimg.com/736x/55/0f/49/550f49a459548599a5a4ea1c67fc0244.jpg"
                                  }
                                  alt="Profile"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                  }}
                                />
                              </Box>
                              <div style={{ flex: 1, paddingTop: 10 }}>
                                <p
                                  style={{
                                    fontFamily: "Raleway-Semi-Bold",
                                    fontSize: 16,
                                    color: defaultLabelColors.main_blue,
                                    margin: 0,
                                  }}
                                >
                                  {i?.added_by ?? ""}
                                </p>
                                <p style={{ margin: 0 }}>
                                  <Stack spacing={1}>
                                    <Rating
                                      style={{ borderRadius: 12 }}
                                      name="half-rating-read"
                                      defaultValue={i?.rating ?? 2}
                                      precision={0.5}
                                      readOnly
                                      size="small"
                                    />
                                  </Stack>
                                </p>
                                <p
                                  style={{
                                    margin: 0,
                                    fontSize: "16px",
                                    fontFamily: "Raleway-Italic",
                                    paddingTop: 5,
                                  }}
                                >
                                  {i?.feedback ?? ""}
                                </p>
                              </div>

                              <div
                                style={{
                                  fontSize: 14,
                                  color: "#757575",
                                  fontFamily: "Raleway-Italic",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {moment(
                                  `${i?.created_at}`.endsWith("Z")
                                    ? i?.created_at
                                    : `${i?.created_at}Z`
                                ).fromNow()}{" "}
                              </div>
                            </div>
                          );
                        })
                    )}
                  </div>
                  {state?.allRatings?.ratings2?.length > 6 ? (
                    <Grid xs={12} item className="display-flex">
                      <p
                        className="profile-headings display-flex"
                        style={{
                          color: "#3026f1",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setState({
                            initilalTotalFeedBack:
                              state?.initilalTotalFeedBack + 6,
                          });
                        }}
                      >
                        <RefrestIcon /> Plus de reviews
                      </p>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </CustomTabPanel>
          </div>
        </div>
        <div
          className="main-container"
          style={{ background: defaultLabelColors.main_background_two }}
        >
          <CustomFooter />
        </div>
        <DraggableDialog
          maxWidth={"md"}
          open={state?.openForgetPasswordDialog}
          handleClose={() => {
            setState({
              openForgetPasswordDialog: false,
              oldPassword: "",
              newPassword: "",
              confirmPassword: "",
            });
          }}
          classes={classes}
          dialogBody={
            <DialogContent
              style={{
                display: "flex",
                background: "#FCE6DD",
                justifyContent: "center",
              }}
              dividers
            >
              <Grid container spacing={2}>
                <Grid
                  xs={12}
                  item
                  className="display-flex"
                  style={{ flexDirection: "column" }}
                >
                  <p
                    className="heading-one"
                    style={{
                      fontFamily: "Raleway-Italic",
                      color: defaultLabelColors.main_blue,
                      textAlign: "center",
                    }}
                  >
                    {strings.change_password}
                  </p>
                  <p
                    className="paragraph-text"
                    style={{
                      marginTop: "-30px",
                      textAlign: "center",
                      fontFamily: "Raleway-Italic",
                      fontWeight: "500",
                      width: "500px",
                    }}
                  ></p>
                </Grid>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Grid xs={12} item>
                    <InputField
                      input_type={
                        state?.viewPasswordOld === false ? "password" : "text"
                      }
                      onchange={onInputChangeTwo}
                      startIcon={<LockOpen />}
                      placeHolder={strings?.old_password}
                      input_value={state?.oldPassword}
                      input_name={"oldPassword"}
                      input_style={{
                        background: "#fff",
                        width: "500px",
                        borderRadius: "16px",
                        border: "none",
                      }}
                      endIicon={
                        state?.viewPasswordOld ? (
                          <Visibility
                            onClick={() => {
                              setState({
                                viewPasswordOld: !state?.viewPasswordOld,
                              });
                            }}
                            style={{ color: "gray", cursor: "pointer" }}
                          />
                        ) : (
                          <VisibilityOff
                            onClick={() => {
                              setState({
                                viewPasswordOld: !state?.viewPasswordOld,
                              });
                            }}
                            style={{ color: "gray", cursor: "pointer" }}
                          />
                        )
                      }
                    />
                  </Grid>
                  <Grid xs={12} item>
                    <InputField
                      input_type={
                        state?.viewPasswordNew === false ? "password" : "text"
                      }
                      onchange={onInputChangeTwo}
                      startIcon={<LockOpen />}
                      placeHolder={strings?.set_new_password}
                      input_value={state?.newPassword}
                      input_name={"newPassword"}
                      input_style={{
                        background: "#fff",
                        width: "500px",
                        borderRadius: "16px",
                        border: "none",
                      }}
                      endIicon={
                        state?.viewPasswordNew ? (
                          <Visibility
                            onClick={() => {
                              setState({
                                viewPasswordNew: !state?.viewPasswordNew,
                              });
                            }}
                            style={{ color: "gray", cursor: "pointer" }}
                          />
                        ) : (
                          <VisibilityOff
                            onClick={() => {
                              setState({
                                viewPasswordNew: !state?.viewPasswordNew,
                              });
                            }}
                            style={{ color: "gray", cursor: "pointer" }}
                          />
                        )
                      }
                    />
                  </Grid>
                  <Grid xs={12} item>
                    <InputField
                      input_type={
                        state?.viewPasswordConfirm === false
                          ? "password"
                          : "text"
                      }
                      onchange={onInputChangeTwo}
                      startIcon={<LockOpen />}
                      placeHolder={strings?.confirm_password}
                      input_value={state?.confirmPassword}
                      input_name={"confirmPassword"}
                      input_style={{
                        background: "#fff",
                        width: "500px",
                        borderRadius: "16px",
                        border: "none",
                      }}
                      endIicon={
                        state?.viewPasswordConfirm ? (
                          <Visibility
                            onClick={() => {
                              setState({
                                viewPasswordConfirm:
                                  !state?.viewPasswordConfirm,
                              });
                            }}
                            style={{ color: "gray", cursor: "pointer" }}
                          />
                        ) : (
                          <VisibilityOff
                            onClick={() => {
                              setState({
                                viewPasswordConfirm:
                                  !state?.viewPasswordConfirm,
                              });
                            }}
                            style={{ color: "gray", cursor: "pointer" }}
                          />
                        )
                      }
                    />
                  </Grid>

                  <Grid xs={12} item style={{ padding: "20px" }}>
                    <CustomButton
                      style={{
                        width: "260px",
                      }}
                      onClick={handleChangePassword}
                      classesNames={"contained_button_two"}
                      value={
                        <React.Fragment>
                          <div
                            className="display-flex"
                            style={{
                              columnGap: "10px",
                              padding: "8px 0px 8px 0px",
                              fontSize: "16px",
                            }}
                          >
                            {"Modifier"}
                            <East
                              style={{
                                color: defaultLabelColors.main_blue,
                                background: "#f9d9b6",
                                borderRadius: 100,
                                padding: 4,
                                fontSize: 18,
                              }}
                            />
                          </div>
                        </React.Fragment>
                      }
                    />
                  </Grid>
                </div>
              </Grid>
            </DialogContent>
          }
        />

        <DraggableDialog
          maxWidth={"md"}
          open={state?.openIdenfyDialog}
          handleClose={() => {
            setState({
              openIdenfyDialog: false,
            });
            handleGetProfileInfo();
          }}
          classes={classes}
          dialogBody={
            idenfyToken && (
              <iframe
                id="iframe"
                allowFullScreen={true}
                style={{
                  width: "100%",
                  height: 700,
                  border: "none",
                  outline: "none",
                }}
                src={`https://ui.idenfy.com/?authToken=${idenfyToken}&lang=fr`}
                allow="camera"
              />
            )
          }
        />

        <DraggableDialog
          maxWidth={"md"}
          open={state?.openFuturePayment}
          handleClose={() => {
            setState({ openFuturePayment: false });
          }}
          classes={classes}
          dialogBody={
            <DialogContent
              style={{
                display: "flex",
                background: "#FCE6DD",
                justifyContent: "center",
              }}
              dividers
            >
              <Grid
                container
                spacing={2}
                style={{ display: "flex", flexDirection: "column" }}
              >
                {state.stripe_type === "order_payment" ? (
                  state.stripe_form?.paymentIntent && (
                    <Elements
                      stripe={stripePromise}
                      options={{
                        clientSecret: state.stripe_form?.paymentIntent,
                        appearance: {
                          theme: "stripe",
                          variables: {
                            fontFamily: '"Raleway-Variable", sans-serif',
                          },
                          rules: {
                            ".Label": {
                              fontFamily: '"Raleway-Variable", sans-serif',
                              fontSize: "14px", // Adjust the size as needed
                              color: "#000000", // Change the color if needed
                            },
                          },
                        },
                      }}
                    >
                      <InjectedCheckoutForm
                        handleConfirmPayment={handleConfirmPayment}
                        onPressBuy={() =>
                          setState({
                            loading: true,
                          })
                        }
                      />
                    </Elements>
                  )
                ) : (
                  <Elements
                    stripe={stripePromise}
                    options={{
                      clientSecret: state.options?.setupIntent,
                      customerOptions: {
                        customer: state.options?.customer,
                        ephemeralKey: state.options?.ephemeralKey,
                      },
                      // payment_method_types: ["card"],
                      // mode: "setup",
                    }}
                  >
                    <StripeCheckout handleCloseDialog={handleCloseDialog} />
                  </Elements>
                )}
              </Grid>
            </DialogContent>
          }
        />
      </div>
      <div className="mobile-screen" style={{ width: "100%" }}>
        <ProfileMobileView
          {...props}
          {...state}
          selectedDate={selectedDate}
          formattedDate={formattedDate}
          anchorEN={anchorEN}
          onInputChangeTwo={onInputChangeTwo}
          handleChangePassword={handleChangePassword}
          handleInitStripeSetupPayment={handleInitStripeSetupPayment}
          handleDateChange={handleDateChange}
          setSelectedDate={setSelectedDate}
          setFormattedDate={setFormattedDate}
          handleUpdateProfile={handleUpdateProfile}
          handlOpenDialogForPassword={handlOpenDialogForPassword}
          handleOpenFuturePayment={handleOpenFuturePayment}
          openForLang={openForLang}
          handleCloseForLang={handleCloseForLang}
        />
      </div>
    </>
  );
}

export default Profile;

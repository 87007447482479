import React, { useState } from "react";
import "./header.css";
import {
  AnouncementIcon,
  FrankIcon,
  MailOrangeIcon,
  UserblackIcon,
  UserOrangeIcon,
} from "../../assets/images/FrankIcons";
import {
  ArrowDropDown,
  Close,
  KeyboardArrowDown,
  Logout,
  PersonOutlineOutlined,
  Settings,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Fade,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  clearAllDataFromLocalStorage,
  getDataFromLocalStorage,
} from "../../utils/LocalStore.util";
import Toast from "../Toast/Toast";
import drawerBoxIcon from "../../assets/mobileImages/drawer-box-icon.png";
import { useDispatch, useSelector } from "react-redux";
import { setUpdateLanguage } from "../../Store/MainAppReducer";
import { RootState } from "../../Store";
import { defaultLabelColors } from "../../utils/constants";
import { strings } from "../../Store/Localization";
import frIcon from "../../assets/images/FrankIcons/fr-icon.png";
import enIcon from "../../assets/images/FrankIcons/en-icon.png";

function NewHeader() {
  const dispatch = useDispatch<any>();
  const selector = useSelector((state: RootState) => state.mainApp);

  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      lang: "fr",
    }
  );

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = (open: any) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorEN, setAnchorEN] = React.useState<null | HTMLElement>(null);
  const openForLang = Boolean(anchorEN);
  const handleClickForLang = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEN(event.currentTarget);
  };
  const handleCloseForLang = () => {
    setAnchorEN(null);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  let userDetails = getDataFromLocalStorage("user");
  const handleLogout = () => {
    try {
      clearAllDataFromLocalStorage();
      navigate("/");
      Toast.showSuccess("Logout Succesfully");
    } catch (error) {}
  };

  return (
    <div
      style={{
        padding: 15,
        background: "white",
        borderRadius: 100,
        marginTop: 20,
        // width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 14,
            width: "100%",
          }}
        >
          <a href="/" style={{ marginBottom: "-10px", marginLeft: 25 }}>
            <FrankIcon fill={"#ee6931"} height={55} />
          </a>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 14,
              flex: 1,
            }}
          >
            <div className="font_resp">
              <div
                className="menu-component menu-container"
                style={{ cursor: "pointer" }}
              >
                <span
                  className="particulier font_resp"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Particulier{" "}
                  <KeyboardArrowDown
                    style={{ fontSize: "24px", fontWeight: "lighter" }}
                  />
                </span>

                <div className="dropdown">
                  <div
                    className="dropdown-item"
                    onClick={() => {
                      navigate("/send-package");
                    }}
                  >
                    Envoyer un colis
                  </div>
                  {/*
                  comment by asad iqbal 1/14/2025 on Ben Demand ( Launching Frank C2C ppt)
                  <div className="dropdown-item">Suivre mon colis</div> */}
                  <div
                    className="dropdown-item"
                    onClick={() => {
                      navigate("/transporter-un-colis");
                    }}
                  >
                    Devenir transporteur
                  </div>
                  <div
                    className="dropdown-item"
                    onClick={() => {
                      navigate("/relocation");
                    }}
                  >
                    Démenagement{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="font_resp">
              <div
                className="menu-component menu-container"
                style={{ cursor: "pointer" }}
              >
                <span
                  className="particulier font_resp"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Transporteur{" "}
                  <KeyboardArrowDown
                    style={{ fontSize: "24px", fontWeight: "lighter" }}
                  />{" "}
                </span>

                <div className="dropdown">
                  <div
                    className="dropdown-item"
                    onClick={() => {
                      navigate("/transporter-un-colis");
                    }}
                  >
                    Devenir transporteur
                  </div>
                  <div
                    className="dropdown-item"
                    onClick={() => {
                      navigate("/announces");
                    }}
                  >
                    Voir les annonces
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="font_resp">
              <div
                className="menu-component menu-container"
                style={{ cursor: "pointer" }}
              >
                <span
                  className="particulier font_resp"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Déménagement{" "}
                  <KeyboardArrowDown
                    style={{ fontSize: "24px", fontWeight: "lighter" }}
                  />{" "}
                </span>

                <div className="dropdown">
                  <div className="dropdown-item"> Déménageur particulier</div>
                  <div className="dropdown-item">Déménageur professionnel</div>
                </div>
              </div>
            </div> */}
            {/*
            comment by asad iqbal 1/14/2025 on Ben Demand ( Launching Frank C2C ppt)
            <div className="font_resp">
              <div
                className="menu-component menu-container"
                style={{ cursor: "pointer" }}
              >
                <span
                  className="particulier font_resp"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    navigate("/marketplace");
                  }}
                >
                  Marché de Frank
                </span>
              </div>
            </div> */}
            <div className="font_resp">
              <div
                className="menu-component menu-container"
                style={{ cursor: "pointer" }}
              >
                <span
                  className="particulier font_resp"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    navigate("/blogs");
                  }}
                >
                  Blog
                </span>
              </div>
            </div>
            {/* <div className="font_resp">
              <div
                className="menu-component menu-container"
                style={{ cursor: "pointer" }}
              >
                <span
                  className="particulier font_resp"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Stockage colis{" "}
                  <KeyboardArrowDown
                    style={{ fontSize: "24px", fontWeight: "lighter" }}
                  />
                </span>

                <div className="dropdown">
                  <div className="dropdown-item">
                    {" "}
                    Trouver un point relais particulier
                  </div>
                  <div className="dropdown-item">
                    Proposer un espace de point relais
                  </div>
                  <div className="dropdown-item">Consigne bagage</div>
                </div>
              </div>
            </div> */}
          </div>

          <div
            className="hide_login_drawer"
            style={{ cursor: "pointer", marginTop: "10px" }}
            onClick={toggleDrawer(true)}
          >
            <svg
              width="23px"
              height="22px"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginTop: "-10px" }}
            >
              <path
                d="M4 16H28M4 8H28M4 24H20"
                stroke="#281208"
                stroke-width="2.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          {/* This is Drawer */}
          <Drawer
            anchor="right"
            open={isDrawerOpen}
            onClose={toggleDrawer(false)}
            style={{
              borderRadius: "0px",
              background: "none",
              height: "100vh",
            }}
            PaperProps={{
              sx: {
                width: "300px",
              },
            }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1, padding: "20px 0px 0px 20px" }}>
                <FrankIcon fill={"#ee6931"} height={60} />
              </div>
              <div style={{ padding: "20px 20px 0px 0px" }}>
                <IconButton onClick={toggleDrawer(false)}>
                  <Close style={{ fontSize: "28px", color: "#000" }} />
                </IconButton>
              </div>
            </div>
            <Typography
              variant="body1"
              style={{ paddingLeft: "20px", flex: 1 }}
            >
              <div
                style={{ display: "flex", flexDirection: "column", gap: 20 }}
              >
                {userDetails ? (
                  <div className="user" style={{ cursor: "pointer" }}>
                    <div
                      style={{
                        display: "flex",
                        gap: "20px",
                        fontFamily: "Raleway-Variable",
                        fontWeight: "600",
                      }}
                      onClick={() => {
                        navigate("/chat");
                      }}
                    >
                      <MailOrangeIcon />{" "}
                      <span style={{ color: "#000" }}>Mail</span>
                    </div>
                  </div>
                ) : null}

                {userDetails ? (
                  <div
                    className="compte"
                    style={{ cursor: "pointer", position: "relative" }}
                  >
                    <div className="user" style={{}}>
                      <div
                        onClick={() => {
                          navigate("/profile");
                        }}
                        style={{
                          display: "flex",
                          gap: "20px",
                          fontFamily: "Raleway-Variable",
                          fontWeight: "600",
                        }}
                      >
                        <UserOrangeIcon /> <span>Account</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                className="menu-component menu-container"
                style={{ cursor: "pointer" }}
                onClick={()=>{navigate("/login")}}
              >
                <span
                  className="particulier font_resp"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems:'center',
                    fontFamily:'Raleway-Semi-Bold',
                    gap:10
                    
                  }}
                >
                  Se Connecter <span style={{paddingBottom:'5px'}}><UserblackIcon /></span>
                </span>
              </div>
                )}

                {/* <div
                  className="compte"
                  style={{ cursor: "pointer", position: "relative" }}
                >
                  <div className="user">
                    <div
                      className="particulier"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={handleClickForLang}

                    >
                      {selector.lang?.toUpperCase()} <ArrowDropDown />
                    </div>
                  </div>
                </div> */}
              </div>
            </Typography>
            <Typography
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <img src={drawerBoxIcon} height={"60%"} alt="box" />
              </div>
            </Typography>
          </Drawer>
          {/*Drawer ends here*/}

          <div className="login_area">
            <div className="right" style={{justifyContent:"flex-end"}}>
              {/* <div className="font_resp">
                <div
                  className="menu-component menu-container"
                  style={{ cursor: "pointer" }}
                >
                  <span
                    className="particulier font_resp"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    fontFamily:'Raleway-Semi-Bold',

                    }}
                  >
                    Suivre mon colis
                  </span>
                </div>
              </div> */}

              {userDetails ? (
                <div className="tracker" style={{ cursor: "pointer" }}>
                  <div
                    className="line-mail"
                    onClick={() => {
                      navigate("/chat");
                    }}
                  >
                    <MailOrangeIcon />
                  </div>
                </div>
              ) : null}

              {userDetails ? (
                <div
                  className="compte"
                  style={{ cursor: "pointer", position: "relative" }}
                >
                  <div className="user" style={{}}>
                    <div
                      onClick={() => {
                        navigate("/profile");
                      }}
                    >
                      <UserOrangeIcon />
                    </div>
                  </div>
                  {/* <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        width: "200px",
                        borderRadius: "12px",
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&::before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem
                      className="dropdown-item"
                      style={{ fontFamily: "Raleway-Variable" }}
                      onClick={() => {
                        navigate("/profile");
                      }}
                    >
                      <Avatar /> Profil
                    </MenuItem>
                    <MenuItem
                      className="dropdown-item"
                      style={{ fontFamily: "Raleway-Variable", gap: 10 }}
                      onClick={() => {
                        navigate("/my-jobs");
                      }}
                    >
                      <AnouncementIcon
                        height={25}
                        width={25}
                        fill={"#bdbdbd"}
                      />
                      {"Transactions"}
                    </MenuItem>
                    <MenuItem
                      className="dropdown-item"
                      style={{ fontFamily: "Raleway-Variable", gap: 10 }}
                      onClick={() => {
                        navigate("/my-anounces");
                      }}
                    >
                      <AnouncementIcon
                        height={25}
                        width={25}
                        fill={"#bdbdbd"}
                      />{" "}
                      {"My Announces"}
                    </MenuItem>
                    <Divider />

                    <MenuItem
                      className="dropdown-item"
                      style={{ fontFamily: "Raleway-Variable" }}
                      // onClick={handleClose}
                    >
                      <ListItemIcon>
                        <Settings fontSize="small" />
                      </ListItemIcon>
                      Settings
                    </MenuItem>
                    <MenuItem
                      className="dropdown-item"
                      style={{ fontFamily: "Raleway-Variable" }}
                      onClick={handleLogout}
                    >
                      <ListItemIcon>
                        <Logout fontSize="small" />
                      </ListItemIcon>
                      Logout
                    </MenuItem>
                  </Menu> */}
                </div>
              ) : (
                <div
                className="menu-component menu-container"
                style={{ cursor: "pointer" }}
                onClick={()=>{navigate("/login")}}
              >
                <span
                  className="particulier font_resp"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems:'center',
                    fontFamily:'Raleway-Semi-Bold',
                    gap:10
                    
                  }}
                >
                  Se Connecter <span style={{paddingBottom:'5px'}}><UserblackIcon /></span>
                </span>
              </div>
                // <div
                //   style={{
                //     display: "flex",
                //     justifyContent: "center",
                //     alignItems: "center",
                //     border: "1px solid #F4C7B8 ",
                //     borderRadius: 4,
                //     padding: 5,
                //     color: "#ee6931",
                //     gap: 10,
                //   }}
                // >
                //   <div
                //     onClick={() => {
                //       navigate("/sign-up");
                //     }}
                //     style={{
                //       display: "flex",
                //       justifyContent: "center",
                //       alignItems: "center",
                //     }}
                //   >
                //     <span
                //       className="particulier font_resp"
                //       style={{
                //         cursor: "pointer",
                //         textAlign: "center",
                //       }}
                //     >
                //       S'inscrire
                //     </span>
                //   </div>
                //   <div
                //     style={{
                //       width: "2px",
                //       height: "10px",
                //       background: "#F4C7B8",
                //     }}
                //   ></div>
                //   <div
                //     onClick={() => {
                //       navigate("/login");
                //     }}
                //     style={{
                //       display: "flex",
                //       justifyContent: "center",
                //       alignItems: "center",
                //     }}
                //   >
                //     <span
                //       className="particulier font_resp"
                //       style={{
                //         cursor: "pointer",
                //         textAlign: "center",
                //       }}
                //     >
                //       Se connecter
                //     </span>
                //   </div>
                // </div>
              )}

              {/* <div
                className="compte"
                style={{ cursor: "pointer", position: "relative" }}
              >
                <div className="user">
                  <div
                    className="particulier font_resp"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                   
                    onClick={handleClickForLang}
                  >
                    {selector.lang?.toUpperCase()} <ArrowDropDown />
                  </div>

                  <Menu
                    id="account-menu"
                    MenuListProps={{
                      "aria-labelledby": "fade-button",
                    }}
                    anchorEl={anchorEN}
                    open={openForLang}
                    onClose={handleCloseForLang}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        // width: "200px",
                        borderRadius: "12px",
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&::before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem
                      onClick={() => {
                        dispatch(setUpdateLanguage("fr"))
                        handleCloseForLang()
                      }}
                    >
                      <div className="display-flex" style={{gap:10}} >
                      <img src={frIcon} alt="fr-icon" height={30} width={30} />
                      FR
                      </div>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        dispatch(setUpdateLanguage("en"))
                        handleCloseForLang()
                      }}
                    >
                      <div className="display-flex" style={{gap:10}} >
                        <img src={enIcon} alt="en-icon" height={20} width={30} />
                      EN
                      </div>
                    </MenuItem>
                  </Menu>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewHeader;

import { East, ExpandMore, KeyboardArrowDown } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  DialogContent,
  Grid,
} from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";
import { Rating } from "react-simple-star-rating";
import {
  MapHomeIcon,
  MapPointerIcon,
  RefrestIcon
} from "../../assets/images/FrankIcons";
import apiServices from "../../services/RequestHandler";
import { strings } from "../../Store/Localization";
import CustomButton from "../../UiComponent/CustomButton";
import CustomFooter from "../../UiComponent/CustomFooter";
import DraggableDialog from "../../UiComponent/DraggableDialog";
import GoogleMaps from "../../UiComponent/GoogleMaps";
import NewHeader from "../../UiComponent/NewHeader";
import InputField from "../../UiComponent/TextFeild";
import { defaultLabelColors, spacing } from "../../utils/constants";
import { handleLocalize } from "../../utils/helper";
import DevenirTransporteurMobileView from "./DevenirTransporteurMobileView";

function DevenirTransporteur(props: any) {
  const { classes } = props;
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      announes: "All",
    }
  );

  useEffect(() => {
    handleGetAllJobs(state?.announes);
  }, [state?.announes]);

  const handleGetAllJobs = async (jobType: string) => {
    try {
      setState({
        announes: jobType,
      });
      let body: any = {
        type: state?.announes,
      };
      if (state?.announes === "Around Me") {
        navigator.geolocation.getCurrentPosition(function (position) {
          body = {
            ...body,
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
        });
      }
      const resp = await apiServices.postFromApi(
        "c2c-shipments/web-all-announcements",
        body,
        ""
      );

      setState({
        allJobsData: resp?.data,
      });
      // const locations = state?.allJobsData?.pickup?.map(
      //   (item: any) => item.location
      // );
    } catch (error) {}
  };

  const handleGetJobInfo = async (item: any) => {
    try {
      setState({
        openJobDialog: true,
      });
      const resp = await apiServices.getFromApi(
        `c2c-shipments/${item?.id}`,
        ""
      );
      setState({
        jobDetails: resp?.data,
      });
     } catch (error) {}
  };

  return (
    <>
      <div className="web-screen">
        <div style={{ background: "#f9c9b3" }}>
          <Grid
            container
            spacing={2}
            className="display-flex"
            style={{
              background: "#F4C9B3",
              paddingLeft: "40px",
              paddingRight: "40px",
              paddingTop: "30px",
            }}
          >
            <Grid xs={12} item style={{ zIndex: 99 }}>
              <NewHeader />
            </Grid>
          </Grid>
          <div style={{ padding: "0px 100px 0px 100px" }}>
            <Grid container spacing={spacing.spacing2}>
              <Grid xs={12} item>
                <h1
                  className="heading-one"
                  style={{
                    fontFamily: "Raleway-Italic",
                    color: defaultLabelColors.main_blue,
                  }}
                >
                  DEVENIR TRANSPORTEUR POUR PARTICULIERS
                </h1>
                <p className="paragraph-text-two">
                  <span style={{ fontWeight: "600" }}>
                    {" "}
                    Vous cherchez un moyen de générer un revenu complémentaire
                    tout en optimisant vos déplacements ?{" "}
                  </span>{" "}
                  Devenez transporteur pour particuliers avec Franck ! Notre
                  plateforme de livraison collaborative vous permet de monétiser
                  l’espace libre de votre véhicule en effectuant des livraisons
                  sur vos trajets habituels.
                  <br />
                </p>

                <p className="paragraph-text-two">
                  {" "}
                  Découvrez comment devenir cotransporteur et profitez des
                  nombreux avantages offerts par Frank.
                </p>
              </Grid>
            </Grid>

            <Grid container spacing={spacing.spacing2}>
              <Grid
                xs={12}
                md={6}
                item
                style={{ paddingLeft: "20px", paddingRight: "20px" }}
              >
                <p
                  style={{
                    fontFamily: "Raleway-Italic",
                    fontWeight: "700",
                    fontSize: "22px",
                    color: "#000",
                  }}
                >
                  Votre trajet
                </p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      borderRadius: 100,
                      padding: 15,
                      border: `1px solid ${defaultLabelColors.main_blue}`,
                    }}
                  >
                    <MapPointerIcon fill={defaultLabelColors.main_blue} />
                  </div>
                  <div style={{ width: "100%" }}>
                    <InputField
                      placeHolder={"Ville de départ "}
                      input_value={state?.origin}
                      input_name={"origin"}
                      input_style={{
                        background: "#fff",
                        width: "100%",
                        borderRadius: "32px",
                      }}
                      readonly={true}
                    />
                  </div>
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      borderRadius: 100,
                      padding: 15,
                      border: `1px solid ${defaultLabelColors.main_blue}`,
                    }}
                  >
                    <MapHomeIcon
                      fill={defaultLabelColors.main_blue}
                      height={20}
                      width={20}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <InputField
                      placeHolder={"Ville d’arrivée "}
                      input_value={state?.destination}
                      input_name={"destination"}
                      input_style={{
                        background: "#fff",
                        width: "100%",
                        borderRadius: "32px",
                      }}
                      readonly={true}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "20px",
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      fontFamily: "Raleway-Italic",
                      fontWeight: "700",
                      fontSize: "22px",
                      color: "#000",
                    }}
                  >
                    Annonces sur le trajet
                  </div>
                  <div>
                    <CustomButton
                      style={{ fontFamily: "Raleway-Variable" }}
                      classesNames={"outlined_button_two"}
                      value={
                        <React.Fragment>
                          <div
                            className="display-flex"
                            style={{ columnGap: "12px" }}
                          >
                            Toutes les tailles
                            <KeyboardArrowDown
                              style={{ color: defaultLabelColors.main_blue }}
                            />
                          </div>
                        </React.Fragment>
                      }
                    />
                  </div>
                </div>

                <div
                  style={{
                    height: "70vh",
                    overflowY: "scroll",
                    marginTop: "20px",
                    borderRadius: "16px",
                    paddingRight: 10,
                  }}
                  className="annouces-main-div"
                >
                  <Grid container spacing={2}>
                    {state?.allJobsData?.map((i: any) => {
                      return (
                        <Grid xs={12} sm={12} md={6} item>
                          <div
                            style={{
                              background: "#fff",
                              borderRadius: "16px",
                              padding: "20px",
                              height: "250px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleGetJobInfo(i);
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ flex: 1 }}>
                                <p
                                  style={{
                                    fontSize: "16px",
                                    fontFamily: "Raleway-Italic",
                                    color: "#000",
                                    fontWeight: "700",
                                  }}
                                  className="title-text-announces"
                                >
                                  {handleLocalize({
                                    en: i?.shipment_items[0]?.item_name_en,
                                    fr: i?.shipment_items[0]?.item_name_fr,
                                  }) ??
                                    i?.shipment_items[0]?.prod_name ??
                                    ""}
                                </p>
                              </div>
                              <div
                                style={{
                                  fontFamily: "Raleway-Italic",
                                  fontSize: "24px",
                                  fontWeight: "800",
                                  color: defaultLabelColors.main_orange,
                                }}
                              >
                                {i?.ship_price ?? "0.00"}€
                              </div>
                            </div>

                            <div
                              style={{
                                background: "#eef2f6",
                                borderRadius: "16px",
                                paddingLeft: "20px",
                                paddingRight: "20px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                  borderBottom: "1px solid #3026f1",
                                }}
                              >
                                <p
                                  style={{
                                    background: "#f9c9b3",
                                    borderRadius: "32px",
                                    padding: "10px 15px",
                                  }}
                                >
                                  <MapHomeIcon
                                    fill={defaultLabelColors.main_blue}
                                    height={20}
                                    width={20}
                                  />
                                </p>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontFamily: "Raleway-Italic",
                                      fontSize: "16px",
                                    }}
                                  >
                                    Depart
                                  </span>
                                  <span
                                    style={{
                                      fontFamily: "Raleway-Italic",
                                      fontWeight: "700",
                                      wordBreak: "break-all",
                                      fontSize: "15px",
                                    }}
                                  >
                                    {" "}
                                    {i?.pickup?.city ?? "City"},
                                    {i?.pickup?.country ?? "City"}
                                  </span>
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                <p
                                  style={{
                                    background: "#f9c9b3",
                                    borderRadius: "32px",
                                    padding: "10px 15px",
                                  }}
                                >
                                  <MapPointerIcon
                                    fill={defaultLabelColors.main_blue}
                                  />
                                </p>
                                <p
                                  style={{
                                    fontFamily: "Raleway-Italic",
                                    fontSize: "16px",
                                  }}
                                >
                                  Destination
                                  <br />
                                  <span
                                    style={{
                                      fontFamily: "Raleway-Italic",
                                      fontWeight: "700",
                                      wordBreak: "break-all",
                                      fontSize: "15px",
                                    }}
                                  >
                                    {" "}
                                    {i?.dropoff?.city ?? "City"},
                                    {i?.dropoff?.country ?? "City"}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      );
                    })}
                  </Grid>
                  <div
                    style={{
                      textAlign: "center",
                      fontFamily: "Raleway-Variable",
                      color: defaultLabelColors.main_blue,
                      fontSize: "22px",
                      fontWeight: "600",
                      padding: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 10,
                      cursor: "pointer",
                    }}
                  >
                    <RefrestIcon fill={defaultLabelColors.main_blue} /> Voir
                    plus d'annonce
                  </div>
                </div>
              </Grid>

              <Grid xs={12} md={6} item style={{ height: "100vh" }}>
                <div
                  style={{
                    display: "flex",
                    padding: "20px",
                    gap: "10px",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    width: "80%",
                    float: "right",
                  }}
                >
                  <CustomButton
                    style={{ maxWidth: 100 }}
                    classesNames={
                      state?.announes === "All"
                        ? "contained_button_two  "
                        : "outlined_button_two  "
                    }
                    onClick={() => {
                      handleGetAllJobs("All");
                    }}
                    value={"Tout"}
                  />
                  <CustomButton
                    style={{ maxWidth: 150 }}
                    classesNames={
                      state?.announes === "Express"
                        ? "contained_button_two  "
                        : "outlined_button_two  "
                    }
                    onClick={() => {
                      handleGetAllJobs("Express");
                    }}
                    value={strings?.Express_announces}
                  />

                  <CustomButton
                    style={{ width: "250px" }}
                    onClick={() => {
                      handleGetAllJobs("Around Me");
                    }}
                    classesNames={
                      state?.announes === "Around Me"
                        ? "contained_button_two"
                        : "outlined_button_two"
                    }
                    value={
                      <React.Fragment>
                        <div
                          className="display-flex"
                          style={{ columnGap: "12px" }}
                        >
                          {"Autour de moi"}
                        </div>
                      </React.Fragment>
                    }
                  />
                </div>
                <div style={{ height: "100%" }}>
                  <GoogleMaps
                    handleGetLocation={(data) => {
                      setState({
                        destinationAddress: data?.result[0],
                        recipient_digital_code: data?.result[0].zipcode,
                      });
                    }}
                    // markers_data={state.allJobsData ?? []}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid
              spacing={spacing.spacing2}
              style={{ paddingTop: "100px", paddingBottom: "100px" }}
            >
              <Grid xs={12} item>
                <div
                  className="triangle"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "20px",
                    paddingRight: "20px",
                    paddingLeft: "20px",
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      fontFamily: "Raleway-Italic",
                      fontWeight: "800",
                      color: "#fff",
                      fontSize: "32px",
                      width: "20%",
                    }}
                  >
                    COMMENT
                    <br /> ÇA FONCTIONNE?
                  </div>

                  <div
                    style={{
                      display: "flex",
                      width: "22%",
                      height: 180,
                      borderRadius: "16px",
                      justifyContent: "center",

                      alignItems: "flex-start",
                      gap: "10px",
                      flexDirection: "column",
                    }}
                    className="mstpone_1"
                  >
                    <div>
                      <span
                        style={{
                          fontFamily: "Raleway-Variable",
                          fontWeight: "900",
                          color: "#fff",
                          background: defaultLabelColors.main_orange,
                          borderRadius: 100,
                          fontSize: "25px",
                          padding: "5px 10px 5px 10px",
                          textAlign: "left",
                          marginLeft: 20,
                        }}
                      >
                        1
                      </span>
                    </div>
                    <div
                      style={{
                        fontSize: "18px",
                        fontFamily: "Raleway-Variable",
                        color: defaultLabelColors.main_blue,
                        fontWeight: "800",
                        textAlign: "left",
                        padding: "0px 5px 0px 5px",
                        marginLeft: 20,
                      }}
                    >
                      Renseignez <br /> votre trajet
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      width: "22%",
                      height: 180,
                      borderRadius: "16px",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      gap: "10px",
                      flexDirection: "column",
                    }}
                    className="mstpone_1"
                  >
                    <div>
                      <span
                        style={{
                          fontFamily: "Raleway-Variable",
                          fontWeight: "900",
                          color: "#fff",
                          background: defaultLabelColors.main_blue,
                          borderRadius: 100,
                          fontSize: "25px",
                          padding: "5px 10px 5px 10px",
                          textAlign: "left",
                          marginLeft: "20px",
                        }}
                      >
                        2
                      </span>
                    </div>
                    <div
                      style={{
                        fontSize: "18px",
                        fontFamily: "Raleway-Variable",
                        color: defaultLabelColors.main_blue,
                        fontWeight: "800",
                        textAlign: "left",
                        padding: "0px 5px 0px 5px",
                        marginLeft: 20,
                      }}
                    >
                      Choisissez la taille <br /> du colis à transporter
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      width: "22%",
                      height: 180,
                      borderRadius: "16px",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      gap: "10px",
                      flexDirection: "column",
                    }}
                    className="mstpone_1"
                  >
                    <div>
                      <span
                        style={{
                          fontFamily: "Raleway-Variable",
                          fontWeight: "900",
                          color: "#fff",
                          background: defaultLabelColors.main_orange,
                          borderRadius: 100,
                          fontSize: "25px",
                          padding: "5px 10px 5px 10px",
                          textAlign: "left",
                          marginLeft: 20,
                        }}
                      >
                        3
                      </span>
                    </div>
                    <div
                      style={{
                        fontSize: "18px",
                        fontFamily: "Raleway-Variable",
                        color: defaultLabelColors.main_blue,
                        fontWeight: "800",
                        textAlign: "left",
                        padding: "0px 5px 0px 5px",
                        marginLeft: 20,
                      }}
                    >
                      Validez <br /> votre choix
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  textAlign: "left",
                  fontSize: "42px",
                  fontFamily: "Raleway-Italic",
                  fontWeight: "800",
                  padding: "40px 0px 10px 15px",
                }}
              >
                Nos actualités
              </div>

              <ul className="cards">
                <li className="cards__item">
                  <div className="card">
                    <div className="card__image card__image--river"></div>
                    <div className="card__content">
                      <div style={{ display: "flex" }}>
                        <div className="card__title" style={{ flex: 1 }}>
                          Du nouveau chez Frank
                          <p
                            style={{
                              fontFamily: "Raleway-Italic",
                              fontSize: "14px",
                              color: "#000",
                              fontWeight: "400",
                              marginTop: 5,
                            }}
                          >
                            il y a 10 heures
                          </p>
                        </div>
                        <div>
                          <East
                            style={{
                              background: "#fff",
                              borderRadius: 100,
                              color: defaultLabelColors.main_blue,
                              padding: 5,
                              fontSize: "16px",
                            }}
                          />{" "}
                        </div>
                      </div>
                      <p className="card__text">
                        Lorem ipsum dolor sit amet consectetur. Sapien pharetra
                        adipiscing odio et bibendum duis. Auctor scelerisque
                        tellus tortor euismod...
                      </p>
                    </div>
                  </div>
                </li>
                <li className="cards__item">
                  <div className="card">
                    <div className="card__image card__image--record"></div>
                    <div className="card__content">
                      <div style={{ display: "flex" }}>
                        <div className="card__title" style={{ flex: 1 }}>
                          Du nouveau chez Frank
                          <p
                            style={{
                              fontFamily: "Raleway-Italic",
                              fontSize: "14px",
                              color: "#000",
                              fontWeight: "400",
                              marginTop: 5,
                            }}
                          >
                            il y a 10 heures
                          </p>
                        </div>
                        <div>
                          <East
                            style={{
                              background: "#fff",
                              borderRadius: 100,
                              color: defaultLabelColors.main_blue,
                              padding: 5,
                              fontSize: "16px",
                            }}
                          />{" "}
                        </div>
                      </div>
                      <p className="card__text">
                        Lorem ipsum dolor sit amet consectetur. Sapien pharetra
                        adipiscing odio et bibendum duis. Auctor scelerisque
                        tellus tortor euismod...
                      </p>
                    </div>
                  </div>
                </li>
                <li className="cards__item">
                  <div className="card">
                    <div className="card__image card__image--flowers"></div>
                    <div className="card__content">
                      <div style={{ display: "flex" }}>
                        <div className="card__title" style={{ flex: 1 }}>
                          Du nouveau chez Frank
                          <p
                            style={{
                              fontFamily: "Raleway-Italic",
                              fontSize: "14px",
                              color: "#000",
                              fontWeight: "400",
                              marginTop: 5,
                            }}
                          >
                            il y a 10 heures
                          </p>
                        </div>
                        <div>
                          <East
                            style={{
                              background: "#fff",
                              borderRadius: 100,
                              color: defaultLabelColors.main_blue,
                              padding: 5,
                              fontSize: "16px",
                            }}
                          />{" "}
                        </div>
                      </div>
                      <p className="card__text">
                        Lorem ipsum dolor sit amet consectetur. Sapien pharetra
                        adipiscing odio et bibendum duis. Auctor scelerisque
                        tellus tortor euismod...
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </Grid>
        </div>

        <div className="display-flex">
          <Grid
            container
            spacing={2}
            marginTop={5}
            style={{
              maxWidth: "1000px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              xs={12}
              item
              className="display-flex"
              style={{
                flexDirection: "column",
                background: "#F5C9B3",
                padding: "10px",
                borderRadius: "18px",
              }}
            >
              <div
                style={{
                  fontSize: "36px",
                  fontWeight: "900",
                  fontFamily: "Raleway-Italic",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  textAlign: "left",
                  width: "100%",
                  padding: "10px 0px 10px 10px",
                }}
              >
                {" "}
                En savoir plus sur comment devenir un transporteur
              </div>

              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: "#F5C9B3",
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500" }}>
                    Comment suis-je payé avec Frank ?
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "100%" }}>
                  <p>
                    Après chaque livraison réussie, vous recevrez votre paiement
                    sur votre{" "}
                    <span style={{ fontWeight: "600" }}>
                      {" "}
                      porte-monnaie en ligne dans l’application Frank.
                    </span>
                    Vous pourrez par la suite verser le montant collecté
                    directement sur votre compte bancaire, sans frais
                    supplémentaires. Vous pouvez également utiliser votre
                    cagnotte sur la{" "}
                    <span style={{ fontWeight: "600" }}>
                      {" "}
                      marketplace Frank.
                    </span>
                  </p>
                  <p>
                    Il était essentiel pour nous de proposer un processus simple
                    et rapide, pour permettre à nos livreurs d'accéder
                    facilement à leur rémunération.
                  </p>
                </AccordionDetails>
              </Accordion>

              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: "#F5C9B3",
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500" }}>
                    Comment est déterminé le prix d'une livraison ?
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "100%" }}>
                  <p>
                    Le prix d’une livraison est{" "}
                    <span style={{ fontWeight: "600" }}>
                      {" "}
                      déterminé par l’expéditeur du colis{" "}
                    </span>{" "}
                    et indiqué au sein de l’annonce proposée. Le transporteur
                    peut{" "}
                    <span style={{ fontWeight: "600" }}>
                      {" "}
                      négocier le prix{" "}
                    </span>{" "}
                    qui figure sur l’annonce pour trouver un accord sur le
                    montant avec l’expéditeur.
                  </p>
                  <p>
                    Notre objectif : proposer des tarifs justes et compétitifs
                    pour le transport routier, tant pour les cotransporteurs que
                    pour les clients expéditeurs
                  </p>
                </AccordionDetails>
              </Accordion>

              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: "#F5C9B3",
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500" }}>
                    L'argent que je gagne en tant que transporteur particulier
                    est-il soumis à l'impôt ?
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "100%" }}>
                  <p>
                    <span style={{ fontWeight: 600 }}>
                      {" "}
                      Oui, les revenus générés en tant que cotransporteur sont
                      soumis à l'impôt,
                    </span>{" "}
                    comme toutes les autres ressources financières. Il est de
                    votre responsabilité de déclarer ces revenus auprès des
                    autorités compétentes et de vous acquitter des éventuelles
                    taxes et cotisations sociales correspondantes. Frank vous
                    fournira un
                    <span style={{ fontWeight: 600 }}>
                      {" "}
                      récapitulatif annuel de vos revenus{" "}
                    </span>{" "}
                    pour vous aider dans vos démarches fiscales.
                  </p>
                </AccordionDetails>
              </Accordion>

              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: "#F5C9B3",
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500" }}>
                    Devenir transporteur est-il un travail à temps plein ?
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "100%" }}>
                  <p>
                    Non, devenir cotransporteur avec Frank ne nécessite pas
                    forcément un engagement à temps plein.
                    <span style={{ fontWeight: 600 }}>
                      {" "}
                      Vous êtes libre de choisir le nombre et la fréquence des
                      missions{" "}
                    </span>{" "}
                    que vous souhaitez effectuer, en fonction de vos
                    disponibilités et de vos objectifs. Que vous soyez étudiant,
                    salarié ou indépendant, vous pouvez adapter votre activité
                    de transporteur à votre emploi du temps.
                  </p>
                  <p>
                    Par ailleurs, sachez qu'en effectuant des missions avec
                    Frank, vous n'exercez pas le métier de transporteur de
                    marchandises comme le ferait un livreur pour une entreprise
                    de transport.
                    <span style={{ fontWeight: 600 }}>
                      {" "}
                      Vous n'avez donc pas besoin d'une licence de transport.{" "}
                    </span>{" "}
                    Les compétences nécessaires sont aussi accessibles à tous.
                  </p>
                </AccordionDetails>
              </Accordion>

              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: "#F5C9B3",
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500" }}>
                    Quels types de colis sont livrés?
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "100%" }}>
                  <p>
                    En tant que cotransporteur Frank, vous pouvez être amené à
                    livrer une grande variété de colis et de marchandises, tels
                    que :
                  </p>

                  <p>• Des documents ;</p>
                  <p>• Des petits objets et cadeaux ;</p>
                  <p>• Des produits électroniques ;</p>
                  <p>• Des vêtements et accessoires ;</p>
                  <p>• Des produits alimentaires non périssables.</p>
                  <p>
                    Chaque annonce de livraison indique clairement toutes les
                    conditions préalables au transport de la marchandise : la
                    nature du colis à transporter, ainsi que ses dimensions et
                    son poids, vous permettant ainsi de choisir les missions qui
                    vous conviennent le mieux. Les prestations de transport sont
                    donc détaillées, pour que vous puissiez faire un choix
                    éclairé lors de la sélection de vos missions.
                  </p>
                </AccordionDetails>
              </Accordion>

              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: "#F5C9B3",
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500" }}>
                    Si j'annule une course, est-ce que je suis pénalisé ?
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "100%" }}>
                  <p>
                    Bien que nous encouragions nos cotransporteurs à honorer
                    leurs engagements, nous comprenons que des imprévus puissent
                    survenir. En cas d'annulation de votre part, veillez à
                    prévenir Frank et le client expéditeur dans les meilleurs
                    délais.{" "}
                    <span style={{ fontWeight: 600 }}>
                      {" "}
                      Si vos annulations restent exceptionnelles, vous ne serez
                      pas pénalisé.
                    </span>
                  </p>
                  <p>
                    Cependant, des annulations fréquentes ou de dernière minute
                    peuvent entraîner une baisse de votre note de confiance et
                    une réduction du nombre de missions qui vous sont proposées.
                    Il s'agit pour Frank de garantir aux utilisateurs une bonne
                    qualité de service.
                  </p>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </div>

        <div
          className="main-container"
          style={{ background: "#fff", marginTop: "100px" }}
        >
          <CustomFooter />
        </div>

        <DraggableDialog
          maxWidth={"md"}
          open={state?.openJobDialog}
          handleClose={() => {
            setState({ openJobDialog: false });
          }}
          dialog_title={"Transporter un colis"}
          classes={classes}
          dialogBody={
            <DialogContent
              style={
                {
                  // display: "flex",
                  // alignItems: "center",
                  // justifyContent: "center",
                  // flexDirection: "column",
                }
              }
              dividers
            >
              <div
                style={{
                  borderRadius: "16px",
                  border: "1px solid #E0E0E0",
                  padding: 20,
                  gap: 10,
                }}
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      flex: 1,
                      fontFamily: "Raleway-Variable",
                      fontSize: "16px",
                    }}
                  >
                    Détail de l’expéditeur
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      fontFamily: "Raleway-Variable",
                      fontSize: "16px",
                    }}
                  >
                    Membre depuis
                    {moment(state?.jobDetails?.user_details?.created_at).format(
                      "YYYY"
                    ) ?? "recently"}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      width: "15%",
                      paddingTop: "20px",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        width: 100,
                        height: 100,
                        borderRadius: 100,
                        overflow: "hidden",
                        display: "flex",

                        boxShadow: 3,
                      }}
                    >
                      <img
                        src="https://d2v5dzhdg4zhx3.cloudfront.net/web-assets/images/storypages/short/linkedin-profile-picture-maker/dummy_image/thumb/001.webp" // replace with your image path
                        alt="Profile"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                  </div>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-end",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p
                        style={{
                          fontFamily: "Raleway-Variable",
                          fontSize: "22px",
                          fontWeight: 600,
                        }}
                      >
                        {state?.jobDetails?.user_details?.cus_name ??
                          "Customer Name"}
                      </p>
                      <Rating
                        disableFillHover
                        initialValue={
                          state?.jobDetails?.user_details?.cus_ratings ?? 2
                        }
                        allowHover={false}
                        size={40}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  borderRadius: "16px",
                  border: "1px solid #E0E0E0",
                  padding: "0px 20px 20px 20px",
                  gap: 10,
                  marginTop: "10px",
                }}
              >
                <p style={{ fontFamily: "Raleway-Variable", fontSize: "16px" }}>
                  Type de livraison
                </p>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      flex: 1,
                      fontFamily: "Raleway-Variable",
                      fontWeight: "600",
                      fontSize: "24px",
                    }}
                  >
                    {state?.jobDetails?.ship_delivery_type ?? "Express"}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "Raleway-Variable",
                      fontSize: "16px",
                    }}
                  >
                    Annonce créée le{" "}
                    {moment(state?.jobDetails?.created_at).format(
                      "DD/MM/YYYY"
                    )}
                  </div>
                </div>
              </div>

              <div
                style={{
                  borderRadius: "16px",
                  border: "1px solid #E0E0E0",
                  padding: "0px 20px 20px 20px",
                  gap: 10,
                  marginTop: "10px",
                }}
              >
                <p style={{ fontFamily: "Raleway-Variable", fontSize: "16px" }}>
                  Produits & dimension
                </p>

                <div>
                  {state?.jobDetails?.shipment_items?.map((i: any) => {
                    return (
                      <div className="card-container">
                        <img src={i?.prod_images[0]} className="card-image" alt="prod-img" />
                        <div className="card-details">
                          <div className="card-title" style={{ fontSize: 17 }}>
                            {handleLocalize({
                              en: i?.item_name_en,
                              fr: i?.item_name_fr,
                            }) ??
                              i?.prod_name ??
                              ""}
                          </div>
                          <div className="card-dimensions">
                            {i?.prod_weight ?? ""}
                          </div>
                          <div className="card-quantity">
                            x{i?.prod_quantity}
                          </div>
                          {i?.prod_is_fragile ? <div className="card-quantity" style={{fontSize:'14px',fontFamily:"Raleway-Variable",fontWeight:'500',marginTop:5}}>
                                <span style={{fontWeight:600}}>Fragile</span> : manipuler avec soin
                              </div> : null}
                        </div>
                        <div
                          className="card-quantity"
                          style={{ paddingRight: "20px" }}
                        >
                          {i?.cat_name}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div
                style={{
                  borderRadius: "16px",
                  border: "1px solid #E0E0E0",
                  padding: "20px 20px 20px 20px",
                  gap: 10,
                  marginTop: "10px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      flex: 1,
                      fontFamily: "Raleway-Variable",
                      fontWeight: "700",
                      fontSize: "24px",
                    }}
                  >
                    {"Frais de livraison"}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: "600",
                      fontFamily: "Raleway-Variable",
                      fontSize: "22px",
                      color: defaultLabelColors.main_blue,
                    }}
                  >
                    7 €
                  </div>
                </div>
              </div>
            </DialogContent>
          }
        />
      </div>

      <div>
        <div className="mobile-screen">
          <DevenirTransporteurMobileView />
        </div>
      </div>
    </>
  );
}

export default DevenirTransporteur;

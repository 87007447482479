import PropTypes, { element } from "prop-types";
import LandingPage from "../views/LandingPage";
import LoginPage from "../views/Auth";
import SignUp from "../views/SignUp";
import Profile from "../views/Profile";
import Blogs from "../views/Blogs";
import SendParcel from "../views/SendParcel";
import SendPackage from "../views/SendPackage";
import TranspoprterUnColis from "../views/TransporterUnColis";
import MarketPlace from "../views/MarketPlace";
import DevenirTransporteur from "../views/DevenirTransporteur";
import TransporterJobs from "../views/TransporterUnColis/TransporterJobs";
import SenderInbox from "../views/SenderInbox.tsx";
import AllAnounces from "../views/AllAnounces";
import MyAnnounces from "../views/MyAnnounces";
import ProductDetails from "../views/MarketPlace/ProductDetails";
import AllProductByCateogry from "../views/MarketPlace/AllProductByCateogry";
import JobDetails from "../views/TransporterUnColis/JobDetails";
import AnounceDetails from "../views/MyAnnounces/AnounceDetails";
import { PrivateRoute } from "./AuthenticationRoutes";
import AddProductIndividual from "../views/MarketPlace/Individual/AddProductIndividual";
import AboutPage from "../views/AboutPage";
import ParticularProductDetails from "../views/MarketPlace/Individual/ParticularProductDetails";
import AlltTrendingProducts from "../views/MarketPlace/AlltTrendingProducts";
import AllLatestItem from "../views/MarketPlace/AllLatestItem";
import CheckoutParticular from "../views/MarketPlace/Individual/CheckoutParticular";
import ParticularOrderDetails from "../views/MarketPlace/Individual/ParticularOrderDetails";
import OrderCheckout from "../views/MarketPlace/OrderCheckout";
import Checkout from "../views/MarketPlace/Checkout";
import ParticularShopDetails from "../views/MarketPlace/ParticularShopDetails";
import FrankShopDetails from "../views/MarketPlace/FrankShopDetails";
import SearchProduct from "../views/MarketPlace/SearchProduct";
import ParticularMyListedProduct from "../views/MarketPlace/Individual/ParticularMyListedProduct";
import PurchasedProdDetails from "../views/MarketPlace/PurchasedProdDetails";
import PurchasedParticularProdDetails from "../views/MarketPlace/PurchasedParticularProdDetails";
import MostViewsProducts from "../views/MarketPlace/MostViewsProducts";
import BlogDetails from "../views/Blogs/BlogDetails";
import Unscubscribe from "../views/Unscubscribe";
// ==============================|| MAIN ROUTING ||============================== //

export function MainAppRoutes({ classes }: any) {
  const MainRoutesBefore = {
    path: "/",
    children: [
      {
        path: "",
        element: <LandingPage classes={classes} />,
      },
      {
        path: "login",
        element: <LoginPage classes={classes} />,
      },
      {
        path: "sign-up",
        element: <SignUp classes={classes} />,
      },
      {
        path: "profile",
        element: <PrivateRoute element={<Profile classes={classes} />} />,
      },
      {
        path: "blogs",
        element: <Blogs classes={classes} />,
      },
      {
        path: "send-parcel",
        element: <SendParcel classes={classes} />,
      },
      {
        path: "send-package",
        element: <SendPackage classes={classes} />,
      },
      {
        path: "relocation",
        element: <SendPackage classes={classes} />,
      },
      {
        path: "transporter-un-colis",
        element: <TranspoprterUnColis classes={classes} />,
      },
      {
        path: "devenir-transporter",
        element: <DevenirTransporteur classes={classes} />,
      },

      {
        path: "my-jobs",
        element: (
          <PrivateRoute element={<TransporterJobs classes={classes} />} />
        ),
      },
      {
        path: "chat",
        element: <PrivateRoute element={<SenderInbox classes={classes} />} />,
      },
      {
        path: "announces",
        element: <AllAnounces classes={classes} />,
      },
      {
        path: "my-anounces",
        element: <PrivateRoute element={<MyAnnounces classes={classes} />} />,
      },
      {
        path: "about-us",
        element: <AboutPage classes={classes} />,
      },
      {
        path: "job/:id?",
        element: <PrivateRoute element={<JobDetails classes={classes} />} />,
      },
      {
        path: "blog-details/:id/:idx",
        element: <BlogDetails classes={classes} />,
      },
      {
        path: "anounce/:id?",
        element: (
          <PrivateRoute element={<AnounceDetails classes={classes} />} />
        ),
      },
      {
        path: "unsubscribe",
        element: <Unscubscribe classes={classes} />,
      },
      // comment by asad iqbal 14/1/25  (launching c2c web ppt) 
      // {
      //   path: "marketplace",
      //   element: <MarketPlace classes={classes} />,
      // },
      // {
      //   path: "track-order/:id?",
      //   element: <PrivateRoute element={<PurchasedProdDetails classes={classes} />} />,
      // },
      // {
      //   path: "track-p-order/:id?",
      //   element: <PrivateRoute element={<PurchasedParticularProdDetails classes={classes} />} />,
      // },
      // {
      //   path: "p-order/:id?",
      //   element: (
      //     <PrivateRoute
      //       element={<ParticularOrderDetails classes={classes} />}
      //     />
      //   ),
      // },
      // {
      //   path: "my-p-product/:id?",
      //   element: (
      //     <PrivateRoute
      //       element={<ParticularMyListedProduct classes={classes} />}
      //     />
      //   ),
      // },

      // {
      //   path: "p-checkout?",
      //   element: (
      //     <PrivateRoute element={<CheckoutParticular classes={classes} />} />
      //   ),
      // },
      // {
      //   path: "cart-details",
      //   element:<Checkout classes={classes} />
      //   // element: <PrivateRoute element={} />,
      // },
      // {
      //   path: "order-checkout?",
      //   element: <PrivateRoute element={<OrderCheckout classes={classes} />} />,
      // },
      // {
      //   path: "product/:id?",
      //   element: <ProductDetails classes={classes} />,
      // },
      // {
      //   path: "p-shop-details/:id?",
      //   element: <ParticularShopDetails classes={classes} />,
      // },
      // {
      //   path: "search",
      //   element: <SearchProduct classes={classes} />,
      // },
      // {
      //   path: "shop-details/:id?",
      //   element: <FrankShopDetails classes={classes} />,
      // },
      // {
      //   path: "p-product/:id?",
      //   element: <ParticularProductDetails classes={classes} />,
      // },
      // {
      //   path: "all-products/:id?",
      //   element: <AllProductByCateogry classes={classes} />,
      // },
      // {
      //   path: "add-product-individual",
      //   element: <AddProductIndividual classes={classes} />,
      // },
      // {
      //   path: "trending-products",
      //   element: <AlltTrendingProducts classes={classes} />,
      // },
      // {
      //   path: "latest-products",
      //   element: <AllLatestItem classes={classes} />,
      // },
      // {
      //   path: "most-viewed",
      //   element: <MostViewsProducts classes={classes} />,
      // },
      
      
    ],
  };

  return MainRoutesBefore;
}

MainAppRoutes.propTypes = {
  classes: PropTypes.object,
};

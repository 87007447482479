import {
  East,
  LockOpen,
  MailOutline,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { Divider, Grid } from "@mui/material";
import { GoogleLogin } from "@react-oauth/google";
import React, { useEffect, useState } from "react";
import AppleSignInButton from "react-apple-signin-auth";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../Store";
import { strings } from "../../Store/Localization";
import CustomButton from "../../UiComponent/CustomButton";
import InputField from "../../UiComponent/TextFeild";
import Toast from "../../UiComponent/Toast/Toast";
import { AppleIcon } from "../../assets/images/FrankIcons";
import apiServices from "../../services/RequestHandler";
import {
  getDataFromLocalStorage,
  saveDataToLocalStorage,
} from "../../utils/LocalStore.util";
import { defaultLabelColors } from "../../utils/constants";
import { handleGetMachineDetails } from "../../utils/helper";
import ForgotPassword from "./ForgotPassword";
interface MachineDetail {
  platform: string;
  systemVersion: string;
  deviceName: string;
  deviceId: string;
  deviceType: string;
  device: string;
  ipAddress: string;
  userAgent: string;
  buildNumber: string;
  codename: string;
  type: string;
  brand: string;
  carrier: string;
  readableVersion: string;
  version: string;
  netState: string;
  model_name: string;
}
function AuthAction(props: any) {
  const { handleCloseLoginPage } = props;
  const selector = useSelector((state: RootState) => state.mainApp);
  const [machineDetails, setMachineDetails] = useState<MachineDetail | null>(
    null
  );

  const navigate = useNavigate();
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      username: null,
      password: null,
      viewPassword: false,
      isForgotPassword: false,
    }
  );

  React.useEffect(() => {
    setState({});
  }, [selector.lang]);

  const handleAppleSuccess = async (response: any) => {
    if (response) {
      const resp = await apiServices.postFromApi(
        `c2c/apple-signup/${response?.authorization?.id_token}`,
        { machineDetails, channel: "web" },
        ""
      );
      if (resp?.status === 201 || resp?.status === 200) {
        if (resp?.data?.token) {
          saveDataToLocalStorage("user", resp?.data);
          saveDataToLocalStorage("token", resp?.data?.token);
          navigate("/");
          return;
        }
        navigate("/sign-up", {
          state: {
            ...resp?.data,
          },
        });
      } else {
        Toast.Error(resp?.error);
      }
    }
  };

  const updateCartAfterLogin = async () => {
    try {
      const cartData: any = getDataFromLocalStorage("cart");
      for (const cart_item of cartData) {
        await apiServices.postFromApi(
          "/c2c-carts",
          {
            shop_id: Number(cart_item?.shop_id),
            product_variation_id: Number(cart_item?.id),
            quantity: Number(cart_item.quantity),
          },
          ""
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onInputChange = (e: any) => {
    try {
      const { value, name } = e.target;
      // if (value.startsWith(" ")) {
      //   return;
      // }
      // const trimmedValue = value.replace(/^\s+/, '');

      setState({
        [name]: value,
      });
    } catch (error) {}
  };

  useEffect(() => {
    const fetchMachineDetails = async () => {
      const details = await handleGetMachineDetails();
      setMachineDetails(details);
    };

    fetchMachineDetails();
  }, []);

  const handleLoginWithGoogle = async (credentialResponse: any) => {
    try {
      const resp = await apiServices.postFromApi(
        `c2c/google-signup/${credentialResponse?.credential}`,
        { machineDetails, channel: "web" },
        ""
      );

      if (resp?.status === 201) {
        if (resp?.data?.token) {
          saveDataToLocalStorage("user", resp?.data);
          saveDataToLocalStorage("token", resp?.data?.token);
          navigate("/");
          return;
        }
        navigate("/sign-up", {
          state: {
            ...resp?.data,
          },
        });
      } else {
        Toast.Error(resp?.error);
      }
    } catch (error) {}
  };
  const handleLogin = async () => {
    try {
      // Trim username and password to avoid leading/trailing spaces
      const username = state?.username?.trim();
      const password = state?.password?.trim();
      console.log("username", username);

      // Check if username is provided after trimming
      if (!username) {
        Toast.Error(strings?.email_error);
        return;
      }

      // Check if password is provided after trimming
      if (!password) {
        Toast.Error(strings?.password_error);
        return;
      }

      const obj = {
        username,
        password,
      };

      let resp = await apiServices.postFromApi("c2c/login", obj, "");

      if (
        resp?.data?.id
        // resp?.data?.step_no === 4 || resp?.data?.step_no === 3
      ) {
        if (!resp?.data?.token) {
          navigate("/sign-up", {
            state: {
              ...resp?.data,
            },
          });
          return;
        }
      } else {
        Toast.Error(
          typeof resp?.error === "string"
            ? resp?.error
            : resp?.error?.error ??
                resp?.error?.isNotEmpty ??
                "Internal Server Error"
        );
      }

      // if (!resp?.data?.token) {
      //   Toast.Error("User not Exist");
      //   return;
      // }

      if (resp?.status === 201) {
        setState({ step: resp?.step + 1, username: "", password: "" });
        saveDataToLocalStorage("token", resp?.data?.token);
        saveDataToLocalStorage("user", resp?.data);
        setTimeout(() => {
          updateCartAfterLogin();
        }, 2000);
        let ship_item = getDataFromLocalStorage("shipment");
        if (ship_item) {
          handleCloseLoginPage();
          return;
        }

        if (props?.shouldNavigate === false) {
          handleCloseLoginPage();
          return;
        }
        if (props.redirectURI) {
          window.location.href = `/${props.redirectURI}`;
          return;
        }
        window.location.href = "/";
        return;
      } else {
        // Toast.Error(
        //   resp?.data?.error ??
        //     resp?.error?.error ??
        //     resp?.data?.isEmail ??
        //     resp?.data?.isNotEmpty ??
        //     "Something went wrong. Please wait a short while and try again. If problem persists then contact to support team."
        // );
      }
    } catch (error) {}
  };

  // const login = useGoogleLogin({
  //   onSuccess: async (credentialResponse) => {
  //     try {
  //       const resp = await apiServices.postFromApi(
  //         `c2c/google-signup/${credentialResponse?.access_token}`,
  //         { machineDetails, channel: "web" },
  //         ""
  //       );
  //       if (resp?.status === 201) {
  //         if (resp?.data?.token) {
  //           saveDataToLocalStorage("user", resp?.data);
  //           saveDataToLocalStorage("token", resp?.data?.token);
  //           navigate("/");
  //           return;
  //         }
  //         navigate("/sign-up", {
  //           state: {
  //             ...resp?.data,
  //           },
  //         });
  //       } else {
  //         Toast.Error(resp?.error);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   },
  // });

  return (
    <div>
      <Grid
        container
        spacing={2}
        style={{ padding: "50px" }}
        className="display-flex"
      >
        {state?.isForgotPassword ? (
          <ForgotPassword
            {...state}
            {...props}
            handleBack={() => setState({ isForgotPassword: false })}
          />
        ) : (
          <Grid container spacing={2}>
            <Grid
              xs={12}
              item
              className="display-flex"
              style={{ display: "flex" }}
            >
              <Grid container spacing={2}>
                <Grid xs={12} item>
                  <p
                    className="heading-one"
                    style={{
                      fontFamily: "Raleway-Italic",
                      color: defaultLabelColors.main_blue,
                      textAlign: "center",
                    }}
                  >
                    CONNEXION
                  </p>
                  <p
                    className="paragraph-text"
                    style={{
                      marginTop: "-30px",
                      textAlign: "center",
                      fontFamily: "Raleway-Italic",
                      fontWeight: "500",
                    }}
                  >
                    Insérez votre email ainsi que votre mot de passe
                  </p>
                </Grid>

                <Grid xs={12} item style={{ textAlign: "center" }}>
                  <InputField
                    placeHolder={strings?.user_or_email}
                    onchange={onInputChange}
                    startIcon={<MailOutline />}
                    input_value={state?.username}
                    input_name={"username"}
                    input_style={{
                      background: "#fff",
                      width: "400px",
                      borderRadius: 100,
                    }}
                  />
                </Grid>

                <Grid xs={12} item style={{ textAlign: "center" }}>
                  <InputField
                    placeHolder={strings?.password}
                    input_type={
                      state?.viewPassword === false ? "password" : "text"
                    }
                    startIcon={<LockOpen />}
                    input_value={state?.password}
                    onchange={onInputChange}
                    onKeyDown={(e: any) => {
                      if (e.key === "Enter") {
                        handleLogin();
                      }
                    }}
                    input_name={"password"}
                    input_style={{
                      background: "#fff",
                      width: "400px",
                      borderRadius: 100,
                    }}
                    endIicon={
                      state?.viewPassword ? (
                        <Visibility
                          onClick={() => {
                            setState({
                              viewPassword: !state?.viewPassword,
                            });
                          }}
                          style={{ color: "gray", cursor: "pointer" }}
                        />
                      ) : (
                        <VisibilityOff
                          onClick={() => {
                            setState({
                              viewPassword: !state?.viewPassword,
                            });
                          }}
                          style={{ color: "gray", cursor: "pointer" }}
                        />
                      )
                    }
                  />
                  <p
                    style={{
                      textAlign: "center",
                      cursor: "pointer",
                      fontFamily: "Raleway-Italic",
                      fontWeight: "500",
                    }}
                  >
                    J’ai oublié mon{" "}
                    <span
                      style={{ fontFamily: "Raleway-Bold-Italic" }}
                      onClick={() => {
                        setState({ isForgotPassword: true });
                      }}
                    >
                      {" "}
                      mot de passe
                    </span>
                  </p>
                </Grid>

                <Grid xs={12} item className="display-flex">
                  <CustomButton
                    style={{
                      width: "260px",
                    }}
                    onClick={handleLogin}
                    classesNames={"contained_button_two"}
                    value={
                      <React.Fragment>
                        <div
                          className="display-flex"
                          style={{
                            columnGap: "10px",
                            padding: "8px 0px 8px 0px",
                            fontSize: "16px",
                          }}
                        >
                          ME CONNECTER
                          <East
                            style={{
                              color: defaultLabelColors.main_blue,
                              background: "#f9d9b6",
                              borderRadius: 100,
                              padding: 4,
                              fontSize: 18,
                            }}
                          />
                        </div>
                      </React.Fragment>
                    }
                  />
                </Grid>
                <Grid xs={12} item>
                  <p
                    style={{
                      textAlign: "center",
                      fontFamily: "Raleway-Italic",
                      fontWeight: "500",
                    }}
                  >
                    Pas de compte ?{" "}
                    <span
                      style={{
                        fontFamily: "Raleway-Bold-Italic",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate("/sign-up");
                      }}
                    >
                      Créer un compte
                    </span>
                  </p>
                </Grid>
                <Grid
                  xs={12}
                  item
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Divider
                    color="#000"
                    style={{
                      fontFamily: "Raleway-Italic",
                      fontWeight: "500",
                      width: "500px",
                      textAlign: "center",
                    }}
                  >
                    Ou
                  </Divider>
                </Grid>
                <Grid xs={12} item>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "row",
                      gap: 20,
                    }}
                  >
                    <GoogleLogin
                      useOneTap={true}
                      text="signin_with"
                      onSuccess={handleLoginWithGoogle}
                      shape="pill"
                      width="200"
                      size="large"
                      logo_alignment="center"
                      type="icon"
                      theme="outline"
                      auto_select={false}
                      onError={() => {
                        console.log("Login Failed");
                      }}
                    />

                    <AppleSignInButton
                      authOptions={{
                        clientId: "www.v2internal.frankme.com",
                        scope: "email name",
                        redirectURI: "https://www.v2internal.frankme.com/login",
                        state: "",
                        nonce: `APPLE_SIGNIN_AUTH_${new Date().getTime()}`,
                        usePopup: true,
                      }}
                      uiType="light"
                      onSuccess={handleAppleSuccess}
                      onError={(error: any) => console.log(error)}
                      buttonExtraChildren=""
                      noDefaultStyle={false}
                      className={"round"}
                      render={
                        (appleProps: any) => (
                          <div
                            style={{
                              background: "#fff",
                              padding: "8px 10px",
                              borderRadius: 100,
                              cursor: "pointer",
                              border: "1px solid #dadce0",
                            }}
                            onClick={() => {
                              if (appleProps.onClick) {
                                appleProps.onClick();
                              }
                            }}
                          >
                            <AppleIcon />
                          </div>
                        )
                        // <button>MY APPLE CUSTOM BUTTON</button>
                      }
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default AuthAction;

import React from "react";
import { AppStoreFR, FrankIcon } from "../../assets/images/FrankIcons";
import playSore from "../../assets/images/FrankIcons/PlayStore-btn.png";
import downloadappStoreBanner from "../../assets/images/FrankIcons/download-app-mobiles@4x.png";
import facebookIcon from "../../assets/images/FrankIcons/Artboard_1.png";
import instaIcon from "../../assets/images/FrankIcons/Artboard_2.png";
import linkedInIcon from "../../assets/images/FrankIcons/Artboard_3.png";
import { defaultLabelColors } from "../../utils/constants";
import mobileFooterNewImage from "../../assets/images/FrankIcons/mobileFooterNew.png";
export default function CustomMobileFooter() {
  return (
    <div style={{ background: defaultLabelColors.main_blue,paddingBottom:'40px',paddingLeft:"10px",paddingRight:'10px' }}>
      <div style={{ padding: "50px 30px 10px 20px" }}>
       
        <div style={{ position: "relative", height: "550px" }}>
          <div
            style={{
              rowGap: 20,
              display: "flex",
              flexDirection: "column",
              zIndex: 111,
              position: "absolute",
              padding: 30,
            }}
          >
            <p
              className="heading-two"
              style={{
                fontSize: "22px",
                textAlign: "left",
                color: "#fff",
                padding: "0px 10px 0px 10px",
                fontFamily: "Raleway-Italic",
                margin: 0,
              }}
            >
              Téléchargez l'application mobile Frank
            </p>
            <p
              style={{
                fontFamily: "Raleway-Italic",
                margin: 0,
                color: "#fff",
                paddingLeft: 5,
              }}
            >
              Rejoignez gratuitement la communauté de livraison verte
            </p>

            <div
              className="display-flex"
              style={{
                flexDirection: "row",
                gap: 10,
              }}
            >
              <a
                href="https://apps.apple.com/app/frank/id6502341979"
                target="_blank"
              >
                <AppStoreFR height={40} />
              </a>

              <a
                href="https://play.google.com/store/apps/details?id=com.frankc2c"
                target="_blank"
                style={{ textAlign: "center" }}
              >
                <img
                  src={playSore}
                  alt="google-play"
                  style={{ width: "130px", height: "40px", marginTop: -5 }}
                />
              </a>
            </div>
          </div>
          <img
            src={mobileFooterNewImage}
            alt=""
            style={{ height: "100%", width: "100%", position: "absolute" }}
          />
        </div>
      </div>
      <div
        className="footer-new"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div className="contain" style={{ padding: "20px 0px 0px 0px" }}>
          <div
            className="col"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p style={{margin:0}}>
              <FrankIcon fill={"#fff"} height={80} />
            </p>
          </div>
          <div className="col" style={{ width: "310px" }}>
            <div
              style={{
                border: "1px solid #fff",
                borderRadius: "12px",
                marginTop: "20px",
                padding: "20px",
                width: "100%",
              }}
            >
              <span
                style={{
                  padding: 0,
                  fontSize: "14px",
                  fontWeight: "700",
                  color: "#fff",
                  fontFamily: "Raleway-Italic",
                }}
              >
                Nous Contacter:
              </span>
              <ul>
                <li>hello@frankme.com</li>
                <li> 04 XX XX XX XX</li>
                <li>7 rue du 4 Septembre, 75002 Paris</li>
              </ul>
            </div>
            <div
              style={{
                display: "flex",
                // marginBottom: "70px",
                gap: "10px",
                marginTop: "10px",
                // justifyContent: "center",
                // alignItems: "center",
                flexDirection: "row",
              }}
            >
              <div>
                <img
                  src={facebookIcon}
                  style={{ height: "50px" }}
                  alt="facebook-icon"
                />
              </div>
              <div>
                <img
                  src={instaIcon}
                  style={{ height: "50px" }}
                  alt="instgram-icon"
                />
              </div>
              <div>
                <img
                  src={linkedInIcon}
                  style={{ height: "50px" }}
                  alt="linkedin-icon"
                />
              </div>
            </div>
          </div>

          <div className="col">
            <span
              style={{
                padding: 0,
                fontSize: "14px",
                fontWeight: "700",
                color: "#fff",
                fontFamily: "Raleway-Italic",
              }}
            >
              Particulier
            </span>
            <ul>
              <li>Envoyer un colis</li>
              <li>Transporteur</li>
              <li>Devenir transporteur</li>
            </ul>
          </div>

          <div className="col">
            <span
              style={{
                padding: 0,
                fontSize: "14px",
                fontWeight: "700",
                color: "#fff",
                fontFamily: "Raleway-Italic",
              }}
            >
              Transporteur
            </span>
            <ul>
              <li>Devenir transporteur</li>
              <li>Voir les annonces pour</li>
              <li> une livraison</li>
            </ul>
          </div>

          <div className="col" style={{ display: "flex", fontWeight: "700",width:"350px",gap:20,color:'#fff',fontFamily:"Raleway-Italic" }}>
            <span>Mon compte</span>
            <span>Messagerie</span>
            <span>Blog</span>
            <span>FAQ</span>
          </div>

          <div
            className="col"
            style={{
              width: "100%",
              // marginLeft: "170px",
              fontFamily: "Raleway-Italic",
              fontSize: "14px",
              color: "#fff",
              // marginTop: "-30px",
              // textAlign: "center",
            }}
          >
            <a
              href="https://frankme.com/privacy-policy"
              target="_blank"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              Politique de confidentialité
            </a>
            {" "}
            |
            {" "}

            <a
              style={{ textDecoration: "none", color: "#fff" }}
              href="https://frankme.com/terms-and-condition"
              target="_blank"
            >
              Termes et conditions
            </a>
          </div>

          <div className="clearfix"></div>
        </div>
      </div>
    </div>
  );
}

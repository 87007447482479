import { ArrowBackIosNew, CameraAltOutlined } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardMedia,
  DialogContent,
  Grid,
  Typography
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { Box } from "@mui/system";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import moment from "moment";
import React, { useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import { LocationPinIcon, MapHomeIcon } from "../../assets/images/FrankIcons";
import noImageFile from "../../assets/images/FrankIcons/noImageAvailable.png";
import apiServices from "../../services/RequestHandler";
import { RootState } from "../../Store";
import { strings } from "../../Store/Localization";
import CustomButton from "../../UiComponent/CustomButton";
import CustomFooter from "../../UiComponent/CustomFooter";
import DraggableDialog from "../../UiComponent/DraggableDialog";
import Loader from "../../UiComponent/Loader";
import NewHeader from "../../UiComponent/NewHeader";
import InputField from "../../UiComponent/TextFeild";
import Toast from "../../UiComponent/Toast/Toast";
import { defaultLabelColors } from "../../utils/constants";
import { decrypt } from "../../utils/Encrypt";
import FrankConstants from "../../utils/FrankConstants";
import {
  handleLocalize,
  numberFormatWithoutCurrency,
  numberFormatWithoutCurrencyFixed,
  numberFormatWithoutCurrencyFixedOne,
} from "../../utils/helper";
import StripeCheckout from "./StripeCheckout";

function AnounceDetails(props: any) {
  const { classes } = props;
  const [otp, setOtp] = React.useState("");
  const selector = useSelector((state: RootState) => state.mainApp);
  const [selectedImage, setSelectedImage] = useState("");
  const theme = useTheme();
  const param = useParams();
  const navigate = useNavigate();
  const [isShaking, setIsShaking] = useState(false);
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      showPickupOtpScreen: false,
      openStripeForm: false,
      loadingLoader: true,
      openConfirmDialog: false,
      ratingsCount: 1,
      itemDetailsDialog: false,
    }
  );

  const stripePromise = loadStripe(
    "pk_test_51PHLwERrtK56w4VJovEUBRsfDb0K90Mo3tt7QrypEedKpj9HuTszcu8bnI8SnXsmA7EWSwZ87LorkcfAHi7zXz4l00kogJUBL5"
    // "pk_live_51PHLwERrtK56w4VJcMQLW2zbn7LTZPNZnBLUmFdMIakX5v5HKsPRllmYUMmWJXvmPVJaptcEN8uxmQdzDNVJYFUC0050uiD5gE"
  );

  const handleGetProdInfo = async (job_id: any) => {
    try {
      const resp = await apiServices.getFromApi(`c2c-shipments/${job_id}`, "");
      const data = resp.data;

      //   handleGetShopInfo(job_details?.shop_id);
      let total_amt = 0;
      if (data?.handling) {
        total_amt =
          data?.order_amount_detail?.sub_total > 0
            ? Number(data?.order_amount_detail?.sub_total ?? 0)
            : Number(data?.agree_ship_price ?? data?.ship_price ?? 0) +
              Number(
                (Number(data?.agree_ship_price ?? data?.ship_price ?? 0) /
                  100) *
                  Number(data?.handling?.handling_charges_percent)
              ) +
              Number(data?.service_charges ?? 0);
      } else {
        total_amt =
          data?.order_amount_detail?.sub_total > 0
            ? Number(data?.order_amount_detail?.sub_total ?? 0) +
              Number(data?.service_charges ?? 0)
            : Number(data?.agree_ship_price ?? data?.ship_price ?? 0) +
              Number(data?.service_charges ?? 0);
      }
      setSelectedImage(data?.shipment_items[0]?.prod_images[0] ?? noImageFile);
      setState({
        job_details: data,
        total_amt,
        loadingLoader: false,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const [expanded, setExpanded] = useState<Record<number, boolean>>({});

  const toggleViewMore = (index: any) => {
    setExpanded((prev) => ({
      ...prev,
      [index]: !prev[index] as any,
    }));
  };
  const onInputChange = (e: any) => {
    try {
      const { value, name } = e.target;
      if (value[0] === " ") {
        return;
      }

      setState({
        [name]: value,
      });
    } catch (error) {}
  };

  const handleDeleteShipment = async () => {
    try {
      let resp = await apiServices.postFromApi(
        `c2c-shipments/delete/${state?.job_details?.id}`,
        {},
        ""
      );
      if (resp?.status === 200 || resp?.status === 201) {
        setState({ openConfirmDialog: false });
        Toast.showSuccess("Annouce Deleted Succesfully");
        navigate(-1);
      }
    } catch (error) {}
  };
  const getItemDetails = (item: any) => {
    try {
      console.log("getItemDetails", item);
      setSelectedImage(item?.prod_images?.[0] ?? noImageFile);
      setState({
        itemDetails: item,
        itemDetailsDialog: true,
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (
      state.job_details?.ship_status_level === 2 &&
      state.job_details?.payment_status !== "Paid"
    ) {
      initPayment();
    }
  }, [JSON.stringify(state.job_details)]);

  const handleSendOtpAfterPayment = async () => {
    try {
      const res = await apiServices.getFromApi(
        `c2c-shipments/generate-pickup-otp/${state?.job_details?.id}`,
        ""
      );
      if (res?.status === 200 || res?.status === 201) {
        Toast.showSuccess("Paiement validé");
        setState({
          openStripeForm: false,
        });
        handleGetProdInfo(param?.id);
      }
    } catch (error) {}
  };

  const initPayment = async () => {
    try {
      let total_amt = 0;
      if (state.job_details?.handling) {
        total_amt =
          state.job_details?.order_amount_detail?.sub_total > 0
            ? Number(state.job_details?.order_amount_detail?.sub_total ?? 0)
            : Number(
                state.job_details?.agree_ship_price ??
                  state.job_details?.ship_price ??
                  0
              ) +
              Number(
                (Number(
                  state.job_details?.agree_ship_price ??
                    state.job_details?.ship_price ??
                    0
                ) /
                  100) *
                  Number(state.job_details?.handling?.handling_charges_percent)
              ) +
              Number(state.job_details?.service_charges ?? 0);
      } else {
        total_amt =
          state.job_details?.order_amount_detail?.sub_total > 0
            ? Number(state.job_details?.order_amount_detail?.sub_total ?? 0) +
              Number(state.job_details?.service_charges ?? 0)
            : Number(
                state.job_details?.agree_ship_price ??
                  state.job_details?.ship_price ??
                  0
              ) + Number(state.job_details?.service_charges ?? 0);
      }

      const response = await apiServices.postFromApi(
        "stripe/payment-sheet-with-card",
        {
          ship_id: Number(state?.job_details?.id),
          amount: Number(total_amt.toFixed(2)),
        },
        ""
      );
      let decryptData = JSON.parse(decrypt(response.pi_key));
      if (typeof decryptData === "string") {
        decryptData = JSON.parse(decryptData);
      }
      const { setupIntent, paymentIntent, ephemeralKey, customer } =
        decryptData ?? {};

      const options = {
        theme: "stripe",

        clientSecret: paymentIntent,
        appearance: {
          theme: "stripe",
          variables: {
            fontFamily: '"Raleway-Variable", sans-serif',
          },
          rules: {
            ".Label": {
              fontFamily: '"Raleway-Variable", sans-serif',
              fontSize: "14px", // Adjust the size as needed
              color: "#000000", // Change the color if needed
            },
          },
        },
      };

      setState({ options, show_stripe: true });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetProdInfo(param?.id);
  }, [param?.id]);

  const triggerShake = () => {
    setIsShaking(true);
    setTimeout(() => {
      setIsShaking(false);
    }, 500);
  };
  const handleAddFeedback = async () => {
    try {
      let obj = {
        order_id: Number(state.job_details?.id),
        rating_to_id: `${state.job_details?.acc_id}`,
        rating_from_id: `${state.job_details?.user_details?.id}`,
        rating: state?.ratingsCount ?? 1,
        feedback: state?.feedback,
      };
      console.log("obj", obj);

      let resp = await apiServices.postFromApi(
        "c2c-feedbacks",
        { data: [obj] },
        ""
      );

      if (resp?.status === 200 || resp?.status === 201) {
        handleGetProdInfo(state.job_details?.id);
        setState({
          feedbackDialog: false,
        });
      }
    } catch (error) {}
  };

  const transSizes = (item:any) => {
    console.log(item);
    
   const deliveryType = FrankConstants.packageSize(selector.lang)?.find(
        (a: any) => a.size === item?.cat_name
      );
  return deliveryType?.size_trans
  
    }

  const { job_details } = state;
  return (
    <div className="web-screen">
      <Loader loading={state?.loadingLoader} />
      <div style={{ background: "#f9c9b3", minHeight: "100vh" }}>
        <Grid
          container
          spacing={2}
          className="display-flex"
          style={{
            background: "#F4C9B3",
            paddingLeft: "40px",
            paddingRight: "40px",
            paddingTop: "30px",
          }}
        >
          <Grid xs={12} item style={{ zIndex: 99 }}>
            <NewHeader />
          </Grid>
        </Grid>
        <div style={{ padding: "30px 80px 50px 80px" }}>
          <Grid container spacing={2} style={{ paddingBottom: "40px" }}>
            <Grid
              xs={12}
              item
              style={{
                display: "flex",
                fontFamily: "Raleway-Bold",
                fontSize: "26px",
                color: "#4B4A61",
                gap: 10,
                alignItems: "center",
              }}
            >
              <ArrowBackIosNew
                onClick={() => {
                  navigate(-1);
                }}
                style={{ cursor: "pointer" }}
              />{" "}
              Récapitulatif de la commande •   {" "}
              {/* <span
                style={{
                  fontFamily: "Raleway-Bold",
                  color: defaultLabelColors.main_orange,
                }}
              >
                {"À  Envoyer "}
              </span> */}
              <h1 style={{ fontFamily: "Raleway-Bold", fontSize: "26px",                  color: defaultLabelColors.main_orange, }}>
                {job_details?.shipment_items?.length > 1 ? (
                  <>
                    {" "}
                    Lot de {`(${
                      job_details?.shipment_items?.length ?? 0
                    })`}{" "}
                    colis{" "}
                  </>
                ) : (
                  handleLocalize({
                    en: job_details?.shipment_items[0]?.item_name_en?.en,
                    fr: job_details?.shipment_items[0]?.item_name_fr?.en,
                  }) ?? job_details?.shipment_items[0]?.prod_name
                )}
              </h1>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            style={{
              background: "#fff",
              borderRadius: "16px",
              padding: "20px",
              gap: 20,
              minHeight: "110vh",
            }}
          >
            {job_details?.shipment_items?.length > 1 ? (
              <Grid xs={12} md={5.8} item style={{ paddingTop: 5 }}>
                <Card
                  elevation={0}
                  sx={{
                    borderRadius: "24px",
                    overflow: "hidden",
                    padding: "16px",
                    border: "1px solid #E0E0E0",
                    fontFamily: "Raleway-Semi-Bold",
                  }}
                >
                  <div style={{ padding: 10 }}>
                    Lot de {`(${job_details?.shipment_items?.length ?? 0})`}{" "}
                    colis
                  </div>
                </Card>

                {job_details?.shipment_items?.map((i: any, index: any) => {
                  return (
                    <Card
                      elevation={0}
                      sx={{
                        borderRadius: "24px",
                        overflow: "hidden",
                        padding: "16px",
                        border: "1px solid #E0E0E0",
                        marginTop: "10px",
                        fontFamily: "Raleway-Semi-Bold",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          gap: 10,
                        }}
                      >
                        <div
                          style={{
                            flex: 1,
                            fontFamily: "Raleway-Bold",
                            display: "flex",
                            alignItems: "center",
                            fontSize: "18px",
                            maxWidth: "70px",
                          }}
                        >
                          Colis {index + 1}
                        </div>
                        <div
                          style={{
                            position: "relative",
                            height: "150px",
                            width: "50px",
                            flex: 1,
                            maxWidth: "150px",
                            cursor:'pointer'
                          }}
                          onClick={()=>getItemDetails(i)}
                        >
                          <img
                            src={i?.prod_images?.[0]}
                            alt="prod-img"
                            style={{
                              position: "absolute",
                              height: "100%",
                              width: "100%",
                              objectFit: "cover",
                              borderRadius: 16,
                            }}
                          />
                          <span
                            style={{
                              position: "absolute",
                              fontSize: 8,
                              fontFamily: "Raleway-Semi-Bold",
                              color: "#fff",
                              bottom: 0,
                              right: 0,
                              background: defaultLabelColors.main_orange,
                              borderRadius: 50,
                              padding: 5,
                              display: "flex",
                              alignItems: "center",
                              gap: 5,
                              marginBottom: 5,
                              marginRight: 5,
                            }}
                          >
                            {" "}
                            <CameraAltOutlined
                              style={{ color: "#fff", fontSize: 12 }}
                            />{" "}
                            {`1/${i?.prod_images?.length}`}{" "}
                          </span>
                        </div>
                        <div style={{ flex: 1 }}>
                          <p
                            style={{
                              fontFamily: "Raleway-Bold",
                              fontSize: "18px",
                              margin: 0,
                            }}
                          >
                            Description :
                          </p>
                          <p
                            style={{
                              fontFamily: "Raleway-Variable",
                              margin: 0,
                              fontSize: "16px",
                            }}
                          >
                            {handleLocalize({
                              en: i?.item_name_en,
                              fr: i?.item_name_fr,
                            }) ??
                              i?.prod_name ??
                              ""}

                            <span
                              style={{
                                fontFamily: "Raleway-Variable",
                                paddingLeft: 5,
                              }}
                            >
                              {i?.prod_height && i?.prod_length && i?.prod_width
                                ? `${i?.prod_length}X${i?.prod_width}`
                                : null}
                            </span>
                          </p>

                          <div key={index} style={{ marginBottom: "16px" }}>
                            <p style={{ margin: 0 }}>
                              {i.prod_desc ? (
                                <span
                                  style={{
                                    fontFamily: "Raleway-Variable",
                                    fontSize: 12,
                                    margin: 0,
                                    display: "-webkit-box",
                                    WebkitBoxOrient: "vertical",
                                    WebkitLineClamp: expanded[index]
                                      ? "none"
                                      : 2,
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {i.prod_desc}
                                </span>
                              ) : null}
                            </p>
                            {i?.prod_quantity ? (
                              <p
                                style={{
                                  fontFamily: "Raleway-Semi-Bold",
                                  fontSize: 12,
                                  margin:0
                                }}
                              >
                                X{i?.prod_quantity}
                              </p>
                            ) : null}

                            {i?.prod_is_fragile ? (
                              <p
                                style={{
                                  fontFamily: "Raleway-Variable",
                                  fontSize: 12,
                                  margin: 0,
                                }}
                              >
                                Fragile
                              </p>
                            ) : null}
                            {i.prod_desc && i.prod_desc.length > 100 && (
                              <span
                                onClick={() => toggleViewMore(index)}
                                style={{
                                  cursor: "pointer",
                                  color: "black",
                                  textDecoration: "underline",
                                  marginTop:'5px'
                                }}
                              >
                                {expanded[index] ? "Voir moins" : "Voir plus"}
                              </span>
                            )}
                          </div>

                          <p
                            style={{
                              fontFamily: "Raleway-Bold",
                              fontSize: "18px",
                              margin: 0,
                              paddingTop: 5,
                            }}
                          >
                            Taille du colis :
                          </p>
                          <p
                            style={{
                              fontFamily: "Raleway-Semi-Bold",
                              margin: 0,
                              fontSize: 14,
                              gap: 5,
                            }}
                          >
                            {transSizes(i)}
                          </p>
                        </div>
                         
                      </div>
                    </Card>
                  );
                })}
              </Grid>
            ) : (
              <Grid
                xs={12}
                md={5.8}
                item
                style={{
                  borderRadius: "16px",
                  height: "100%",
                  padding: 0,
                }}
              >
                <Card
                  elevation={0}
                  sx={{
                    borderRadius: "32px",
                    overflow: "hidden",
                    border: "1px solid #E0E0E0",
                  }}
                >
                  <Box
                    position="relative"
                    sx={{ borderRadius: "32px 32px 0px 0px" }}
                  >
                    <div style={{ height: "480px" }} className="display-flex">
                      <CardMedia
                        component="img"
                        image={
                          selectedImage ?? "https://via.placeholder.com/300x200"
                        }
                        alt="Product Image"
                        sx={{
                          objectFit: "contain",
                          width: "98%",
                          height: "460px",
                          borderRadius: "32px 32px 0px 0px",
                        }}
                      />
                    </div>
                  </Box>

                  <CardContent>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "30px",
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <Typography
                          variant="h6"
                          color="#3B3A3A"
                          sx={{
                            fontFamily: "Raleway-Bold",
                          }}
                        >
                          {handleLocalize({
                            en: job_details?.shipment_items[0]?.item_name_en
                              ?.en,
                            fr: job_details?.shipment_items[0]?.item_name_fr
                              ?.en,
                          }) ??
                            job_details?.shipment_items[0]?.prod_name ??
                            ""}
                          <span> </span>
                        </Typography>
                      </div>

                      <div
                        style={{
                          color: defaultLabelColors.main_orange,
                          fontFamily: "Raleway-Extra-Bold",
                          fontSize: "22px",
                          gap: "10px",
                          marginRight: "10px",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "24px",
                            fontFamily: "Raleway-Bold",
                            color: "#000",
                          }}
                        >
                          Tarif accepté &nbsp;
                        </span>
                        {numberFormatWithoutCurrency(
                          job_details?.agree_ship_price
                            ? job_details?.agree_ship_price
                            : job_details?.ship_price
                        ) ?? ""}
                        &nbsp;€
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: 5,
                      }}
                    >
                      {job_details?.shipment_items[0]?.prod_images?.map(
                        (item: any) => {
                          return (
                            <div
                              className="display-flex"
                              style={{
                                height: "116px",
                                width: "116px",
                                cursor: "pointer",
                                borderRadius: "12px",
                                border: `2px solid ${
                                  selectedImage === item
                                    ? defaultLabelColors.main_orange
                                    : "grey"
                                } `,
                              }}
                              onClick={() => {
                                setSelectedImage(item);
                              }}
                            >
                              <img
                                src={item}
                                alt="prod-img"
                                style={{
                                  objectFit: "contain",
                                  height: "110px",
                                }}
                              />
                            </div>
                          );
                        }
                      )}
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            )}

            <Grid
              xs={12}
              md={5.8}
              item
              style={{
                borderRadius: "16px",
                height: "100%",
                padding: 0,
              }}
            >
              <Card
                elevation={0}
                sx={{
                  borderRadius: "32px",
                  overflow: "hidden",
                  border: "1px solid #E0E0E0",
                  paddingLeft: "15px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <div
                    style={{
                      borderRadius: 100,
                      background: "#f2f2f2",
                      padding: 15,
                      height: "100%",
                    }}
                    className="display-flex"
                  >
                    <MapHomeIcon
                      height={20}
                      width={20}
                      fill={defaultLabelColors.main_blue}
                    />
                  </div>
                  <div style={{ flex: 1, width: "90%" }}>
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      Lieu de départ
                    </p>
                    <p
                      style={{
                        fontSize: "16px",
                        fontFamily: "Raleway-Bold",
                      }}
                    >
                      {job_details?.pickup?.address}
                    </p>
                  </div>
                </div>
              </Card>

              <Card
                elevation={0}
                sx={{
                  borderRadius: "32px",
                  overflow: "hidden",
                  border: "1px solid #E0E0E0",
                  marginTop: "10px",
                  paddingLeft: "15px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <div
                    style={{
                      borderRadius: 100,
                      background: "#f2f2f2",
                      padding: 13,
                      height: "100%",
                    }}
                    className="display-flex"
                  >
                    <LocationPinIcon
                      height={23}
                      width={23}
                      fill={defaultLabelColors.main_orange}
                    />
                  </div>
                  <div style={{ flex: 1, width: "90%" }}>
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      Lieu d’arrivé
                    </p>
                    <p
                      style={{
                        fontSize: "16px",
                        fontFamily: "Raleway-Bold",
                      }}
                    >
                      {job_details?.dropoff?.address ?? ""}
                    </p>
                  </div>
                </div>
              </Card>

              <Card
                elevation={0}
                sx={{
                  borderRadius: "32px",
                  overflow: "hidden",
                  border: "1px solid #E0E0E0",
                  paddingLeft: "15px",
                  marginTop: "15px",
                }}
              >
                <div>
                  <p
                    style={{
                      fontSize: "18px",
                      fontFamily: "Raleway-Extra-Bold",
                    }}
                  >
                    Destinataire
                  </p>

                  <p
                    style={{
                      fontFamily: "Raleway-Bold",
                      fontSize: "18px",
                      textDecoration: "none",
                    }}
                  >
                   
                   {job_details?.recipient_name??""}
                  </p>
                  <p
                    style={{
                      fontFamily: "Raleway-Bold",
                      fontSize: "18px",
                      textDecoration: "none",
                    }}
                  >
                    <a
                      href={`tel:${job_details?.recipient_phone_code}${job_details?.recipient_phone}`}
                    >
                      {job_details?.recipient_phone_code}{" "}
                      {job_details?.recipient_phone}
                    </a>
                  </p>
                </div>
              </Card>

              {job_details?.shipment_items?.length > 1 ? null : (
                <Card
                  elevation={0}
                  sx={{
                    borderRadius: "32px",
                    overflow: "hidden",
                    border: "1px solid #E0E0E0",
                    paddingLeft: "15px",
                    marginTop: "15px",
                  }}
                >
                  <div>
                    <p
                      style={{
                        fontSize: "18px",
                        fontFamily: "Raleway-Extra-Bold",
                      }}
                    >
                      Taille de mon colis
                    </p>
                    <p
                      style={{
                        fontFamily: "Raleway-Bold",
                        fontSize: "16px",
                      }}
                    >
                      <span style={{ color: "#000" }}>
                         {transSizes(job_details?.shipment_items?.[0])}
                       </span>
                    </p>
                  </div>
                </Card>
              )}

              {job_details?.shipment_items?.length > 1 ? null : (
                <Card
                  elevation={0}
                  sx={{
                    borderRadius: "32px",
                    overflow: "hidden",
                    border: "1px solid #E0E0E0",
                    paddingLeft: "15px",
                    marginTop: "15px",
                  }}
                >
                  <div>
                    <p
                      style={{
                        fontSize: "18px",
                        fontFamily: "Raleway-Extra-Bold",
                      }}
                    >
                      Description
                    </p>
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      {job_details?.shipment_items[0]?.prod_desc ?? ""}
                      {job_details?.shipment_items[0]?.prod_desc ? (
                        <br />
                      ) : null}
                      {job_details?.shipment_items[0]?.prod_length ?? 0} x{""}
                      {job_details?.shipment_items[0]?.prod_height ?? 0} x{""}
                      {job_details?.shipment_items[0]?.prod_width ?? 0}
                      <br />
                      Très bon état
                    </p>
                  </div>
                </Card>
              )}

              <Card
                elevation={0}
                sx={{
                  borderRadius: "32px",
                  overflow: "hidden",
                  border: "1px solid #E0E0E0",
                  paddingLeft: "15px",
                  marginTop: "15px",
                }}
              >
                <div>


                  {job_details?.promo ?  <p style={{fontFamily:"Raleway-Bold",fontSize:'18px',color:defaultLabelColors.main_orange}}>Promo Code Applied 🥳</p> : null}
                  <p
                    style={{
                      fontSize: "18px",
                      fontFamily: "Raleway-Extra-Bold",
                    }}
                  >
                    Votre trajet
                  </p>
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    {strings?.delivery_cost} &nbsp; &nbsp; &nbsp;{" "}
                    {job_details?.promo ?  
<span style={{gap:5}}>

                   <span style={{color:'red'}}><s> {numberFormatWithoutCurrency(job_details?.promo?.actual_order_amount)}</s></span>

                   <span > {numberFormatWithoutCurrency(job_details?.promo?.actual_order_amount - job_details?.promo?.discount_amount )}</span>
                   </span>
                    
                    :
                    job_details?.agree_ship_price
                      ? numberFormatWithoutCurrency(
                          job_details?.agree_ship_price
                        )
                      : numberFormatWithoutCurrency(
                          job_details?.ship_price
                        )}{" "}
                    €
                  </p>
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    {strings?.service_charges}
                    &nbsp; &nbsp; &nbsp;{" "}
                    {job_details?.promo ? 
                    <span>                    <span style={{color:'red'}}><s>
                      {job_details?.service_charges}
                   </s> </span>

                   <span>
                    0.00
                   </span>
                   </span>

                    : job_details?.service_charges ?? "1.99"} €
                  </p>

                  <p
                    style={{
                      fontFamily: "Raleway-Bold",
                      fontSize: "20px",
                      color: defaultLabelColors.main_orange,
                    }}
                  >
                    {strings?.total}
                    &nbsp; &nbsp; &nbsp;{" "}
                    {job_details?.promo ? 
                    numberFormatWithoutCurrency(job_details?.promo?.is_platform_charges === true ?  job_details?.promo?.actual_order_amount - job_details?.promo?.discount_amount :  job_details?.promo?.actual_order_amount - job_details?.promo?.discount_amount + job_details?.service_charges  )
                    :
                    numberFormatWithoutCurrencyFixed(
                      job_details?.agree_ship_price
                        ? job_details?.agree_ship_price +
                            job_details?.service_charges
                        : job_details?.ship_price + job_details?.service_charges
                    )}
                    €
                  </p>
                </div>
              </Card>

              {job_details?.ship_status_level === 1 ||
              job_details?.ship_status === "Placed" ? (
                <CustomButton
                  value={"Supprimer l’annonce"}
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#fff",
                    fontFamily: "Raleway-Variable",
                    background: "#ee6931",
                    border: "none",
                    borderRadius: "12px",
                    padding: "10px 40px 10px 40px",
                    width: "100%",
                    marginTop: "10px",
                  }}
                  onClick={() => {
                    setState({ openConfirmDialog: true });
                  }}
                />
              ) : null}
            </Grid>

            <Grid xs={12} item>
              <Grid
                container
                style={{
                  background: defaultLabelColors.main_orange,
                  borderRadius: "24px",
                  padding: 10,
                }}
              >
                <Grid xs={12} md={2.25} item className="display-flex">
                  <p
                    style={{
                      fontFamily: "Raleway-Extra-Bold",
                      color: "#fff",
                      fontSize: "22px",
                    }}
                  >
                    01
                    <br />
                    <span style={{ fontSize: "18px" }}>
                      {" "}
                      Mise en ligne <br />
                      {job_details?.order_statuses[0]?.created_at
                        ? moment(
                            `${job_details?.order_statuses[0]?.created_at}`.endsWith(
                              "Z"
                            )
                              ? job_details?.order_statuses[0]?.created_at
                              : job_details?.order_statuses[0]?.created_at + "Z"
                          ).format("DD/MM/YYYY HH:mm")
                        : "- - -"}
                    </span>
                  </p>
                </Grid>
                <Grid xs={12} md={2.25} item className="display-flex">
                  <p
                    style={{
                      fontFamily: "Raleway-Extra-Bold",
                      color: "#fff",

                      fontSize: "22px",
                    }}
                  >
                    02
                    <br />
                    <span style={{ fontSize: "18px" }}>
                      {" "}
                      Accepté <br />
                      {job_details?.order_statuses[1]?.created_at
                        ? moment(
                            `${job_details?.order_statuses[1]?.created_at}`.endsWith(
                              "Z"
                            )
                              ? job_details?.order_statuses[1]?.created_at
                              : job_details?.order_statuses[1]?.created_at + "Z"
                          ).format("DD/MM/YYYY HH:mm")
                        : "- - -"}
                    </span>
                  </p>
                </Grid>
                <Grid xs={12} md={2.25} item className="display-flex">
                  <p
                    style={{
                      fontFamily: "Raleway-Extra-Bold",
                      color: "#fff",

                      fontSize: "22px",
                    }}
                  >
                    03
                    <br />
                    <span style={{ fontSize: "18px" }}>
                      {" "}
                      Expédié <br />
                      {job_details?.order_statuses[2]?.created_at
                        ? moment(
                            `${job_details?.order_statuses[2]?.created_at}`.endsWith(
                              "Z"
                            )
                              ? job_details?.order_statuses[2]?.created_at
                              : job_details?.order_statuses[2]?.created_at + "Z"
                          ).format("DD/MM/YYYY HH:mm")
                        : "- - -"}
                    </span>
                  </p>
                </Grid>
                <Grid xs={12} md={2.25} item className="display-flex">
                  <p
                    style={{
                      fontFamily: "Raleway-Extra-Bold",
                      color: "#fff",

                      fontSize: "22px",
                    }}
                  >
                    04
                    <br />
                    <span style={{ fontSize: "18px" }}>
                      {" "}
                      Livré
                      <br />
                      {job_details?.order_statuses[3]?.created_at
                        ? moment(
                            `${job_details?.order_statuses[3]?.created_at}`.endsWith(
                              "Z"
                            )
                              ? job_details?.order_statuses[3]?.created_at
                              : job_details?.order_statuses[3]?.created_at + "Z"
                          ).format("DD/MM/YYYY HH:mm")
                        : "- - -"}
                    </span>
                  </p>
                </Grid>
                <Grid xs={12} md={3} item className="display-flex">
                  {job_details?.ship_status_level === 2 &&
                  job_details?.payment_status !== "Paid" ? (
                    <div
                      style={{
                        background: "#fff",
                        borderRadius: "16px",
                        color: "#000",
                        fontSize: "18px",
                        fontFamily: "Raleway-Extra-Bold",
                        padding: 20,
                        textAlign: "center",
                        cursor: "pointer",
                        width: "231px",
                      }}
                      onClick={() => {
                        setState({ openStripeForm: true });
                      }}
                    >
                      {strings?.continue_payment}
                    </div>
                  ) : null}

                  {job_details?.ship_pickup_otp &&
                  job_details?.ship_status === "Accepted" ? (
                    <>
                      <div
                        style={{
                          padding: "10px 0px 10px 0px",
                        }}
                      >
                        <OTPInput
                          inputStyle={{
                            fontFamily: "Raleway-Variable",
                            fontSize: "22px",
                            fontWeight: "900",
                            color: "#000",
                            border: "none",
                            background: "#F4F3F6",
                            borderRadius: "12px",
                            padding: "16px",
                          }}
                          inputType="number"
                          value={job_details?.ship_pickup_otp}
                          onChange={(otpValue) => {
                            setOtp(otpValue);
                          }}
                          numInputs={4}
                          renderSeparator={
                            <span style={{ padding: "5px" }}></span>
                          }
                          renderInput={(props) => <input {...props} readOnly />}
                        />
                        <p
                          style={{
                            fontFamily: "Raleway-Extra-Bold",
                            fontSize: "20px",
                            color: "#fff",
                            margin: 0,
                            paddingTop: "5px",
                          }}
                        >
                          Code à transmettre
                          <br /> au transporteur
                        </p>
                      </div>
                    </>
                  ) : null}

                  {job_details?.ship_deliver_otp &&
                  job_details?.ship_status === "Picked" ? (
                    <>
                      <div
                        style={{
                          padding: "10px 0px 10px 0px",
                        }}
                      >
                        <OTPInput
                          inputStyle={{
                            fontFamily: "Raleway-Extra-Bold",
                            fontSize: "22px",
                            color: "#000",
                            border: "none",
                            background: "#F4F3F6",
                            borderRadius: "12px",
                            padding: "16px",
                          }}
                          inputType="number"
                          value={job_details?.ship_deliver_otp}
                          onChange={(otpValue) => {
                            setOtp(otpValue);
                          }}
                          numInputs={4}
                          renderSeparator={
                            <span style={{ padding: "5px" }}></span>
                          }
                          renderInput={(props) => <input {...props} readOnly />}
                        />
                        <p
                          style={{
                            fontFamily: "Raleway-Extra-Bold",
                            fontSize: "20px",
                            color: "#fff",
                            margin: 0,
                            paddingTop: "5px",
                          }}
                        >
                          Code à transmettre
                          <br /> au transporteur
                        </p>
                      </div>
                    </>
                  ) : null}

                  {job_details?.ship_status === "Deliver" ? (
                    job_details?.feedback ? null : (
                      <>
                        <div
                          style={{
                            background: "#fff",
                            borderRadius: "16px",
                            color: "#000",
                            fontSize: "18px",

                            fontFamily: "Raleway-Extra-Bold",
                            padding: 20,
                            textAlign: "center",
                            cursor: "pointer",
                            width: "231px",
                          }}
                          onClick={() => {
                            setState({ feedbackDialog: true });
                          }}
                        >
                          Laisser un commentaire
                        </div>
                      </>
                    )
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <div
          className="main-container"
          style={{ background: "#fff", paddingTop: "90px" }}
        >
          <CustomFooter />
        </div>
      </div>

      <DraggableDialog
        maxWidth={"sm"}
        open={state?.openStripeForm}
        handleClose={() => {
          setState({ openStripeForm: false });
        }}
        dialog_title={strings.payments}
        classes={classes}
        dialogBody={
          <DialogContent
            style={
              {
                // display: "flex",
                // alignItems: "center",
                // justifyContent: "center",
                // flexDirection: "column",
              }
            }
            dividers
          >
            <div style={{ gap: 10 }}>
              <div>
                <div>
                  {job_details?.shipment_items?.length > 1 ? (
                    <div className="card-container" style={{ marginTop: 5 }}>
                      <img
                        src={job_details?.shipment_items?.[0]?.prod_images[0]}
                        className="card-image"
                        alt="prod-img"
                      />
                      <div className="card-details">
                        <div className="card-title" style={{ fontSize: 17 }}>
                          {`Lot de (${job_details?.shipment_items?.length}) colis`}
                        </div>
                      </div>
                      <div
                        className="card-quantity"
                        style={{
                          paddingRight: "20px",
                          color: defaultLabelColors.main_orange,
                          fontSize: "22px",
                        }}
                      >
                        <span
                          style={{
                            fontSize: 16,
                            color: "#000",
                            paddingRight: 5,
                          }}
                        >
                          Total à payer
                        </span>
                        {numberFormatWithoutCurrency(state?.total_amt ?? 0.0)}
                        &nbsp; €
                      </div>
                    </div>
                  ) : (
                    job_details?.shipment_items?.map((i: any) => {
                      return (
                        <div
                          className="card-container"
                          style={{ marginTop: "10px" }}
                        >
                          <img
                            src={i?.prod_images[0]}
                            className="card-image"
                            alt="prod-img"
                          />
                          <div className="card-details">
                            <div
                              className="card-title"
                              style={{ fontSize: 17 }}
                            >
                              {i?.prod_name ?? ""}
                            </div>

                            <div className="card-quantity">
                              x{i?.prod_quantity}
                            </div>
                            {i?.prod_is_fragile ? (
                              <div
                                className="card-quantity"
                                style={{
                                  fontSize: "14px",
                                  fontFamily: "Raleway-Variable",
                                  fontWeight: "500",
                                  marginTop: 5,
                                }}
                              >
                                <span style={{ fontWeight: 600 }}>Fragile</span>{" "}
                                : manipuler avec soin
                              </div>
                            ) : null}
                          </div>
                          <div
                            className="card-quantity"
                            style={{
                              paddingRight: "20px",
                              color: defaultLabelColors.main_orange,
                              fontSize: "22px",
                            }}
                          >
                            {numberFormatWithoutCurrency(
                              state?.total_amt,
                              selector.lang
                            )}{" "}
                            €
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Elements stripe={stripePromise} options={state.options}>
                  <StripeCheckout handleOtpSend={handleSendOtpAfterPayment} />
                </Elements>
              </div>
            </div>
          </DialogContent>
        }
      />
      <DraggableDialog
        maxWidth={"sm"}
        open={state?.openConfirmDialog}
        handleClose={() => {
          setState({ openConfirmDialog: false });
        }}
        dialog_title={"Confirmation"}
        classes={classes}
        dialogBody={
          <DialogContent style={{}} dividers>
            <div>
              <p
                style={{
                  fontFamily: "Raleway-Variable",
                  fontSize: "26px",
                  fontWeight: "600",
                }}
              >
                Voulez-vous supprimer votre annonce ?
              </p>
            </div>
          </DialogContent>
        }
        dialogFooter={
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              gap: 10,
              padding: 10,
            }}
          >
            <CustomButton
              value={"Oui"}
              style={{
                fontSize: "16px",
                fontWeight: "500",
                color: "#fff",
                fontFamily: "Raleway-Variable",
                background: "#ee6931",
                border: "none",
                borderRadius: "12px",
                padding: "10px 40px 10px 40px",
                marginTop: "10px",
              }}
              onClick={handleDeleteShipment}
            />
            <CustomButton
              value={"Non"}
              style={{
                fontSize: "16px",
                fontWeight: "500",
                color: "#ee6931",
                border: "1px solid #ee6931",
                fontFamily: "Raleway-Variable",
                background: "#fff",
                borderRadius: "12px",
                padding: "10px 40px 10px 40px",
                marginTop: "10px",
              }}
              onClick={() => {
                setState({ openConfirmDialog: false });
              }}
            />
          </div>
        }
      />
      <DraggableDialog
        maxWidth={"sm"}
        open={state?.feedbackDialog}
        handleClose={() => {
          setState({
            feedbackDialog: false,
          });
        }}
        dialog_title={"Laisser un commentaire"}
        dialogBody={
          <DialogContent style={{}} dividers>
            <>
              <div
                style={{
                  padding: 10,
                  borderBottom: "1px solid #000",
                  gap: 10,
                }}
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      width: "20%",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        width: 120,
                        height: 100,
                        borderRadius: 100,
                        overflow: "hidden",
                        display: "flex",

                        boxShadow: 3,
                      }}
                    >
                      <img
                        src={
                          job_details?.acc_picture ??
                          "https://i.pinimg.com/736x/55/0f/49/550f49a459548599a5a4ea1c67fc0244.jpg"
                        } // replace with your image path
                        alt="Profile"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 60,
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "22px",
                        fontWeight: 600,
                      }}
                    >
                      {job_details?.acc_name ?? "Customer Name"}
                    </div>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Rating
                        initialValue={state?.ratingsCount}
                        allowHover={true}
                        size={40}
                        onClick={(value: any) => {
                          setState({ ratingsCount: value });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ padding: 0 }}>
                <p
                  style={{
                    fontFamily: "Raleway-Semi-Bold",
                    color: "#000",
                    marginBottom: 0,
                  }}
                >
                  Écrivez votre commentaire
                </p>
                <InputField
                  placeHolder={"Exemple : Parfait ! Rapide et efficace"}
                  isMultiLine={true}
                  input_rows={3}
                  input_type={"text"}
                  input_value={state.feedback ?? ""}
                  onchange={onInputChange}
                  input_name={"feedback"}
                  input_style={{
                    background: "#F4F3F6",
                    width: "100%",
                    borderRadius: "16px",
                    border: "none",
                    fontSize: "16px",
                    margin: "0px",
                    marginBottom: 10,
                  }}
                />

                <CustomButton
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#fff",
                    fontFamily: "Raleway-Variable",
                    background: state?.feedback ? "#ee6931" : "lightgrey",
                    border: "none",
                    borderRadius: "12px",
                    padding: "10px 40px 10px 40px",
                    width: "100%",
                  }}
                  disable={state?.feedback ? false : true}
                  onClick={handleAddFeedback}
                  value={"Envoyer"}
                />
              </div>
            </>
          </DialogContent>
        }
      />
      <DraggableDialog
        maxWidth={"md"}
        open={state?.itemDetailsDialog}
        handleClose={() => {
          setState({ itemDetailsDialog: false });
        }}
        dialog_title={""}
        classes={classes}
        dialogBody={
          <DialogContent style={{}} dividers>
            <div>
              <Card
                elevation={0}
                sx={{
                  borderRadius: "32px",
                  overflow: "hidden",
                  border: "1px solid #E0E0E0",
                }}
              >
                <Box
                  position="relative"
                  sx={{ borderRadius: "32px 32px 0px 0px" }}
                >
                  <div style={{ height: "380px" }} className="display-flex">
                    <CardMedia
                      component="img"
                      image={
                        selectedImage ?? "https://via.placeholder.com/300x200"
                      }
                      alt="Product Image"
                      sx={{
                        objectFit: "contain",
                        width: "98%",
                        height: "360px",
                        borderRadius: "32px 32px 0px 0px",
                      }}
                    />
                  </div>
                </Box>

                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "30px",
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <Typography
                        variant="h6"
                        color="#3B3A3A"
                        sx={{
                          fontFamily: "Raleway-Bold",
                        }}
                      >
                        {handleLocalize({
                          en: state?.itemDetails?.item_name_en,
                          fr: state?.itemDetails?.item_name_fr,
                        }) ??
                          state?.itemDetails?.prod_name ??
                          ""}
                        <span> </span>
                      </Typography>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    {state?.itemDetails?.prod_images?.map((item: any) => {
                      return (
                        <div
                          className="display-flex"
                          style={{
                            height: "116px",
                            width: "116px",
                            cursor: "pointer",
                            borderRadius: "12px",
                            border: `2px solid ${
                              selectedImage === item
                                ? defaultLabelColors.main_orange
                                : "grey"
                            } `,
                          }}
                          onClick={() => {
                            setSelectedImage(item);
                          }}
                        >
                          <img
                            src={item}
                            alt="prod-img"
                            style={{
                              objectFit: "contain",
                              height: "110px",
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </CardContent>
              </Card>
              {/* <Card
                elevation={0}
                sx={{
                  borderRadius: "32px",
                  overflow: "hidden",
                  border: "1px solid #E0E0E0",
                  marginTop:"10px",
                  padding:"20px"

                }}
              >
                
              
                          <p
                            style={{
                              fontFamily: "Raleway-Bold",
                              fontSize: "18px",
                              margin: 0,
                            }}
                          >
                            Description :
                          </p>
                          <p
                            style={{
                              fontFamily: "Raleway-Variable",
                              margin: 0,
                              fontSize: "16px",
                            }}
                          >
                            {handleLocalize({
                              en: state?.itemDetails?.item_name_en,
                              fr: state?.itemDetails?.item_name_fr,
                            }) ??
                              state?.itemDetails?.prod_name ??
                              ""}

                            <span
                              style={{
                                fontFamily: "Raleway-Variable",
                                paddingLeft: 5,
                              }}
                            >
                              {state?.itemDetails?.prod_height && state?.itemDetails?.prod_length && state?.itemDetails?.prod_width
                                ? `${state?.itemDetails?.prod_length}X${state?.itemDetails?.prod_width}`
                                : null}
                            </span>
                          </p>
                          {state?.itemDetails?.prod_desc ? (
                            <p
                              style={{
                                fontFamily: "Raleway-Variable",
                                fontSize: 12,
                                margin: 0,
                                width: "150px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {state?.itemDetails?.prod_desc}
                            </p>
                          ) : null}
                          {state?.itemDetails?.prod_quantity ? (
                            <p
                              style={{
                                fontFamily: "Raleway-Variable",
                                fontSize: 12,
                                margin: 0,
                              }}
                            >
                              X{state?.itemDetails?.prod_quantity}
                            </p>
                          ) : null}
                          {state?.itemDetails?.prod_is_fragile ? (
                            <p
                              style={{
                                fontFamily: "Raleway-Variable",
                                fontSize: 12,
                                margin: 0,
                              }}
                            >
                              Fragile
                            </p>
                          ) : null}

                          <p
                            style={{
                              fontFamily: "Raleway-Bold",
                              fontSize: "18px",
                              marginTop: 10,
                            }}
                          >
                            Taille du colis :
                          </p>
                          <p
                            style={{
                              fontFamily: "Raleway-Semi-Bold",
                              margin: 0,
                            }}
                          >
                            {state?.itemDetails?.cat_name}
                          </p>
                   
              </Card> */}
            </div>
          </DialogContent>
        }
      />
    </div>
  );
}

export default AnounceDetails;

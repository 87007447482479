import { Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "../../Store";
import { strings } from "../../Store/Localization";
import Loader from "../../UiComponent/Loader";
import NewHeader from "../../UiComponent/NewHeader";
import noChatimage from "../../assets/images/FrankIcons/noChatimage.png";
import noImage from "../../assets/images/FrankIcons/noImageAvailable.png";
import apiServices from "../../services/RequestHandler";
import { getDataFromLocalStorage } from "../../utils/LocalStore.util";
import { color_theme, defaultLabelColors } from "../../utils/constants";
import {
  handleLocalize,
  numberFormatWithoutCurrency,
} from "../../utils/helper";
import {
  checkForPermission,
  firestoreDB,
} from "../../utils/notificationListner";

import AnnouncementChat from "./AnnouncementChat";
import MarketPlaceChat from "./MarketPlaceChat";
import { collection, onSnapshot, query, where } from "firebase/firestore";
const useStyles = makeStyles(() => ({
  scrollableArea: {
    height: "600px",
    // width: "100%",
    overflowY: "auto",

    "&::-webkit-scrollbar": {
      width: "2px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: color_theme.sand,
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      // backgroundColor: "#f5f5f5",
    },

    scrollbarWidth: "thin",
    scrollbarColor: `${color_theme.sand} #f5f5f5`,
  },
}));

function SenderInbox(props: any) {
  const classes = useStyles();
  const [chatHeads, setChatHeads] = useState<Array<any>>([]);
  const [typingUsers, setTypingUsers] = useState<Array<any>>([]);

  const selector = useSelector((state: RootState) => state.mainApp);
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      user: getDataFromLocalStorage("user"),
      loading_screen: true,
      showCustomerChat: false,
      showAccepterChat: false,
    }
  );

  useEffect(() => {
    handleGetAllChats();
  }, []);

  useEffect(() => {}, [selector]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const screen = queryParams.get("screen"); // "messaging"
  const resId = queryParams.get("res_id"); // "391302"

  const handleGetAllChats = async () => {
    try {
      let resp = await apiServices.getFromApi("chat/all-chat-heads", "");
      setChatHeads(resp?.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  React.useEffect(() => {
    const typingQuery = query(
      collection(firestoreDB, "typing_status"),
      // where("chatId", "in", [
      //   `${announcement_details?.receiver_id ?? ""}_${user?.id ?? ""}`,
      //   `${user?.id ?? ""}_${announcement_details?.receiver_id ?? ""}`,
      // ]),
      // where("resource_id", "==", `${announcement_details?.id}`),
      // where("user_id", "!=", `${user?.id}`),
      where("isTyping", "==", true)
    );

    const unsubscribe = onSnapshot(typingQuery, (snapshot) => {
      const users = snapshot.docs.map((doc) => doc.data());
      if (users.length > 0) {
        setTypingUsers(users);
      } else {
        setTypingUsers([]);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleGetShipmentRates = async (item: any, val?: boolean) => {
    try {
      setState({ loading: true });
      if (item?.type === "market_place") {
        let obj: any = {
          id: Number(item?.product_id),
          customer_id: Number(selector?.user?.id),
        };
        const resp = await apiServices.postFromApi(
          "/c2c-product-masters/particular/details",
          obj,
          ""
        );
        let receiver_id;

        if (!item?.is_owner) {
          receiver_id = item?.product_owner;
          setState({ loading: false });
        } else {
          receiver_id = item?.customer_id;
          setState({ loading: false });
        }

        setState({
          chat_type: "market_place",
          chat_details: resp?.data,
          selected_item: item,
          market_place_chat_item: {
            ...resp?.data,
            product_id: resp?.data?.id,
            receiver_id,
            customer_id: item?.customer_id,
            re_render: new Date().getTime(),
            loading: false,
          },
        });

        return;
      }

      if (item?.type === "announcement") {
        let obj: any = {
          id: Number(item?.ship_id),
          customer_id: Number(selector?.user?.id),
        };

        const resp = await apiServices.postFromApi(
          `c2c-shipments/details`,
          obj,
          ""
        );
        let receiver_id;

        if (!item?.is_owner) {
          receiver_id = item?.product_owner;
        } else {
          receiver_id = item?.accepter_id;
        }
        setState({
          chat_details: resp?.data,
          announcement_details: {
            ...resp?.data,
            product_id: resp?.data?.id,
            receiver_id,
            customer_id: item?.accepter_id,
            re_render: new Date().getTime(),
            loading: false,
          },
          chat_type: item?.type,
          selected_item: item,
          market_place_chat_item: null,
          loading: false,
        });

        return;
      }
    } catch (error) {
      console.log(error);
      setState({ loading: false });
    }
  };

  React.useEffect(() => {
    if (selector.messaging) {
      const message = JSON.parse(selector.messaging ?? "{}");
      if (message.navigation_id) {
        if (state.selected_item) {
          handleGetShipmentRates(state.selected_item);
        }
      }
    }
  }, [selector.messaging]);

  const renderAnnounceCard = () =>
    state?.chat_details?.shipment_items?.length > 1 ? (
      <div>
        <p
          style={{
            fontFamily: "Raleway-Semi-Bold",
            color: "#000",
            fontSize: "20px",
          }}
        >
          {state?.chat_details?.owner_detail?.id === state?.user?.id
            ? null
            : state?.chat_details?.owner_detail?.added_by}
        </p>
        <div className="card-container" style={{ marginTop: 5 }}>
          <img
            src={state?.chat_details?.shipment_items?.[0]?.prod_images[0]}
            className="card-image"
            alt="prod-img"
          />
          <div className="card-details">
            <div className="card-title" style={{ fontSize: 18 }}>
              {`Lot de (${state?.chat_details?.shipment_items?.length}) colis`}
            </div>
            <p
              style={{
                color: "#080626",
                font: "14px",
                fontWeight: "500",
                fontFamily: "Raleway-Semi-Bold",
              }}
            >
              {" "}
              Postée le{" "}
              {moment(state?.chat_details?.created_at).format(
                "DD/MM/YYYY"
              )}{" "}
            </p>
          </div>
          <div
            className="card-quantity"
            style={{
              paddingRight: "20px",
              color: defaultLabelColors.main_orange,
              fontSize: "26px",
            }}
          >
            {numberFormatWithoutCurrency(
              state?.chat_details?.ship_price ??
                state?.chat_details?.price ??
                0.0
            )}
            &nbsp; €
          </div>
        </div>
      </div>
    ) : (
      <div className="card-container">
        <img
          src={
            state?.chat_details?.shipment_items?.[0]?.prod_images?.[0] ??
            state?.selected_item?.images?.[0] ??
            noImage
          }
          className="card-image"
          alt="prod-img"
        />
        <div className="card-details" style={{ flex: 1 }}>
          <div className="card-title" style={{ fontSize: 20 }}>
            {handleLocalize({
              en: state?.selected_item?.product_title?.en,
              fr: state?.selected_item?.product_title?.fr,
            }) ??
              state?.chat_details?.shipment_items?.[0]?.prod_name ??
              ""}
          </div>

          <div
            className="card-quantity"
            style={{
              color: defaultLabelColors.main_orange,
              display: "flex",
              alignItems: "center",
              flex: 1,
            }}
          >
            <p
              style={{
                color: "#080626",
                font: "14px",
                fontWeight: "500",
              }}
            >
              Postée le
              {moment(state?.selected_item?.created_at_tz).format("DD/MM/YYYY")}
            </p>
          </div>
        </div>
        <div
          style={{
            fontFamily: "Raleway-Variable",
            fontWeight: "700",
            fontSize: "22px",
            color: defaultLabelColors.main_orange,
          }}
        >
          {numberFormatWithoutCurrency(
            state?.chat_details?.base_price ?? state?.chat_details?.ship_price
          )}{" "}
          €
        </div>
      </div>
    );

  return (
    <>
      <Loader loading={state?.loading} />
      <Helmet>
        <title>Le Chat | Discutez avec la Communauté Frank</title>
        <link rel="canonical" href="https://www.v2internal.frankme.com/chat" />
        <meta
          name="description"
          content="Discutez en temps réel avec d'autres membres de la communauté Frank. Partagez vos expériences et restez connecté avec des transporteurs et clients."
        />
      </Helmet>
      <div className="web-screen">
        <div style={{ background: "#f9c9b3" }}>
          <Grid
            container
            spacing={2}
            className="display-flex"
            style={{
              background: "#F4C9B3",
              paddingLeft: "40px",
              paddingRight: "40px",
              paddingTop: "30px",
            }}
          >
            <Grid xs={12} item style={{ zIndex: 99 }}>
              <NewHeader />
            </Grid>
          </Grid>
          <div
            style={{
              padding: "60px 160px 30px 150px",
              gap: 10,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={4.5}>
                <div
                  style={{
                    background: "#fff",
                    borderRadius: "16px",
                    padding: "16px",
                    display: "flex",
                    flexDirection: "column",
                    height: "90vh",
                  }}
                  className={classes?.scrollableArea}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <h2
                        style={{
                          fontFamily: "Raleway-Extra-Bold",
                          fontSize: "22px",
                          fontWeight: 500,
                        }}
                      >
                        {strings?.inbox}
                      </h2>
                    </div>
                    <div
                      style={{
                        cursor: "pointer",
                        fontFamily: "Raleway-Bold",
                        color: defaultLabelColors.main_blue,
                      }}
                      onClick={checkForPermission}
                    >
                      {" "}
                      Désactiver les notifications
                    </div>
                  </div>
                  <Box
                    sx={{
                      flex: 1,
                      backgroundColor: "white",
                      overflowY: "scroll",
                      maxHeight: "90vh",
                    }}
                  >
                    {chatHeads?.map((item: any, index: any) => {
                      let item_name = "";
                      if (item?.shipment_items?.length > 1) {
                        item_name = item?.shipment_items?.[0]?.item_name_fr;
                      } else {
                        item_name = item?.shipment_items?.[0]?.item_name_en;
                      }
                      const is_typing = typingUsers.find(
                        (a: any) =>
                          (Number(a.resource_id) === Number(item?.ship_id) ||
                            Number(a.resource_id) ===
                              Number(item?.product_id)) &&
                          Number(a.user_id) !== Number(state?.user?.id) &&
                          (item?.is_owner
                            ? a.chatId ===
                                `${item.accepter_id}_${state.user?.id}` ||
                              a.chatId ===
                                `${state.user?.id}_${item.accepter_id}`
                            : true)
                      );

                      return (
                        <>
                          <div
                            style={{
                              display: "flex",
                              padding: "10px",
                              cursor: "pointer",
                              gap: 10,
                            }}
                            key={`CHAT_HEAD_${index + 1}`}
                            onClick={() => handleGetShipmentRates(item)}
                          >
                            <div
                              style={{
                                height: 60,
                                width: 60,
                              }}
                            >
                              <img
                                src={item?.images?.[0] ?? noImage}
                                style={
                                  item?.shipment_items?.[0]?.images?.[0] ??
                                  item?.images?.[0]
                                    ? {
                                        height: 50,
                                        width: 50,
                                        objectFit: "contain",
                                        borderRadius: 100,
                                        border: `1px solid #0000001f`,
                                      }
                                    : {
                                        height: 50,
                                        width: 50,
                                        objectFit: "contain",
                                        borderRadius: 100,
                                        border: `1px solid #0000001f`,
                                      }
                                }
                              />
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                rowGap: 10,
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "baseline",
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: 18,
                                    fontFamily: "Raleway-Bold",
                                    color: color_theme.black,
                                    flex: 1,
                                  }}
                                >
                                  {item.type === "announcement"
                                    ? handleLocalize({
                                        en: item?.item_name_en,
                                        fr: item?.item_name_fr,
                                      })
                                    : handleLocalize(item.product_title)}
                                  <span
                                    style={{
                                      color:
                                        item.type === "announcement"
                                          ? color_theme.main_blue
                                          : color_theme.main_orange,
                                      fontSize: 12,
                                      marginLeft: 5,
                                    }}
                                  >
                                    •{" "}
                                    {item.type === "announcement"
                                      ? item?.is_owner
                                        ? "À envoyer"
                                        : "À transporter"
                                      : "Seconde main"}
                                  </span>
                                </div>
                                <div
                                  style={{
                                    padding: "4px 12px",
                                    fontSize: 12,
                                    background:
                                      item?.is_accepted === true
                                        ? "#34A8531A"
                                        : item?.is_accepted === false
                                        ? "#1C4FDC1A"
                                        : "#1C4FDC1A",
                                    color:
                                      item?.is_accepted === true
                                        ? "#34A853"
                                        : item?.is_accepted === false
                                        ? "#1C4FDC"
                                        : "#1C4FDC",
                                    minWidth: 56,
                                    borderRadius: 8,
                                    textAlign: "center",
                                  }}
                                >
                                  {item?.is_accepted
                                    ? "Validée"
                                    : item?.is_accepted === false
                                    ? "En cours"
                                    : "En cours"}
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "baseline",
                                }}
                              >
                                <div
                                  style={{
                                    flex: 1,
                                    color: "#4B4A61",
                                    fontSize: 14,
                                  }}
                                >
                                  {is_typing ? "typing..." : item?.added_by}
                                </div>
                                <div
                                  style={{
                                    fontSize: 12,
                                    color: color_theme.black,
                                  }}
                                >
                                  {moment(
                                    `${item.created_at_tz}`.endsWith("Z")
                                      ? item.created_at_tz
                                      : item.created_at_tz + "Z"
                                  ).format("HH:mm")}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </Box>
                </div>
              </Grid>

              <Grid item xs={12} md={7.5}>
                <div
                  style={{
                    background: "#fff",
                    borderRadius: "16px",
                    padding: "16px",
                    height: "90vh",
                  }}
                >
                  <div
                    style={{
                      height: "90vh",
                    }}
                  >
                    {
                      // state?.chat_type === "sender" ? (
                      //   <>
                      //     {renderAnnounceCard()}
                      //     <SenderChat
                      //       handleGetShipmentRates={handleGetShipmentRates}
                      //       {...props}
                      //       {...state}
                      //       handleSwitChat={() => {
                      //         setState({
                      //           chat_type: "transporter",
                      //         });
                      //       }}
                      //     />
                      //   </>
                      // ) : state?.chat_type === "transporter" ? (
                      //   <>
                      //     {renderAnnounceCard()}
                      //     <AccepterChat
                      //       handleGetShipmentRates={handleGetShipmentRates}
                      //       {...props}
                      //       {...state}
                      //     />
                      //   </>
                      // ) :
                      state.chat_type === "announcement" ? (
                        <>
                          {renderAnnounceCard()}
                          <AnnouncementChat
                            announcement_details={state.announcement_details}
                            chat_details={state.chat_details}
                          />
                        </>
                      ) : state?.chat_type === "market_place" ? (
                        <>
                          {renderAnnounceCard()}
                          <MarketPlaceChat
                            handleGetShipmentRates={handleGetShipmentRates}
                            {...props}
                            {...state}
                          />
                        </>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              flexDirection: "column",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={noChatimage}
                              alt="no-chat-img"
                              style={{ opacity: "0.7" }}
                            />
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
}

export default SenderInbox;

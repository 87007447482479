import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect } from "react";
import { TextField } from "@mui/material";
import dayjs from "dayjs";
import { strings } from "../../Store/Localization";
import { useSelector } from "react-redux";
import { RootState } from "../../Store";

type Dayjs = dayjs.Dayjs;

interface CustomDatePickerProps {
  value: Dayjs | null;
  onChange: (newValue: Dayjs | null, formattedDate: string) => void;
  placeholder?: string;
  disablePast?: boolean;
  fontFamily?: string;
  backgroundColor?: string;
  disableFuture?: boolean;
  format?: string;
}

function CustomDatePicker({
  placeholder,
  value,
  onChange,
  disablePast = false,
  disableFuture = false,
  backgroundColor = "white",
  fontFamily = "Raleway-Italic",
  format = "DD/MM/YYYY",
}: CustomDatePickerProps) {
  const handleDateChange = (newValue: Dayjs | null) => {
    const formattedDate = newValue ? newValue.format("YYYY-MM-DD") : "";
    onChange(newValue, formattedDate);
  };

  const selector = useSelector((state: RootState) => state.mainApp);

  useEffect(() => {}, [selector.lang]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={value}
        onChange={handleDateChange}
        disablePast={disablePast}
        disableFuture={disableFuture}
        format={format}
        maxDate={dayjs().subtract(10, "year")}
        slotProps={{
          textField: {
            placeholder: placeholder,
            sx: {
              backgroundColor: backgroundColor,
              width: "100%",
              borderRadius: "16px",
              border: "none",
              fontFamily: fontFamily,
              height: 56,
              "& .MuiInputBase-input": {
                padding: "18px 16px",
                "::placeholder": {
                  color: "#7c7c7c",
                  opacity: 2,
                },
              },
              "& fieldset": {
                display: "none",
              },
              "& .MuiFormLabel-root": {
                display: "none",
              },
            },
          },
        }}
      />
    </LocalizationProvider>
  );
}

export default CustomDatePicker;

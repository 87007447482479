import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Store";
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  CircularProgress,
  TextField,
} from "@mui/material";
import { handleLocalize } from "../../utils/helper";
import { debounce } from "lodash";
import { addAnnounceItem } from "../../Store/MainAppReducer";
import classNames from "classnames";
import { strings } from "../../Store/Localization";

interface ParcelSizeSelectProps {
  label?: string;
  onChange: (value: string) => void; // Only returning the plain input value as a string
  selected_value: any;
}

interface OptionType {
  label: string;
  value: any;
}

export const ParcelSizeSelect: React.FC<ParcelSizeSelectProps> = ({
  label = " ",
  selected_value,
  onChange,
}) => {
  const selector = useSelector((state: RootState) => state.mainApp);
  const dispatch = useDispatch<any>();
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [selectedValue, setSelectedValue] = useState(
    selected_value
      ? selected_value
      : {
          item_name_en: "",
          item_name_fr: "",
          value: null,
        }
  );

  const [options, setOptions] = useState<any>(
    selector.announce_items?.slice(0, 10)
  );
  const [filteredOptions, setFilteredOptions] = useState<any>(
    selector?.announce_items?.slice(0, 10)
  );

  React.useEffect(() => {
    setOptions(filteredOptions);
  }, [filteredOptions]);

  React.useEffect(() => {
    if (selected_value) {
      setSelectedValue(selected_value);
    } else {
      setSelectedValue({
        item_name_en: "",
        item_name_fr: "",
        value: null,
      });
    }
  }, [JSON.stringify(selected_value)]);

  const handleInputChange = (event: React.ChangeEvent<{}>, value: string) => {
    if (value === "") {
      setOptions(selector?.announce_items?.slice(0, 10));
    } else {
      const filtered_records = selector?.announce_items?.filter((i: any) => {
        let text2 = handleLocalize({
          en: i?.item_name_en,
          fr: i?.item_name_fr,
        });
        return text2?.toLowerCase().includes(value?.toLowerCase());
      });
      console.log(filtered_records)
      if (filtered_records?.length > 0) {
        setFilteredOptions(filtered_records);
      } else {
        setFilteredOptions([
          {
            item_name_en: `Add ${value} ?`,
            item_name_fr: `Ajouter ${value} ?`,
            value: value,
            custom: true,
          },
        ]);
      }
    }
  };
  // adding debounce function to call my function after scroll ends 1000ms = 1 sec
  const debouncedSearch = debounce(async (text) => {
    try {
      handleInputChange(null as any, text);
    } catch (error) {
      console.log(error);
    }
  }, 500);

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: any,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any> | undefined
  ) => {
    setIsTyping(false);
    setLoading(false);
    let data = {
      ...newValue,
      item_name_fr: newValue?.item_name_fr
        ?.replace("Ajouter ", "")
        .replace(" ?", ""),
      item_name_en: newValue?.item_name_en
        ?.replace("Add ", "")
        .replace(" ?", ""),
      label: handleLocalize({
        fr: newValue?.item_name_fr?.replace("Ajouter ", "")?.replace(" ?", ""),
        en: newValue?.item_name_en?.replace("Add ", "")?.replace(" ?", ""),
      }),
    };

    let isPresent = selector.announce_items?.find(
      (a: any) =>
        a.item_name_en === newValue?.item_name_en ||
        a.item_name_fr === newValue?.item_name_fr
    );
    if (!isPresent) {
      dispatch(addAnnounceItem(data));
    }

    setSelectedValue(data);
    if (onChange) {
      onChange(data);
    }
  };

  return (
    <div>
      <label
        className={classNames("Raleway-Variable")}
        style={{ fontSize: "18px", fontWeight: "600", display: "flex" }}
      >
        Description
      </label>
      <Autocomplete
        style={{ width: "100%" }}
        options={options}
        getOptionLabel={(option) =>
          typeof option === "string"
            ? option ?? ""
            : handleLocalize({
                en: option?.item_name_en,
                fr: option?.item_name_fr,
              }) ?? ""
        }
        value={selectedValue ?? null}
        getOptionKey={(option: any) =>
          `${handleLocalize({
            en: option?.item_name_en,
            fr: option?.item_name_fr,
          })} ${(Math.random() + 1)?.toString(36)?.substring(7)}`
        }
        filterOptions={(x) => x} // Prevent default filtering
        loading={loading}
        onInputChange={(e, value) => {
          setInputValue(value);
          setIsTyping(true);
          debouncedSearch(value);
        }}
        onChange={handleChange}
        inputValue={isTyping ? inputValue : ""}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            placeholder={strings?.prod_name}
            variant="standard"
            InputProps={{
              ...params.InputProps,
              disableUnderline: true, // Remove underline
              style: {
                fontFamily: "Raleway-Italic", // Change font family
                backgroundColor: "#fff", // Change background color
                height: "52px",
                borderRadius: "16px",
                padding: 10,
                width: "100%",
                marginTop: 10,
              },
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none", // Remove the outline border
              },
            }}
          />
        )}
        isOptionEqualToValue={(option: any, value: any) =>
          option.value === value ||
          option.label === value ||
          handleLocalize({
            en: option?.item_name_en,
            fr: option?.item_name_fr,
          }) === value
        }
        freeSolo
      />
    </div>
  );
};

export default ParcelSizeSelect;

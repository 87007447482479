import { Component } from "react";
import { strings } from "../Store/Localization";
import { getDataFromLocalStorage } from "./LocalStore.util";

interface ItemSize {
  size: string;
  size_trans: string;
  desc: string;
  key: string;
  weight: string;
  level: number;
  id: number;
}

class FrankConstants extends Component<{}, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      item_sizes: [
        {
          size: "Small",
          size_trans: strings.getLanguage() === "fr" ? "Petit" : "Small", // strings.small,
          desc: "Entre une enveloppe et une boite à chaussure (<5 Kg)",
          key: "regular",
          weight: "less_than_5_kg",
          level: 1,
          id: 3,
        },
        {
          size: "Medium",
          size_trans: strings.medium,

          desc: "Entre un sac à dos et une caisse à vin (5 KG- 15 Kg )",
          key: "medium",
          weight: "between_5_and_15_kg",
          level: 2,
          id: 4,
        },
        {
          size: "Large",
          size_trans: strings.large,

          desc: "Entre une télévision et une commode (15 kg - 25 kg)",
          key: "large",
          weight: "between_15_and_25_kg",
          level: 3,
          id: 1,
        },
        {
          size: "Extra Large",
          size_trans: strings.xl,

          desc: "Entre une armoire et une table à manger (+25 Kg)",
          key: "extra_large",
          weight: "more_than_25_kg",
          level: 4,
          id: 2,
        },
      ],
      lang: getDataFromLocalStorage("lang") ?? "fr",
    };
  }

  componentDidMount(): void {}

  itemSizes(lang: string): any[] {
    this.setState({
      lang,
    });
    return [
      {
        size: "Small",
        size_trans: strings.getLanguage() === "fr" ? "Petit" : "Small", // strings.small,
        desc: strings.small_desc,
        key: "regular",
        weight: "less_than_5_kg",
        level: 1,
        id: 3,
        weight_fr:
          strings.getLanguage() === "fr" ? "Moins de 5 kg " : "less_than_5_kg",
      },
      {
        size: "Medium",
        size_trans: strings.medium,

        desc: strings.medium_desc,
        key: "medium",
        weight: "between_5_and_15_kg",
        level: 2,
        id: 4,
        weight_fr:
          strings.getLanguage() === "fr"
            ? "Entre 5 et 15 kg "
            : "between_5_and_15_kg",
      },
      {
        size: "Large",
        size_trans: strings.large,

        desc: strings.large_desc,
        key: "large",
        weight: "between_15_and_25_kg",
        level: 3,
        id: 1,
        weight_fr:
          strings.getLanguage() === "fr"
            ? "Entre 15 et 25 kg "
            : "between_15_and_25_kg",
      },
      {
        size: "Extra Large",
        size_trans: strings.xl,

        desc: strings.e_large_desc,
        key: "extra_large",
        weight: "more_than_25_kg",
        level: 4,
        id: 2,
        weight_fr:
          strings.getLanguage() === "fr" ? "Plus de 25 kg " : "more_than_25_kg",
      },
    ];
  }

  deliveryTypes(lang: string): any[] {
    this.setState({
      lang,
    });
    return [
      {
        label: strings.delivery_type,
        description: strings.delivery_type_text,
        key: "Urgent",
      },
      {
        label: strings.delivery_type2,
        description: strings.delivery_type2_text,
        key: "I can wait",
      },

      {
        label: strings.delivery_type3,
        description: strings.delivery_type3_text,
        key: "I have time",
      },
    ];
  }

  packageSize(lang: string): any[] {
    this.setState({
      lang,
    });
    return [
      {
        size: "Regular",
        size_trans: strings.getLanguage() === "fr" ? "Petit" : "Small", // strings.small,
        desc: strings.small_desc,
        key: "Small",
        weight: "less_than_5_kg",
        level: 1,
        id: 3,
        weight_fr:
          strings.getLanguage() === "fr" ? "Moins de 5 kg " : "less_than_5_kg",
      },
      {
        size: "Medium",
        size_trans: strings.medium,

        desc: strings.medium_desc,
        key: "Medium",
        weight: "between_5_and_15_kg",
        level: 2,
        id: 4,
        weight_fr:
          strings.getLanguage() === "fr"
            ? "Entre 5 et 15 kg "
            : "between_5_and_15_kg",
      },
      {
        size: "Large",
        size_trans: strings.large,

        desc: strings.large_desc,
        key: "Large",
        weight: "between_15_and_25_kg",
        level: 3,
        id: 1,
        weight_fr:
          strings.getLanguage() === "fr"
            ? "Entre 15 et 25 kg "
            : "between_15_and_25_kg",
      },
      {
        size: "Extra Large",
        size_trans: strings.xl,

        desc: strings.e_large_desc,
        key: "Extra Large",
        weight: "more_than_25_kg",
        level: 4,
        id: 2,
        weight_fr:
          strings.getLanguage() === "fr" ? "Plus de 25 kg " : "more_than_25_kg",
      },
    ];
  }

  globalErrorMessage(lang: String): string {
    return strings.internal_error;
  }
}
export default new FrankConstants({} as any);

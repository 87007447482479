import CryptoJS from "crypto-js";
// Encryption function
// const secretKey = 'FRANK@2024!';\
const secretKey = "FR@NK-FR_2024!";
// export const encrypt = (text: string): string => {
//   return CryptoJS.AES.encrypt(text, secretKey).toString();
// };
// export const decrypt = (ciphertext: string): string => {
//   const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
//   return bytes.toString(CryptoJS.enc.Utf8);
// };


export const encrypt = (text: string): string => {
  return CryptoJS.AES.encrypt(text, secretKey).toString();
};

// Decryption function
export const decrypt = (ciphertext: string): string => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};
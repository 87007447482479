import { East, ExpandMore, MoreHoriz, Search } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  DialogContent,
  Grid,
  InputAdornment,
  Rating,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomButton from "../../UiComponent/CustomButton";
import { apiKey, defaultLabelColors, spacing } from "../../utils/constants";

import { styled } from "@mui/system";
import {
  HomeIcon,
  MapHomeIcon,
  MapPointerIcon,
  NoAnnoucementImage,
  PointerIcon,
  RefrestIcon,
  SignUpImage,
} from "../../assets/images/FrankIcons";
import apiServices from "../../services/RequestHandler";
import { strings } from "../../Store/Localization";
import CustomFooter from "../../UiComponent/CustomFooter";
import TransporterUnColisMobile from "./TransporterUnColisMobile";

import moment from "moment";
import DraggableDialog from "../../UiComponent/DraggableDialog";
// import { Rating } from "react-simple-star-rating";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import noJobsImage from "../../assets/images/FrankIcons/noJobsFound.png";
import GoogleAutocompleteField from "../../UiComponent/GoogleAutocompleteField";
import Loader from "../../UiComponent/Loader";
import MapboxGL from "../../UiComponent/MapBoxGL";
import NewHeader from "../../UiComponent/NewHeader";
import Toast from "../../UiComponent/Toast/Toast";
import {
  handleLocalize,
  numberFormatWithoutCurrency,
  numberFormatWithoutCurrencyFixedOne,
} from "../../utils/helper";
import { getDataFromLocalStorage } from "../../utils/LocalStore.util";
import AuthAction from "../Auth/AuthAction";
import { addDoc, collection } from "firebase/firestore";
import { firestoreDB } from "../../utils/notificationListner";
import FrankConstants from "../../utils/FrankConstants";
import { useSelector } from "react-redux";
import { RootState } from "../../Store";

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none", // Remove the border
    },
    "&:hover fieldset": {
      border: "none", // Remove hover border
    },
    "&.Mui-focused fieldset": {
      border: "none", // Remove focus border
    },
  },
  "& .MuiInputBase-input": {
    fontSize: "52px",
    padding: "12px 14px", // Adjust padding if needed
    textAlign: "center",
    fontFamily: "Raleway-Variable",
    fontWeight: "700",
  },
  "& .MuiInputAdornment-root": {
    fontSize: `52px !important`,
    fontFamily: "Raleway-Variable", // To match the font family
    fontWeight: "700",
  },
});

function TranspoprterUnColis(props: any) {
  const { classes } = props;
  const [inputValueForOrigin, setInputValueForOrigin] = useState("");
  const [inputValueForDestination, setInputValueForDestination] = useState("");
  const selector = useSelector((state: RootState) => state.mainApp);
  const navigate = useNavigate();
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      announes: "All",
      negotiate: false,
      messagingScreen: false,
      shouldNavigate: false,
      disableNegotiate: false,
      accepter_details: null,
      login_user: getDataFromLocalStorage("user"),
      loadingLoader: true,
      disableNegotiateBtn: false,
      initilalTotalFeedBack: 3,
      mapbox_direction: null,
    }
  );

  useEffect(() => {
    handleGetAllJobs(state?.announes);
    handleGetAllBlog();
    navigator.geolocation.getCurrentPosition(function (position) {
      setState({
        coords: {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        },
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetAllBlog = async () => {
    try {
      setState({ activity: true });
      let resp = await apiServices.getFromApi(
        `c2c-blogs/web?page=1&limit=6`,
        ""
      );
      if (resp?.status === 200 || resp?.status === 201) {
        setState({ allBlogs: resp?.data, totalPage: resp?.total_pages });
        setState({ activity: false });
      }
    } catch (error) {
      setState({ activity: false });
    }
  };

  const handleClearSearchFeilds = () => {
    try {
      setInputValueForOrigin("");
      setInputValueForDestination("");
    } catch (error) {}
  };

  const handleTerminer = () => {
    try {
      setState({
        openJobDialog: false,
        messagingScreen: false,
        negotiate: false,
        disableNegotiate: false,
        accepter_details: [],
      });
      handleGetAllJobs(state?.announes);
    } catch (error) {}
  };

  const handleGetAllJobs = async (jobType: string) => {
    try {
      setState({ loadingLoader: true, announes: jobType });
      let user = getDataFromLocalStorage("user");

      let body: any = {
        type: jobType,
        user: user?.id ?? null,
        limit: false,
      };
      if (jobType === "Around Me") {
        body = {
          ...body,
          lat: state?.coords?.lat,
          lng: state?.coords?.lng,
        };
      }
      const resp = await apiServices.postFromApi(
        "c2c-shipments/web-all-announcements",
        body,
        ""
      );

      setState({
        allJobsData: resp?.data,
        announes: jobType,
        loadingLoader: false,

        re_render_time: new Date().getTime(),
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetJobInfo = async (item?: any) => {
    try {
      let user = getDataFromLocalStorage("user");
      if (!user) {
        setState({ openLoginDialog: true });
        return;
      }

      // const resp = await apiServices.getFromApi(
      //   `c2c-shipments/${item?.id}`,
      //   ""
      // );

      let obj: any = {
        id: Number(item?.id),
        customer_id: Number(user?.id),
      };
      const resp = await apiServices.postFromApi(
        `c2c-shipments/details`,
        obj,
        ""
      );

      handleGetUserDetails(resp?.data);

      const accepter_details = resp?.data?.negotiations?.filter(
        (i: any) => Number(i?.accepter_id) === Number(user?.id)
      );

      accepter_details?.sort((a: any, b: any) =>
        new Date(a?.created_at).getTime() > new Date(b?.created_at).getTime()
          ? -1
          : 1
      );

      if (accepter_details?.[0]?.is_accepted === null) {
        setState({
          disableNegotiate: true,
          accepter_details,
        });
      }

      setState({
        jobDetails: resp?.data,
        openJobDialog: true,
        negotiated_price: resp?.data?.ship_price + 1,
      });
    } catch (error) {}
  };

  const handleGetUserDetails = async (item?: any) => {
    try {
      const resp = await apiServices.getFromApi(
        `c2c/get-user-details/${item?.user_details?.id}`,
        ""
      );
      console.log("resp hun", resp);

      let avg_rating_ = resp.data?.ratings2?.reduce((a: any, b: any) => {
        return a + b.rating;
      }, 0);
      let avg_rating = isNaN(avg_rating_ / resp?.data?.ratings2?.length)
        ? 0
        : avg_rating_ / resp?.data?.ratings2?.length;
      setState({
        allRatings: resp?.data,

        details: {
          ...resp.data,
          avg_rating,
        },
      });
    } catch (error) {}
  };

  const onInputChange = (e: any) => {
    try {
      let { value, name } = e.target;

      const re = /^\d*\.?\d{0,2}$/;

      if (value === "") {
        setState({
          [name]: value,
        });
        return;
      }

      if (
        !re.test(value) ||
        value[0] === " " ||
        parseFloat(value) < 0 ||
        value[0] === "."
      ) {
        return;
      }

      if (value.length > 1 && value[0] === "0" && !value.includes(".")) {
        return;
      }

      if (parseFloat(value) > 10000) {
        return;
      }

      setState({
        [name]: value,
      });
    } catch (error) {
      console.error("Error in onInputChange:", error);
    }
  };

  const handleCloseLoginPage = () => {
    try {
      setState({ openLoginDialog: false });
    } catch (error) {}
  };

  const handleAcceptJob = async () => {
    try {
      let obj = {
        ship_id: Number(state?.jobDetails?.id),
        ship_price: Number(state?.jobDetails?.ship_price),
        is_accepted: true,
      };
      let resp = await apiServices.postFromApi(
        "c2c-shipment-negotiations",
        obj,
        ""
      );

      if (resp?.status === 200 || resp?.status === 201) {
        Toast.showSuccess("Job Accepted Succesfully");
        handleSentFirstMessage(state?.jobDetails?.ship_price);
        handleGetAllJobs(state?.announes);
        setState({ openJobDialog: false });
      }
    } catch (error) {}
  };

  const handleNegotiate = async (negotiatedValue?: Number, shipID?: Number) => {
    if (
      state.negotiated_price < state.jobDetails?.ship_price ||
      !state.negotiated_price
    ) {
      Toast.Error("Le montant n’est pas valide");
      setState({ disableNegotiateBtn: false });
      return;
    }
    handleSentFirstMessage();
  };

  const handleSentFirstMessage = async (amount?: number) => {
    let user = getDataFromLocalStorage("user");

    const name = user?.first_name + user?.surname ? ` ${user?.surname}` : "";
    let first_msg: any = {
      message: ``,
      message_status: "sent",
      order_id: `${state?.jobDetails?.id ?? ""}`,
      is_negotiate: true,
      order_details: state.jobDetails,
      sender_id: `${user?.id}`,
      receiver_id: `${state.jobDetails?.customer_id}`,
      sender_name: name,
    };
    let new_msg: any = {
      message: `${name} has sent you a message. neg_amt=${
        amount ?? state?.negotiated_price
      }`,
      message_status: "sent",
      order_id: `${state?.jobDetails?.id ?? ""}`,
      is_negotiate: true,
      order_details: null,
      sender_id: `${user?.id}`,
      receiver_id: `${state.jobDetails?.customer_id}`,
      sender_name: name,
    };
    if (amount) {
      await addDoc(collection(firestoreDB, `chat_negotiations`), first_msg);
      await addDoc(collection(firestoreDB, `chat_negotiations`), new_msg);
      setState({ disableNegotiateBtn: false });
      return;
    }

    let obj: any = {
      ship_id: Number(
        state.jobDetails?.id
          ? state.jobDetails?.id
          : state?.jobDetails?.id ?? state?.jobDetails?.ship_id
      ),
      ship_price: Number(state.negotiated_price ?? 0),
    };

    if (state?.jobDetails?.negotiations?.length > 0) {
      obj.parent_id = Number(
        state?.jobDetails?.negotiations?.[
          state?.jobDetails?.negotiations?.length - 1
        ]?.id
      );
    }

    const resp = await apiServices.postFromApi(
      "/c2c-shipment-negotiations",
      obj,
      ""
    );
    if (resp.status === 201) {
      Toast.showSuccess(strings.offer_request_sent);
      handleGetAllJobs(state?.announes);
      await addDoc(collection(firestoreDB, `chat_negotiations`), first_msg);
      await addDoc(collection(firestoreDB, `chat_negotiations`), new_msg);

      if (resp.status === 201) {
        // getMessages();
        // setState({
        //   message: "",
        //   temp_document: null,
        //   upload_type: null,
        //   temp_gallery: null,
        // });
        setState({ messagingScreen: true, disableNegotiateBtn: false });
      }
    } else {
      Toast.Error(
        resp.error?.message ??
          resp?.message ??
          resp?.error?.error ??
          "Internal Server Error"
        // item_sizes.globalErrorMessage(selector.lang),
      );
      setState({ disableNegotiateBtn: false });
    }
  };

  const handlePlaceSelected = async (
    place: google.maps.places.PlaceResult | null
  ) => {
    if (place && place.geometry) {
      const latitude = place.geometry.location?.lat() || 0;
      const lngitude = place.geometry.location?.lng() || 0;
      const address = place.formatted_address || "";
      // setSelectedLocation({ lat, lng, address });
      setInputValueForOrigin(address);
      const lat = latitude;
      const lng = lngitude;
      let originLoc = { lat, lng };
      setState({
        originLocation: `${latitude},${lngitude}`,
        map_origin: { latitude, longitude: lngitude },
        originForMap: originLoc,
      });
    }
  };

  const handlePlaceSelectedDestination = async (
    place: google.maps.places.PlaceResult | null
  ) => {
    if (place && place.geometry) {
      const latitude = place.geometry.location?.lat() || 0;
      const lngitude = place.geometry.location?.lng() || 0;
      const address = place.formatted_address || "";
      // setSelectedLocation({ lat, lng, address });
      setInputValueForDestination(address);
      const lat = latitude;
      const lng = lngitude;
      let originLoc = { lat, lng };

      setState({
        destinationLocation: `${lat},${lng}`,
        map_destination: { latitude, longitude: lngitude },
        destinatioForMap: originLoc,
      });
    }
  };

  useEffect(() => {
    if (inputValueForOrigin === "" && inputValueForDestination === "") {
      setInputValueForOrigin("");
      setInputValueForDestination("");
      handleGetAllJobs(state.announes);
    }
  }, [inputValueForOrigin, inputValueForDestination]);

  const handleGetDirections = async () => {
    try {
      if (!state?.originLocation) {
        Toast.Error("Please select valid start location");
        return;
      }
      if (!state?.destinationLocation) {
        Toast.Error("Please select valid end location");
        return;
      }
      let direction_query = `https://api.mapbox.com/directions/v5/mapbox/driving-traffic/${state.map_origin?.longitude}%2C${state.map_origin?.latitude}%3B${state.map_destination?.longitude}%2C${state.map_destination?.latitude}?alternatives=false&annotations=distance%2Cduration%2Cspeed%2Ccongestion%2Cmaxspeed%2Cclosure%2Ccongestion_numeric&banner_instructions=true&geometries=geojson&language=en&overview=full&roundabout_exits=true&steps=true&access_token=pk.eyJ1IjoiZnJhbmtmciIsImEiOiJjbTVhcWl5OW4wZzZvMmpzNm5iYzUwNWF5In0.X7R4cjq4TJb1rF0XXqbTSQ`;

      const line_string = await axios(direction_query);
      const user = getDataFromLocalStorage("user");
      let geometry = line_string.data?.routes?.[0]?.geometry;
      setState({
        mapbox_direction: geometry,
      });

      let line_string2: any = JSON.stringify(line_string?.data);
      line_string2 = JSON.parse(line_string2);

      let obj = {
        data: {
          lineString: `LINESTRING(${line_string2?.routes?.[0]?.geometry?.coordinates
            ?.map((i: any) => i.reverse())
            ?.map((coord: any) => coord.join(" "))
            .join(", ")})`,
          type: "LineString",
          range: 1000,
          user: user?.id ?? null,
        },
      };

      const resp = await apiServices.postFromApi(
        "c2c-shipments/web-all-locations",
        obj,
        ""
      );

      if (resp?.data?.error) {
        Toast.Error("Please Select Valid Start and End Point");
        return;
      }
      setState({
        allJobsData: resp?.data,
      });
    } catch (error) {
      console.log(error);
      Toast.Error(
        `Sorry, we could not calculate driving directions from "${inputValueForOrigin}" to "${inputValueForDestination}"`
      );
    }
  };

  const handleGetAroundJobs = async ({
    lat,
    lng,
  }: {
    lat: number;
    lng: number;
  }) => {
    try {
      setState({ loadingLoader: true });
      let user = getDataFromLocalStorage("user");

      let body: any = {
        type: "Around Me",
        user: user?.id ?? null,
        limit: false,
        lat,
        lng,
      };

      const resp = await apiServices.postFromApi(
        "c2c-shipments/web-all-announcements",
        body,
        ""
      );
      setState({
        allJobsData: resp?.data,
        announes: "Around Me",
        loadingLoader: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

 const transSizes = (item:any) => {
 const deliveryType = FrankConstants?.packageSize(selector.lang)?.find(
      (a: any) => a.size === item
    );
    return deliveryType?.size_trans
  }

 const transDelivery = (item:any) => {
  const deliveryType = FrankConstants?.deliveryTypes(selector.lang)?.find(
        (a: any) => a.key === item
      );
    return deliveryType?.label
    }
  return (
    <>
      <Helmet>
        <title>Transport de colis entre particuliers - Frank</title>
        <link
          rel="canonical"
          href="https://www.v2internal.frankme.com/transporter-un-colis"
        />
        <meta
          name="description"
          content="Devenez transporteur particulier avec Frank et rentabilisez vos trajets en transportant des colis. Découvrez les annonces de livraison entre particuliers près de chez vous."
        />
      </Helmet>
      <div className="web-screen">
        <Loader loading={state?.loadingLoader} />

        <div style={{ background: "#f9c9b3" }}>
          <Grid
            container
            spacing={2}
            className="display-flex"
            style={{
              background: "#F4C9B3",
              paddingLeft: "40px",
              paddingRight: "40px",
              paddingTop: "30px",
            }}
          >
            <Grid xs={12} item style={{ zIndex: 99 }}>
              <NewHeader />
            </Grid>
          </Grid>

          <div style={{ padding: "20px 110px 0px 110px" }}>
            {/* <Grid container spacing={spacing.spacing2}>
              <Grid xs={12} md={12} item>
                <div>
                  <h1 className="page-title-one">{strings?.announces} </h1>
                </div>
                <div
                  style={{
                    display: "flex",

                    margin: 0,
                    padding: 0,
                  }}
                >
                  <div style={{ width: "150px" }}>
                    <img
                      src={transporterUnColisImage}
                      height={150}
                      width={150}
                      alt="transporter-img"
                    />
                  </div>
                  <div>
                    <p
                      style={{
                        fontFamily: "Raleway-Extra-Bold",
                        color: "#000",
                        fontSize: "18px",
                      }}
                    >
                      <span style={{ color: defaultLabelColors.main_blue }}>
                        {" "}
                        Gagnez de l'argent{" "}
                      </span>{" "}
                      en transportant des colis entre particuliers
                    </p>
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "18px",
                      }}
                    >
                      Sélectionnez, transportez, encaissez ! Rien de plus simple
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid> */}
            <Grid container spacing={spacing.spacing2}>
              <Grid xs={12} item>
                <h1
                  className="heading-one"
                  style={{
                    fontFamily: "Raleway-Italic",
                    color: defaultLabelColors.main_blue,
                    fontSize: "40px",
                  }}
                >
                DEVENIR TRANSPORTEUR PARTICULIERS
                </h1>
                <p className="paragraph-text-two">
                  <span style={{ fontWeight: "600" }}>
                    {" "}
                    Vous cherchez un moyen de générer un revenu complémentaire
                    tout en optimisant vos déplacements ?{" "}
                  </span>{" "}
                  <br />
                  Devenez transporteur pour particuliers avec Frank ! Notre
                  plateforme de livraison collaborative vous permet de monétiser
                  l’espace libre de votre véhicule en effectuant des livraisons
                  sur vos trajets habituels.
                  <br />
                </p>

                <p className="paragraph-text-two">
                  {" "}
                  Découvrez comment devenir cotransporteur et profitez des
                  nombreux avantages offerts par Frank.
                </p>
              </Grid>
            </Grid>
            <Grid container spacing={3} style={{ paddingBottom: "20px" }}>
              <Grid xs={12} md={6} item>
                <div>
                  <h3
                    style={{
                      fontFamily: "Raleway-Italic",
                      fontSize: "22px",
                      color: "#000",
                    }}
                  >
                    Votre trajet
                  </h3>
                  <div style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        top: "70px",
                        left: "26px",
                        width: "2px",
                        height: "20px",
                        borderLeft: "2px dashed gray",
                      }}
                    />

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div>
                        <HomeIcon height={55} width={55} />
                      </div>
                      <div style={{ width: "100%" }}>
                        <GoogleAutocompleteField
                          apiKey={apiKey}
                          placeholder="Lieu de départ"
                          inputValue={inputValueForOrigin}
                          onInputChange={setInputValueForOrigin}
                          onPlaceSelected={handlePlaceSelected}
                          key={"selectedLocation"}
                          styles={{
                            background: "#fff",
                            width: "96.5%",
                            borderRadius: "16px",
                            border: "none",
                            fontSize: "16px",
                            margin: "10px",
                            fontFamily: "Raleway-Italic",
                            padding: "10px",
                            marginLeft: 9,
                            zIndex: 1111,
                            color: "#000",
                            height: "41px",
                            paddingLeft: "10px",
                          }}
                        />
                      </div>
                    </div>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div>
                        <PointerIcon height={55} width={55} />
                      </div>
                      <div style={{ width: "100%" }}>
                        <GoogleAutocompleteField
                          apiKey={apiKey}
                          placeholder="Lieu d’arrivée"
                          inputValue={inputValueForDestination}
                          onInputChange={setInputValueForDestination}
                          onPlaceSelected={handlePlaceSelectedDestination}
                          key={"selectedLocation"}
                          styles={{
                            background: "#fff",
                            width: "96.5%",
                            borderRadius: "16px",
                            border: "none",
                            fontSize: "16px",
                            margin: "10px",
                            fontFamily: "Raleway-Italic",
                            padding: "10px",
                            marginLeft: 9,
                            zIndex: 1111,
                            color: "#000",
                            height: "41px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingTop: "10px",
                    }}
                  >
                    <CustomButton
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#fff",
                        fontFamily: "Raleway-Variable",
                        background:
                          inputValueForOrigin && inputValueForDestination
                            ? "#ee6931"
                            : "lightgrey",
                        border: "none",
                        borderRadius: "12px",
                        padding: "10px 40px 10px 40px",
                      }}
                      disable={
                        inputValueForOrigin && inputValueForDestination
                          ? false
                          : true
                      }
                      startIcon={<Search />}
                      onClick={handleGetDirections}
                      value={strings?.search}
                    />
                  </div>
                </div>

                <div>
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h3
                      style={{
                        flex: 1,
                        fontSize: "20px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {/* Les dernières annonces de trajet */}
                      Dernières annonces sur votre trajet 
                    </h3>
                    <div
                      style={{
                        fontSize: "16px",
                        fontFamily: "Raleway-Bold",
                      }}
                    >
                      {state?.allJobsData?.length ?? ""} annonces en ligne{" "}
                    </div>
                  </div>
                  <Grid container spacing={1}>
                    {state?.allJobsData?.length < 1 ? (
                      <div
                        style={{
                          width: "100%",
                          fontSize: "32px",
                          fontWeight: "700",
                          // opacity: "0.5",
                          textAlign: "center",
                          fontFamily: "Raleway-Variable",
                          marginTop: "20px",
                        }}
                      >
                        <p>Aucune annonce trouvée</p>
                        {/* <img
                          src={noJobsImage}
                          alt="not-found"
                          style={{ opacity: "0.5" }}
                        /> */}
                        <NoAnnoucementImage width={"100%"} height={"50vh"}/>
                      </div>////
                    ) : (
                      state?.allJobsData?.slice?.(0, 5)?.map((i: any) => {
                        return (
                          <Grid xs={12} sm={12} md={6} item>
                            <div
                              style={{
                                background: "#fff",
                                borderRadius: "16px",
                                padding: "15px",
                                cursor: "pointer",
                                minHeight: "250px",
                              }}
                              onClick={() => {
                                handleGetJobInfo(i);
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <div style={{ flex: 1 }}>
                                  <p
                                    style={{
                                      fontSize: "16px",
                                      fontFamily: "Raleway-Bold",
                                      color: "#000",
                                    }}
                                    className="title-text-announces"
                                  >
                                    {handleLocalize({
                                      en: i?.shipment_items?.[0]?.item_name_en,
                                      fr: i?.shipment_items?.[0]?.item_name_fr,
                                    }) ??
                                      i?.shipment_items?.[0]?.prod_name ??
                                      ""}

                                    <br />
                                    <span
                                      style={{
                                        fontFamily: "Raleway-Variable",
                                        fontSize: "12px",
                                        color: "grey",
                                      }}
                                    >
                                      Publié le{" "}
                                      {moment(i?.created_at).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </span>
                                  </p>
                                </div>
                                <div
                                  style={{
                                    fontFamily: "Raleway-Extra-Bold-Italic",
                                    fontSize: "24px",
                                    color: defaultLabelColors.main_orange,
                                  }}
                                >
                                  {i?.ship_price ?? "0.00"}€
                                </div>
                              </div>

                              {/* <div
                                style={{
                                  height: "100px",
                                  width: "100%",
                                  borderRadius: "15px 15px 0px 0px",
                                }}
                              >
                                <img
                                  src={i?.shipment_items[0]?.prod_images[0]}
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                    objectFit: "contain",
                                    borderRadius: "15px 15px 0px 0px",
                                  }}
                                  alt="prod-img"
                                />
                              </div> */}

                              <div
                                style={{
                                  background: "#F4F3F6",
                                  borderRadius: " 15px 15px",
                                  paddingLeft: "20px",
                                  paddingRight: "20px",
                                  marginTop: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  <p
                                    style={{
                                      background: "#f9c9b3",
                                      borderRadius: 100,
                                      padding: "10px 12px",
                                    }}
                                  >
                                    <MapHomeIcon
                                      fill={defaultLabelColors.main_blue}
                                      height={20}
                                      width={20}
                                    />
                                  </p>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontFamily: "Raleway-Italic",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Départ
                                    </span>
                                    <span
                                      style={{
                                        fontFamily: "Raleway-Bold-Italic",
                                        wordBreak: "break-all",
                                        fontSize: "12px",
                                        display: "inline-block",
                                        maxWidth: "120px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      <Tooltip
                                        title={`${i?.pickup?.city ?? "city"}, ${
                                          i?.pickup?.country ?? "country"
                                        }`}
                                      >
                                        <span>
                                          {i?.pickup?.city ?? "City"},
                                          {i?.pickup?.country ?? "country"}
                                        </span>
                                      </Tooltip>
                                    </span>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    width: "80%",
                                    height: "1px",
                                    background: defaultLabelColors.main_blue,
                                    marginLeft: "50px",
                                  }}
                                ></div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  <p
                                    style={{
                                      background: "#f9c9b3",
                                      borderRadius: 100,
                                      padding: "10px 13px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <MapPointerIcon
                                      fill={defaultLabelColors.main_orange}
                                    />
                                  </p>
                                  <p
                                    style={{
                                      fontFamily: "Raleway-Italic",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Arrivée
                                    <br />
                                    <span
                                      style={{
                                        fontFamily: "Raleway-Bold-Italic",
                                        wordBreak: "break-all",
                                        fontSize: "12px",
                                        display: "inline-block",
                                        maxWidth: "120px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      <Tooltip
                                        title={`${
                                          i?.dropoff?.city ?? "city"
                                        }, ${i?.dropoff?.country ?? "country"}`}
                                      >
                                        <span>
                                          {i?.dropoff?.city ?? "City"},
                                          {i?.dropoff?.country ?? "City"}
                                        </span>
                                      </Tooltip>
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Grid>
                        );
                      })
                    )}
                    {state?.allJobsData?.length < 6 ||
                    state?.allJobsData?.length < 1 ? null : (
                      <Grid xs={12} sm={12} md={6} item>
                        <div
                          onClick={() => {
                            navigate("/announces");
                          }}
                          style={{
                            background: "#fce0d4",
                            borderRadius: "16px",
                            cursor: "pointer",
                            minHeight: "250px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <div style={{ color: defaultLabelColors?.main_blue, fontSize: "20px" ,fontFamily:"Raleway-Semi-Bold"}}>Voir toutes les annonces</div>
                          {/* <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>

                          <MoreHoriz
                            style={{ color: "#fff", fontSize: "64px" }}
                            />
                            </div> */}
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </div>
              </Grid>

              <Grid xs={12} md={6} item>
                <div
                  style={{
                    display: "flex",
                    gap: 10,
                    justifyContent: "flex-end",
                  }}
                >
                  <CustomButton
                    value={"Tout"}
                    classesNames={
                      state?.announes === "All"
                        ? "contained_button"
                        : "outlined_button"
                    }
                    onClick={() => {
                      handleGetAllJobs("All");
                      handleClearSearchFeilds();
                    }}
                  />
                  <CustomButton
                    value={"Urgent"}
                    classesNames={
                      state?.announes === "Urgent"
                        ? "contained_button"
                        : "outlined_button"
                    }
                    onClick={() => {
                      handleGetAllJobs("Urgent");
                      handleClearSearchFeilds();
                    }}
                  />
                  <CustomButton
                    value={"Autour de moi"}
                    classesNames={
                      state?.announes === "Around Me"
                        ? "contained_button"
                        : "outlined_button"
                    }
                    onClick={() => {
                      handleGetAllJobs("Around Me");
                      handleClearSearchFeilds();
                    }}
                  />
                </div>
                <div style={{ height: "100%", marginTop: "10px" }}>
                  <MapboxGL
                    mapPins={
                      state?.allJobsData?.length > 0
                        ? state?.allJobsData?.map((i: any) => ({
                            lat: i?.pickup?.location?.coordinates?.[0],
                            lng: i?.pickup?.location?.coordinates?.[1],
                            id: i.id,
                            price: i?.ship_price,
                          }))
                        : []
                    }
                    mapbox_direction={state.mapbox_direction}
                    onMapIconClick={handleGetJobInfo}
                    handleGetAroundJobs={handleGetAroundJobs}
                    re_render_time={state.re_render_time}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid
              spacing={spacing.spacing2}
              style={{ paddingTop: "100px", paddingBottom: "100px" }}
            >
              <Grid xs={12} item>
                <div
                  className="triangle"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "20px",
                    paddingRight: "20px",
                    paddingLeft: "20px",
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      fontFamily: "Raleway-Italic",
                      fontWeight: "800",
                      color: "#fff",
                      fontSize: "32px",
                      width: "25%",
                    }}
                  >
                    COMMENT
                    <br /> ÇA FONCTIONNE ?
                  </div>

                  <div
                    style={{
                      display: "flex",
                      width: "22%",
                      height: 150,
                      borderRadius: "16px",
                      justifyContent: "center",

                      alignItems: "flex-start",
                      gap: "10px",
                      flexDirection: "column",
                    }}
                    className="mstpone_1"
                  >
                    <div>
                      <span
                        style={{
                          fontFamily: "Raleway-Extra-Bold-Italic",
                          color: "#fff",
                          background: defaultLabelColors.main_orange,
                          borderRadius: 100,
                          fontSize: "25px",
                          padding: "5px 15px 10px 15px",
                          textAlign: "left",
                          marginLeft: 20,
                        }}
                      >
                        1
                      </span>
                    </div>
                    <div
                      style={{
                        fontSize: "18px",
                        fontFamily: "Raleway-Extra-Bold",
                        color: defaultLabelColors.main_blue,
                        textAlign: "left",
                        padding: "0px 5px 0px 5px",
                        marginLeft: 20,
                      }}
                    >
                      Renseignez votre <br />
                      trajet
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      width: "22%",
                      height: 150,
                      borderRadius: "16px",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      gap: "10px",
                      flexDirection: "column",
                    }}
                    className="mstpone_1"
                  >
                    <div>
                      <span
                        style={{
                          fontFamily: "Raleway-Extra-Bold-Italic",
                          color: "#fff",
                          background: defaultLabelColors.main_blue,
                          borderRadius: 100,
                          fontSize: "25px",
                          padding: "5px 15px 10px 15px",
                          textAlign: "left",
                          marginLeft: "20px",
                        }}
                      >
                        2
                      </span>
                    </div>
                    <div
                      style={{
                        fontSize: "18px",
                        fontFamily: "Raleway-Extra-Bold",
                        color: defaultLabelColors.main_blue,
                        textAlign: "left",
                        padding: "0px 5px 0px 5px",
                        marginLeft: 20,
                      }}
                    >
                      Choisissez la taille
                      <br />
                      du colis à transporter
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      width: "22%",
                      height: 150,
                      borderRadius: "16px",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      gap: "10px",
                      flexDirection: "column",
                    }}
                    className="mstpone_1"
                  >
                    <div>
                      <span
                        style={{
                          fontFamily: "Raleway-Extra-Bold-Italic",
                          color: "#fff",
                          background: defaultLabelColors.main_orange,
                          borderRadius: 100,
                          fontSize: "25px",
                          padding: "5px 15px 10px 15px",
                          // textAlign: "center",
                          marginLeft: 20,
                        }}
                      >
                        3
                      </span>
                    </div>
                    <div
                      style={{
                        fontSize: "18px",
                        fontFamily: "Raleway-Extra-Bold",
                        color: defaultLabelColors.main_blue,
                        textAlign: "left",
                        padding: "0px 5px 0px 5px",
                        marginLeft: 20,
                      }}
                    >
                      Validez votre choix
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h2
              style={{
                textAlign: "left",
                fontSize: "42px",
                fontFamily: "Raleway-Extra-Bold-Italic",
                padding: " 40px 0px 20px 20px",
                margin: 0,
              }}
            >
              Nos actualités
            </h2>

            <ul className="cards">
              {state?.allBlogs?.slice(0, 3)?.map((item: any) => {
                return (
                  <>
                    <li
                      className="cards__item"
                      onClick={() => {
                        navigate(
                          `/blog-details/${item?.blog_cat_id}/${item?.id}`
                        );
                      }}
                    >
                      <div className="card">
                        <div
                          className="card__image"
                          style={{
                            backgroundImage: `url(${item?.blog_image?.[0]})`,
                          }}
                        ></div>
                        <div className="card__content">
                          <div style={{ display: "flex" }}>
                            <div className="card__title" style={{ flex: 1 }}>
                              {handleLocalize(item?.title)}
                              <p
                                style={{
                                  fontFamily: "Raleway-Italic",
                                  fontSize: "14px",
                                  color: "#000",
                                  fontWeight: "400",
                                  marginTop: 5,
                                }}
                              >
                                Posté le{" "}
                                {moment(
                                  `${item?.created_at}`.endsWith("Z")
                                    ? item?.created_at
                                    : item?.created_at + "Z"
                                ).fromNow()}
                              </p>
                            </div>
                            <div>
                              <East
                                style={{
                                  background: "#fff",
                                  borderRadius: 100,
                                  color: defaultLabelColors.main_blue,
                                  padding: 5,
                                  fontSize: "16px",
                                }}
                              />{" "}
                            </div>
                          </div>
                          <p className="card__text">
                            {handleLocalize(item?.sub_title)}
                          </p>
                        </div>
                      </div>
                    </li>
                  </>
                );
              })}
            </ul>
          </div>
        </Grid>
        <Grid
          container
          spacing={2}
          marginTop={5}
          style={{ padding: 10 }}
          className="display-flex"
        >
          <Grid
            xs={12}
            item
            className="display-flex"
            style={{
              maxWidth: "1000px",
              flexDirection: "column",
              background: "#F5C9B3",
              padding: "10px",
              borderRadius: "18px",
            }}
          >
            {/* <h3
              style={{
                fontSize: "36px",
                fontFamily: "Raleway-Extra-Bold-Italic",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                textAlign: "left",
                width: "1000px",
                paddingLeft: "38px",
                padding: "15px 0px 10px 15px",
                margin: 0,
              }}
            >
              Des questions ?
            </h3> */}
            <div style={{ width: "1000px" }}>
              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: "#F5C9B3",
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500",fontSize:'19px' }}>
                    Comment suis-je payé avec Frank?
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "90%" }}>
                  <p>
                    Après chaque livraison réussie, vous recevrez votre paiement
                    sur votre{" "}
                    <span style={{ fontWeight: "600" }}>
                      {" "}
                      porte-monnaie en ligne dans l’application Frank.
                    </span>
                    Vous pourrez par la suite verser le montant collecté
                    directement sur votre compte bancaire, sans frais
                    supplémentaires. Vous pouvez également utiliser votre
                    cagnotte sur la marketplace Frank.
                  </p>
                  <p>
                    Il était essentiel pour nous de proposer un processus simple
                    et rapide, pour permettre à nos livreurs d'accéder
                    facilement à leur rémunération.
                  </p>
                </AccordionDetails>
              </Accordion>

              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: "#F5C9B3",
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500",fontSize:'19px' }}>
                    Comment est déterminé le prix d'une livraison?
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "90%" }}>
                  <p>
                    Le prix d’une livraison est{" "}
                    <span style={{ fontWeight: "600" }}>
                      {" "}
                      déterminé par l’expéditeur du colis{" "}
                    </span>{" "}
                    et indiqué au sein de l’annonce proposée. Le transporteur
                    peut
                    <span style={{ fontWeight: "600" }}>
                      négocier le prix
                    </span>{" "}
                    qui figure sur l’annonce pour trouver un accord sur le
                    montant avec l’expéditeur.
                  </p>
                  <p>
                    Notre objectif : proposer des tarifs justes et compétitifs
                    pour le transport routier, tant pour les cotransporteurs que
                    pour les clients expéditeurs
                  </p>
                </AccordionDetails>
              </Accordion>

              {/* 
              
              // comment by asad iqbal from 16/1/25(review website + app ppt)
              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: "#F5C9B3",
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500" }}>
                    L'argent que je gagne en tant que transporteur particulier
                    est-il soumis à l'impôt?
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "90%" }}>
                  <p>
                    <span style={{ fontWeight: "600" }}>
                      Oui, les revenus générés en tant que cotransporteur sont
                      soumis à l'impôt
                    </span>
                    , comme toutes les autres ressources financières. Il est de
                    votre responsabilité de déclarer ces revenus auprès des
                    autorités compétentes et de vous acquitter des éventuelles
                    taxes et cotisations sociales correspondantes. Frank vous
                    fournira un
                    <span style={{ fontWeight: "600" }}>
                      récapitulatif annuel de vos revenus
                    </span>
                    pour vous aider dans vos démarches fiscales.
                  </p>
                </AccordionDetails>
              </Accordion> */}

              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: "#F5C9B3",
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500",fontSize:'19px' }}>
                    Devenir transporteur est-il un travail à temps plein?
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "90%" }}>
                  <p>
                    Non, devenir cotransporteur avec Frank ne nécessite pas
                    forcément un engagement à temps plein.
                    <span style={{ fontWeight: "600" }}>
                      Vous êtes libre de choisir le nombre et la fréquence des
                      missions
                    </span>
                    que vous souhaitez effectuer, en fonction de vos
                    disponibilités et de vos objectifs. Que vous soyez étudiant,
                    salarié ou indépendant, vous pouvez adapter votre activité
                    de transporteur à votre emploi du temps.
                  </p>
                  <p>
                    Par ailleurs, sachez qu'en effectuant des missions avec
                    Frank, vous n'exercez pas le métier de transporteur de
                    marchandises comme le ferait un livreur pour une entreprise
                    de transport.
                    <span style={{ fontWeight: "600" }}>
                      Vous n'avez donc pas besoin d'une licence de transport.
                    </span>
                    Les compétences nécessaires sont aussi accessibles à tous.
                  </p>
                </AccordionDetails>
              </Accordion>

              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: "#F5C9B3",
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500",fontSize:'19px' }}>
                    Quels types de colis sont livrés?
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "90%" }}>
                  <p>
                    En tant que cotransporteur Frank, vous pouvez être amené à
                    livrer une{" "}
                    <span style={{ fontWeight: "600" }}>
                      {" "}
                      grande variété de colis et de marchandises
                    </span>
                    , tels que :
                  </p>
                  <p>• Des documents ;</p>
                  <p>• Des petits objets et cadeaux ;</p>

                  <p>• Des produits électroniques ;</p>

                  <p>• Des vêtements et accessoires ;</p>

                  <p>• Des produits alimentaires non périssables.</p>

                  <p>
                    Chaque annonce de livraison indique clairement toutes les
                    conditions préalables au transport de la marchandise : la
                    nature du colis à transporter, ainsi que ses dimensions et
                    son poids, vous permettant ainsi de choisir les missions qui
                    vous conviennent le mieux. Les prestations de transport sont
                    donc détaillées, pour que vous puissiez faire un choix
                    éclairé lors de la sélection de vos missions.
                  </p>
                </AccordionDetails>
              </Accordion>

              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: "#F5C9B3",
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      <ExpandMore />
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500",fontSize:'19px' }}>
                    Si j'annule une course, est-ce que je suis pénalisé?
                  </h3>
                </AccordionSummary>
                <AccordionDetails>
                  <p>
                    Bien que nous encouragions nos cotransporteurs à honorer
                    leurs engagements, nous comprenons que des imprévus puissent
                    survenir. En cas d'annulation de votre part, veillez à
                    prévenir Frank et le client expéditeur dans les meilleurs
                    délais.{" "}
                    <span style={{ fontWeight: "600" }}>
                      {" "}
                      Si vos annulations restent exceptionnelles, vous ne serez
                      pas pénalisé.
                    </span>
                  </p>

                  <p>
                    Cependant, des annulations fréquentes ou de dernière minute
                    peuvent entraîner une baisse de votre note de confiance et
                    une réduction du nombre de missions qui vous sont proposées.
                    Il s'agit pour Frank de garantir aux utilisateurs une bonne
                    qualité de service.
                  </p>
                </AccordionDetails>
              </Accordion>
            </div>
          </Grid>
        </Grid>

        <div
          className="main-container"
          style={{ background: "#fff", marginTop: "100px" }}
        >
          <CustomFooter />
        </div>

        <DraggableDialog
          maxWidth={`${state?.negotiate ? "sm" : "md"}`}
          open={state?.openJobDialog}
          handleClose={() => {
            setState({
              openJobDialog: false,
              messagingScreen: false,
              negotiate: false,
              disableNegotiate: false,
              accepter_details: [],
              negotiated_price: "",
              initilalTotalFeedBack: 3,
            });
          }}
          dialog_title={
            state?.negotiate && !state?.messagingScreen
              ? "Négocier"
              : "Transporter un colis"
          }
          classes={classes}
          dialogBody={
            <DialogContent style={{}} dividers>
              {state?.negotiate ? (
                state?.messagingScreen ? (
                  <>
                    <div
                      className="card-container"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "92%",
                        padding: "20px",
                        height: "60vh",
                      }}
                    >
                      <div style={{ flex: 1, width: "100%", gap: 20 }}>
                        <div
                          style={{
                            background: "#eef2f6",
                            borderRadius: "16px",
                            paddingLeft: "20px",
                            paddingRight: "20px",
                            marginTop: "10px",
                            width: "90%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              borderBottom: "1px solid #3026f1",
                            }}
                          >
                            <p
                              style={{
                                background: "#f9c9b3",
                                borderRadius: "32px",
                                padding: "10px 15px",
                              }}
                            >
                              <MapHomeIcon
                                fill={defaultLabelColors.main_blue}
                                height={20}
                                width={20}
                              />
                            </p>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span
                                style={{
                                  fontFamily: "Raleway-Italic",
                                  fontSize: "16px",
                                }}
                              >
                                Départ
                              </span>
                              <span
                                style={{
                                  fontFamily: "Raleway-Italic",
                                  fontWeight: "700",
                                  wordBreak: "break-all",
                                  fontSize: "15px",
                                }}
                              >
                                {" "}
                                {state?.jobDetails?.pickup?.city ?? "City"},
                                {state?.jobDetails?.pickup?.country ?? "City"}
                              </span>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <p
                              style={{
                                background: "#f9c9b3",
                                borderRadius: "32px",
                                padding: "10px 15px",
                              }}
                            >
                              <MapPointerIcon
                                fill={defaultLabelColors.main_blue}
                              />
                            </p>
                            <p
                              style={{
                                fontFamily: "Raleway-Italic",
                                fontSize: "16px",
                              }}
                            >
                              Destination
                              <br />
                              <span
                                style={{
                                  fontFamily: "Raleway-Italic",
                                  fontWeight: "700",
                                  wordBreak: "break-all",
                                  fontSize: "15px",
                                }}
                              >
                                {" "}
                                {state?.jobDetails?.dropoff?.city ?? "City"},
                                {state?.jobDetails.dropoff?.country ?? "City"}
                              </span>
                            </p>
                          </div>

                          <div
                            style={{
                              borderTop: "1px solid lightgrey",
                              padding: "10px",
                              display: "flex",
                            }}
                          >
                            <div
                              style={{
                                fontFamily: "Raleway-Variable",
                                fontSize: "18px",
                                fontWeight: 600,
                                flex: 1,
                              }}
                            >
                              Frais de livraison
                            </div>
                            <div
                              style={{
                                fontFamily: "Raleway-Variable",
                                fontSize: "18px",
                                fontWeight: 600,
                              }}
                            >
                              {numberFormatWithoutCurrency(
                                state?.jobDetails?.ship_price
                              )}{" "}
                              €
                            </div>
                          </div>
                        </div>

                        <div
                          style={{
                            background: "#DCDBFB",
                            borderRadius: "16px",
                            width: "94%",
                            padding: "10px",
                            fontFamily: "Raleway-Variable",
                            fontWeight: "600",
                            marginTop: "20px",
                            fontSize: "16px",
                          }}
                        >
                          Vous avez fait une offre pour une montant de :{" "}
                          {numberFormatWithoutCurrency(state?.negotiated_price)}{" "}
                          €
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                      ></div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        gap: "10px",
                        paddingTop: "20px",
                      }}
                    >
                      <CustomButton
                        classesNames={"outlined_button"}
                        value={"Retour"}
                        // disable={state?.items?.length > 0 ? true : false}
                        style={{ width: "200px" }}
                        onClick={handleTerminer}
                      />
                      <CustomButton
                        classesNames={"contained_button"}
                        value={"Terminer"}
                        // disable={state?.items?.length > 0 ? true : false}
                        style={{ width: "200px" }}
                        onClick={() => {
                          setState({
                            openJobDialog: false,
                            messagingScreen: false,
                            negotiate: false,
                            disableNegotiate: false,
                            accepter_details: [],
                            negotiated_price: "",
                          });
                          handleGetAllJobs(state?.announes);
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <div>
                    <div>
                      <div className="card-container">
                        <img
                          src={
                            state?.jobDetails?.shipment_items[0]?.prod_images[0]
                          }
                          className="card-image"
                          alt="prod-img"
                        />
                        <div
                          className="card-details"
                          style={{ display: "flex" }}
                        >
                          <div
                            className="card-title"
                            style={{ fontSize: 20, flex: 1 }}
                          >
                            {handleLocalize({
                              en: state?.jobDetails?.shipment_items[0]
                                ?.item_name_en,
                              fr: state?.jobDetails?.shipment_items[0]
                                ?.item_name_fr,
                            }) ??
                              state?.jobDetails?.shipment_items[0]?.prod_name ??
                              ""}
                          </div>

                          <div
                            className="card-quantity"
                            style={{
                              color: defaultLabelColors.main_orange,
                              display: "flex",
                              alignItems: "center",
                              fontSize: "26px",
                              fontWeight: 600,
                              paddingRight: "10px",
                            }}
                          >
                            {state?.jobDetails?.ship_price} €
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="card-container"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        lineHeight: "1px",
                        marginTop: "20px",
                      }}
                    >
                      <p
                        style={{
                          fontFamily: "Raleway-Semi-Bold",
                          fontSize: "20px",
                          fontWeight: "600",
                          textAlign: "center",
                        }}
                      >
                        Insérez le montant de votre offre
                      </p>

                      <div className="display-flex">
                        <StyledTextField
                          name="negotiated_price"
                          onChange={onInputChange}
                          type="text"
                          placeholder="ex : 8 "
                          value={state.negotiated_price ?? ""}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                sx={{
                                  fontSize: "52px",
                                  fontFamily: "Raleway-Variable",
                                  fontWeight: "700",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "Raleway-Variable",
                                    fontSize: "53px",
                                    position: "absolute",
                                    left: "calc(100% - 35%)",
                                  }}
                                >
                                  €
                                </span>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        gap: "10px",
                        paddingTop: "20px",
                      }}
                    >
                      <CustomButton
                        classesNames={"outlined_button"}
                        value={"Annuler"}
                        // disable={state?.items?.length > 0 ? true : false}
                        style={{ width: "100%" }}
                        onClick={() => {
                          setState({ negotiate: false, negotiated_price: "" });
                        }}
                      />
                      <CustomButton
                        classesNames={
                          state?.disableNegotiateBtn
                            ? "outlined_button_disable"
                            : "contained_button"
                        }
                        value={"Négocier"}
                        disable={state?.disableNegotiateBtn}
                        style={{ width: "100%" }}
                        onClick={() => {
                          if (state?.disableNegotiateBtn) return;
                          setState({
                            disableNegotiateBtn: true,
                          });
                          setTimeout(() => {
                            handleNegotiate(
                              state.negotiated_price,
                              state?.jobDetails?.id
                            );
                          }, 500);
                        }}
                      />
                    </div>
                  </div>
                )
              ) : (
                <>
                  <div
                    style={{
                      borderRadius: "16px",
                      border: "1px solid #E0E0E0",
                      padding: 20,
                    }}
                  >
                    <div style={{ display: "flex", paddingBottom: 20 }}>
                      <div
                        style={{
                          flex: 1,
                          fontFamily: "Raleway-Variable",
                          fontSize: "16px",
                        }}
                      >
                        Détail de l’expéditeur
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                          fontFamily: "Raleway-Variable",
                          fontSize: "16px",
                        }}
                      >
                        Membre depuis &nbsp;
                        <span style={{ fontWeight: "600" }}>
                          {moment(
                            state?.jobDetails?.user_details?.created_at
                          ).format("YYYY") ?? "recently"}
                        </span>
                      </div>
                    </div>
                    <Accordion
                      elevation={0}
                      sx={{
                        "&::before": {
                          display: "none",
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <AccordionSummary
                        style={{
                          marginTop: -20,
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              sx={{
                                position: "relative",
                                width: 100,
                                height: 100,
                                borderRadius: 100,
                                overflow: "hidden",
                                display: "flex",

                                boxShadow: 3,
                              }}
                            >
                              <img
                                src={
                                  state?.jobDetails?.user_details
                                    ?.profile_pic ??
                                  "https://i.pinimg.com/736x/55/0f/49/550f49a459548599a5a4ea1c67fc0244.jpg"
                                }
                                alt="Profile"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              />
                            </Box>
                          </div>
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "flex-end",
                              paddingLeft: 10,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: "Raleway-Bold",
                                  fontSize: "18px",
                                }}
                              >
                                {state?.jobDetails?.user_details?.cus_name ??
                                  "Customer Name"}
                              </p>
                              <p
                                style={{
                                  margin: 0,
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 10,
                                }}
                              >
                                <Stack spacing={1}>
                                  <Rating
                                    style={{ borderRadius: 12 }}
                                    name="half-rating-read"
                                    defaultValue={
                                      state?.jobDetails?.user_details
                                        ?.cus_ratings ?? 2
                                    }
                                    precision={0.5}
                                    readOnly
                                    size="large"
                                  />
                                </Stack>

                                <span
                                  style={{
                                    fontSize: "16px",
                                    fontFamily: "Raleway-Semi-Bold",
                                    width: "100%",
                                  }}
                                >
                                  {`( ${
                                    numberFormatWithoutCurrencyFixedOne(
                                      state?.jobDetails?.user_details?.cus_ratings?.toFixed(
                                        1
                                      )
                                    ) ?? "0"
                                  } )`}
                                </span>
                                <span
                                  style={{
                                    fontFamily: "Raleway-Bold",
                                    color: defaultLabelColors.main_blue,
                                    fontSize: 18,
                                    paddingLeft: 10,
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  Évaluation{" "}
                                  <ExpandMore
                                    style={{
                                      color: defaultLabelColors.main_blue,
                                      fontWeight: 600,
                                    }}
                                  />
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </AccordionSummary>

                      <AccordionDetails style={{ marginTop: "10px" }}>
                        {state?.allRatings?.ratings2?.length < 1 ? (
                          <p
                            style={{
                              fontFamily: "Raleway-Semi-Bold",
                              fontSize: 26,
                            }}
                          >
                            No Review Received yet!
                          </p>
                        ) : (
                          state?.allRatings?.ratings2
                            ?.slice(0, state?.initilalTotalFeedBack)
                            ?.map((i: any) => {
                              return (
                                <div
                                  style={{
                                    width: "100%",
                                    marginTop: 10,
                                    borderBottom: `${
                                      state?.allRatings?.ratings2?.length < 2
                                        ? "0px"
                                        : "1px"
                                    } solid #E0E0E0`,
                                    paddingBottom: 5,
                                    display: "flex",
                                    gap: 10,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      position: "relative",
                                      width: 60,
                                      height: 60,
                                      borderRadius: "50%",
                                      overflow: "hidden",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      boxShadow: 3,
                                      border: "1px solid #E0E0E0",
                                    }}
                                  >
                                    <img
                                      src={
                                        i?.profile_pic ??
                                        "https://i.pinimg.com/736x/55/0f/49/550f49a459548599a5a4ea1c67fc0244.jpg"
                                      }
                                      alt="Profile"
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                      }}
                                    />
                                  </Box>
                                  <div style={{ flex: 1, paddingTop: 10 }}>
                                    <p
                                      style={{
                                        fontFamily: "Raleway-Semi-Bold",
                                        fontSize: 14,
                                        color: defaultLabelColors.main_blue,
                                        margin: 0,
                                      }}
                                    >
                                      {i?.added_by ?? ""}
                                    </p>
                                    <p style={{ margin: 0 }}>
                                      <Stack spacing={1}>
                                        <Rating
                                          style={{ borderRadius: 12 }}
                                          name="half-rating-read"
                                          defaultValue={i?.rating ?? 2}
                                          precision={0.5}
                                          readOnly
                                          size="small"
                                        />
                                      </Stack>
                                    </p>
                                    <p
                                      style={{
                                        margin: 0,
                                        paddingTop: 5,
                                        fontSize: "14px",
                                        fontFamily: "Raleway-Italic",
                                      }}
                                    >
                                      {i?.feedback ?? ""}
                                    </p>
                                  </div>

                                  <div
                                    style={{
                                      fontSize: 14,
                                      color: "#757575",
                                      fontFamily: "Raleway-Italic",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {moment(
                                      `${i?.created_at}`.endsWith("Z")
                                        ? i?.created_at
                                        : i?.created_at + "Z"
                                    ).fromNow()}
                                  </div>
                                </div>
                              );
                            })
                        )}
                        {state?.allRatings?.ratings2?.length > 6 ? (
                          <Grid xs={12} item className="display-flex">
                            <p
                              className="profile-headings display-flex"
                              style={{
                                color: "#3026f1",
                                textAlign: "center",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setState({
                                  initilalTotalFeedBack:
                                    state?.initilalTotalFeedBack + 3,
                                });
                              }}
                            >
                              <RefrestIcon /> Plus de reviews
                            </p>
                          </Grid>
                        ) : null}
                      </AccordionDetails>
                    </Accordion>
                  </div>

                  <div
                    style={{
                      borderRadius: "16px",
                      border: "1px solid #E0E0E0",
                      padding: "0px 20px 20px 20px",
                      gap: 10,
                      marginTop: "10px",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "16px",
                      }}
                    >
                      Type de livraison
                    </p>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          flex: 1,
                          fontFamily: "Raleway-Variable",
                          fontWeight: "600",
                          fontSize: "24px",
                        }}
                      >
                        { transDelivery(state?.jobDetails?.ship_delivery_type) ?? "Express"}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontFamily: "Raleway-Variable",
                          fontSize: "16px",
                        }}
                      >
                        Annonce créée le{" "}
                        {moment(state?.jobDetails?.created_at).format(
                          "DD/MM/YYYY"
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      borderRadius: "16px",
                      border: "1px solid #E0E0E0",
                      padding: "15px",
                      gap: 10,
                      marginTop: "10px",
                    }}
                  >
                    <div
                      style={{
                        background: "#F4F3F6",
                        borderRadius: "24px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <div
                        style={{ display: "flex", padding: "10px", gap: 10 }}
                      >
                        <div style={{ flex: 1 }} className="display-flex">
                          <HomeIcon height={36} />
                        </div>
                        <div>
                          <p
                            className="paragraph-text"
                            style={{ fontSize: "14px" }}
                          >
                            {strings?.pickup_address}
                          </p>
                          <p
                            className="paragraph-text"
                            style={{ fontSize: "14px", fontWeight: "600" }}
                          >
                            {state?.jobDetails?.pickup?.address ??
                              "Paris, France"}
                            &nbsp;
                            {state?.jobDetails?.pickup?.city ?? ""},
                            {state?.jobDetails?.pickup?.country ?? "Paris"}
                          </p>
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", padding: "10px", gap: 10 }}
                      >
                        <div style={{ flex: 1 }} className="display-flex">
                          <PointerIcon height={36} />
                        </div>
                        <div>
                          <p
                            className="paragraph-text"
                            style={{ fontSize: "14px" }}
                          >
                            {strings?.dropoff_address}
                          </p>
                          <p
                            className="paragraph-text"
                            style={{ fontSize: "14px", fontWeight: "600" }}
                          >
                            {state?.jobDetails?.dropoff?.address ?? ""}
                            &nbsp;
                            {state?.jobDetails?.dropoff?.city ?? ""},
                            {state?.jobDetails?.dropoff?.country ?? ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      borderRadius: "16px",
                      border: "1px solid #E0E0E0",
                      padding: "0px 20px 20px 20px",
                      gap: 10,
                      marginTop: "10px",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "16px",
                      }}
                    >
                      {strings?.item_sizes}
                    </p>

                    <div>
                      {state?.jobDetails?.shipment_items?.map((i: any) => {
                        return (
                          <div
                            className="card-container"
                            style={{ marginTop: "10px" }}
                          >
                            <img
                              src={i?.prod_images[0]}
                              className="card-image"
                              alt="prod-img"
                            />
                            <div className="card-details">
                              <div
                                className="card-title"
                                style={{ fontSize: 17 }}
                              >
                                {handleLocalize({
                                  en: i?.item_name_en,
                                  fr: i?.item_name_fr,
                                }) ??
                                  i?.prod_name ??
                                  ""}
                              </div>

                              <div className="card-quantity">
                                x{i?.prod_quantity}
                              </div>
                              {i?.prod_is_fragile ? (
                                <div
                                  className="card-quantity"
                                  style={{
                                    fontSize: "14px",
                                    fontFamily: "Raleway-Variable",
                                    fontWeight: "500",
                                    marginTop: 5,
                                  }}
                                >
                                  <span style={{ fontWeight: 600 }}>
                                    Fragile
                                  </span>{" "}
                                  : manipuler avec soin
                                </div>
                              ) : null}
                            </div>
                            <div
                              className="card-quantity"
                              style={{ paddingRight: "20px" }}
                            >
                              {transSizes(i?.cat_name)}{i?.prod_quantity > 1 ? "s" : null}
                              </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  {state?.accepter_details?.[0]?.is_accepted === null ? (
                    <div
                      style={{
                        borderRadius: "16px",
                        border: "1px solid #E0E0E0",
                        padding: "20px 20px 20px 20px",
                        gap: 10,
                        marginTop: "10px",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            flex: 1,
                            fontFamily: "Raleway-Variable",
                            fontWeight: "700",
                            fontSize: "24px",
                          }}
                        >
                          Frais de livraison négociés
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontWeight: "600",
                            fontFamily: "Raleway-Variable",
                            fontSize: "22px",
                            color: "grey",
                            gap: 20,
                          }}
                        >
                          {numberFormatWithoutCurrency(
                            state?.jobDetails?.ship_price
                          )}{" "}
                          €
                          <span style={{ color: defaultLabelColors.main_blue }}>
                            {numberFormatWithoutCurrency(
                              state?.accepter_details[0]?.ship_price
                            )}{" "}
                            €
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        borderRadius: "16px",
                        border: "1px solid #E0E0E0",
                        padding: "20px 20px 20px 20px",
                        gap: 10,
                        marginTop: "10px",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            flex: 1,
                            fontFamily: "Raleway-Variable",
                            fontWeight: "700",
                            fontSize: "24px",
                          }}
                        >
                          {"Frais de livraison"}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: "600",
                            fontFamily: "Raleway-Variable",
                            fontSize: "22px",
                            color: defaultLabelColors.main_blue,
                          }}
                        >
                          {numberFormatWithoutCurrency(
                            state?.jobDetails?.ship_price
                          )}{" "}
                          €
                        </div>
                      </div>
                    </div>
                  )}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      gap: "10px",
                      paddingTop: "20px",
                    }}
                  >
                    <CustomButton
                      classesNames={
                        state?.disableNegotiate
                          ? "outlined_button_disable"
                          : "outlined_button"
                      }
                      value={strings?.negotiate}
                      disable={state?.disableNegotiate}
                      style={{
                        width: "200px",
                        cursor: state?.disableNegotiate ? "auto" : "pointer",
                      }}
                      onClick={() => {
                        if (state?.disableNegotiate) return;
                        setState({ negotiate: true });
                      }}
                    />
                    <CustomButton
                      classesNames={"contained_button"}
                      value={strings?.accept}
                      // disable={state?.items?.length > 0 ? true : false}
                      style={{ width: "200px" }}
                      onClick={handleAcceptJob}
                    />
                  </div>
                </>
              )}
            </DialogContent>
          }
        />

        <DraggableDialog
          maxWidth={"md"}
          open={state?.openLoginDialog}
          handleClose={() => {
            setState({ openLoginDialog: false });
          }}
          classes={classes}
          dialogBody={
            <DialogContent
              style={{
                display: "flex",
                background: "#FCE6DD",
              }}
              dividers
            >
              <Grid container spacing={spacing.spacing2}>
                <Grid xs={12} item>
                  <AuthAction
                    {...state}
                    {...props}
                    handleCloseLoginPage={handleCloseLoginPage}
                  />
                </Grid>
              </Grid>
            </DialogContent>
          }
        />
      </div>

      <div>
        <div className="mobile-screen">
          <TransporterUnColisMobile
            {...props}
            {...state}
            handleAcceptJob={handleAcceptJob}
            handleNegotiate={handleNegotiate}
            handleSentFirstMessage={handleSentFirstMessage}
            handleCloseLoginPage={handleCloseLoginPage}
            handleTerminer={handleTerminer}
            onInputChange={onInputChange}
          />
        </div>
      </div>
    </>
  );
}

export default TranspoprterUnColis;

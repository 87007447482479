import {
  Add,
  AddPhotoAlternateOutlined,
  Close,
  East,
  ExpandMore,
  Remove,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Radio,
  Switch,
  Typography
} from "@mui/material";
import { Box } from "@mui/system";
import ActionSheet from "actionsheet-react";
import React, { useRef } from "react";
import {
  AddImageIcon,
  AppStoreFR,
  FrankIcon,
  PlusPackageitemIcon,
} from "../../assets/images/FrankIcons";
import facebookIcon from "../../assets/images/FrankIcons/Artboard_1.png";
import instaIcon from "../../assets/images/FrankIcons/Artboard_2.png";
import linkedInIcon from "../../assets/images/FrankIcons/Artboard_3.png";
import downloadappStoreBanner from "../../assets/images/FrankIcons/download-app-mobiles@4x.png";
import playSore from "../../assets/images/FrankIcons/PlayStore-btn.png";
import { strings } from "../../Store/Localization";
import CustomCardForMobile from "../../UiComponent/CustomAnnouceCard/CustomCardForMobile";
import CustomButton from "../../UiComponent/CustomButton";
import CustomHeaderMobile from "../../UiComponent/CustomeHeaderMobile";
import ParcelSizeSelect from "../../UiComponent/ParcelSizeSelect";
import InputField from "../../UiComponent/TextFeild";
import Toast from "../../UiComponent/Toast/Toast";
import { defaultLabelColors, SHIPMENT_TYPE } from "../../utils/constants";
import { handleLocalize } from "../../utils/helper";
import DropOffInfo from "./DropOffInfo";
import PickupInfo from "./PickupInfo";
import ReviewPackage from "./ReviewPackage";

function SendPackageMobileView(props: any) {
  const ref = useRef<any>(null);
 
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
       
    }
  );


  // const EditShipmentItem = (item: any, idx: any) => {
  //   try {
  //     setState({
  //       current_index: idx,
  //       item_quantity: item?.prod_quantity,
  //       prod_length: item?.productLength,
  //       prod_width: item?.productWidth,
  //       prod_height: item?.productHeight,
  //       additional_info: item?.product_additionalInfo,
  //       isFragile: item?.is_fragile,
  //       selectedValue: item?.productSize,
  //       announcement_item: {
  //         images: item?.images,
  //       },
  //       selected_value: item?.packageType,
  //       isUpdate: true,
  //     });
  //     handleOpen()
  //   } catch (error) {}
  // };

  const handleOpen = () => {
    if (ref.current) {
      ref.current.open();
    }
  };
  const handleClose = () => {
    props?.handleClearAllFeils();
    if (ref.current) {
      ref.current.close();
    }
    

  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props?.setSelectedValue(event.target.value);
  };

  const handleNext = () => {
    props?.handleAddShipment();
    handleClose();
  };

  return (
    <div className="main-container" style={{ background: "#f9c9b3" }}>
      <CustomHeaderMobile />
      {props?.step === 1 ? (
        <>
          <Grid container spacing={2} style={{ padding: "0px 20px 0px 20px" }}>
            <Grid xs={12} item>
              <p
                className="heading-one"
                style={{
                  fontFamily: "Raleway-Italic",
                  color: defaultLabelColors.main_blue,
                  fontSize: 26,
                }}
              >
                ENVOYER UN COLIS ENTRE PARTICULIERS
              </p>
              <p className="paragraph-text-two" style={{ fontSize: "18px" }}>
                Vous voulez envoyer un colis sans vous ruiner? Découvrez la
                livraison collaborative avec Frank!
                <br />
                Notre service innovant vous permet de faire livrer vos colis par
                des particuliers. Un moyen économique et écologique d'expédier
                vos paquets partout en France.
              </p>

              <p className="paragraph-text-two" style={{ fontWeight: "700" }}>
                Déposez votre annonce
              </p>
            </Grid>
          </Grid>
          <div style={{ padding: "0px 20px 0px 35px", marginTop: "-10px" }}>
            <Grid
              container
              spacing={2}
              style={{
                background: "#fff",
                borderRadius: "16px",
                padding: "5px",
              }}
            >
              {props?.items?.map((i: any, idx: any) => {
                return (
                  <Grid
                    xs={12}
                    md={6}
                    item
                    style={{ padding: 0, marginBottom: 7 }}
                  >
                    <CustomCardForMobile
                      productImage={i?.images?.[0]?.uri}
                      imageQuantity={i?.images?.length}
                      productLength={i?.productLength}
                      productWidth={i?.productWidth}
                      productheight={i?.productHeight}
                      productSize={i?.productSize?.size}
                      productTitle={handleLocalize({
                        fr: i?.packageType?.item_name_fr,
                        en: i?.packageType?.item_name_en,
                      })}
                      shipmentQuantity={i?.prod_quantity}
                      // handleEdit={() =>  props?.EditShipmentItem(i, idx)}
                      handleRemove={() => props?.handleRemoveItem(i, idx)}
                    />
                  </Grid>
                );
              })}

              <Grid
                xs={12}
                md={6}
                item
                style={{
                  border: "1px dashed  #3026f1",
                  background: "#ede7f6",
                  borderRadius: "16px",
                  height:'120px'
                }}
                className="display-flex"
                onClick={handleOpen}
              >
                <div
                  className="display-flex"
                  style={{ flexDirection: "column", gap: "0px" }}
                >
                  <PlusPackageitemIcon
                    height={28}
                    fill={defaultLabelColors.main_blue}
                  />
                  <p
                    className="heading-one"
                    style={{
                      fontFamily: "Raleway-Italic",
                      color: defaultLabelColors.main_blue,
                      fontSize: "20px",
                      textAlign: "center",
                    }}
                  >
                    Ajouter un colis
                  </p>
                </div>
              </Grid>
            </Grid>
             <div style={{width:'100%',marginLeft:'-8px',marginTop:'20px'}}>
                <CustomButton
                  style={{ fontFamily: "Raleway-Variable",width:'100%' }}
                  classesNames={"contained_button"}
                  onClick={props?.handleContinue}
                  value={
                    <React.Fragment>
                      <div
                        className="display-flex"
                        style={{
                          columnGap: "40px",
                          padding: "8px 0px 8px 0px",
                          fontSize: "20px",
                          width: "100%",
                        }}
                      >
                        {strings?.continue_}
                        <East
                          style={{
                            color: defaultLabelColors.main_blue,
                            background: "#f9d9b6",
                            borderRadius: 100,
                            fontSize: 22,
                            padding: 2,
                          }}
                        />
                      </div>
                    </React.Fragment>
                  }
                />
             </div>

            <div className="why-a3_mob" style={{ marginTop: "30px" }}>
              <div className="menu-a4_mob">
                <span
                  className="achetez-vos-produits-a5_mob"
                  style={{
                    textAlign: "center",
                    fontSize: 32,
                    fontFamily: "Raleway-Italic",
                    fontWeight: 800,
                  }}
                >
                  Nos actualités
                </span>
                <span
                  className="soyez-livres-a6_mob"
                  style={{ textAlign: "center" }}
                >
                  Soyez livrés par nos Franks ou nos partenaires
                </span>
              </div>
              <div className="frame-a7_mob">
                <Grid container spacing={2}>
                  <Grid xs={12} item>
                    <div className="actu">
                      <div className="img-wrapper-blue">
                        <div className="pic-26-1"></div>
                      </div>
                      <div className="text-f9">
                        <div style={{ display: "flex", width: "100%" }}>
                          <div
                            style={{
                              width: "70%",
                              color: "#080626",
                              fontFamily: "Raleway-Variable",
                              fontSize: 18,
                              fontWeight: 700,
                            }}
                          >
                            Du nouveau chez Frank
                            <p
                              style={{
                                fontFamily: "Raleway-Italic",
                                fontSize: "14px",
                                color: "#000",
                                fontWeight: "400",
                                margin: 0,
                              }}
                            >
                              il y a 10 heures
                            </p>{" "}
                          </div>
                          <div style={{ width: "30%", textAlign: "right" }}>
                            <East
                              style={{
                                background: "#fff",
                                borderRadius: 100,
                                color: defaultLabelColors.main_blue,
                                padding: 2,
                                fontSize: "16px",
                              }}
                            />{" "}
                          </div>
                        </div>
                        <span
                          className="lorem-ipsum-dolor-fc"
                          style={{ marginBottom: "10px" }}
                        >
                          Lorem ipsum dolor sit amet consectetur. Sapien
                          pharetra adipiscing odio et bibendum duis. Auctor
                          scelerisque tellus tortor euismod...
                        </span>
                      </div>
                    </div>
                  </Grid>

                  <Grid xs={12} item>
                    <div className="actu">
                      <div className="img-wrapper-blue">
                        <div className="pic-26-2"></div>
                      </div>
                      <div className="text-f9">
                        <div style={{ display: "flex", width: "100%" }}>
                          <div
                            style={{
                              width: "70%",
                              color: "#080626",
                              fontFamily: "Raleway-Variable",
                              fontSize: 18,
                              fontWeight: 700,
                            }}
                          >
                            Du nouveau chez Frank
                            <p
                              style={{
                                fontFamily: "Raleway-Italic",
                                fontSize: "14px",
                                color: "#000",
                                fontWeight: "400",
                                margin: 0,
                              }}
                            >
                              il y a 10 heures
                            </p>{" "}
                          </div>
                          <div style={{ width: "30%", textAlign: "right" }}>
                            <East
                              style={{
                                background: "#fff",
                                borderRadius: 100,
                                color: defaultLabelColors.main_blue,
                                padding: 2,
                                fontSize: "16px",
                              }}
                            />{" "}
                          </div>
                        </div>
                        <span
                          className="lorem-ipsum-dolor-fc"
                          style={{ marginBottom: "10px" }}
                        >
                          Lorem ipsum dolor sit amet consectetur. Sapien
                          pharetra adipiscing odio et bibendum duis. Auctor
                          scelerisque tellus tortor euismod...
                        </span>
                      </div>
                    </div>
                  </Grid>
                  <Grid xs={12} item>
                    <div className="actu">
                      <div className="img-wrapper-blue">
                        <div className="pic-26-3"></div>
                      </div>
                      <div className="text-f9">
                        <div style={{ display: "flex", width: "100%" }}>
                          <div
                            style={{
                              width: "70%",
                              color: "#080626",
                              fontFamily: "Raleway-Variable",
                              fontSize: 18,
                              fontWeight: 700,
                            }}
                          >
                            Du nouveau chez Frank
                            <p
                              style={{
                                fontFamily: "Raleway-Italic",
                                fontSize: "14px",
                                color: "#000",
                                fontWeight: "400",
                                margin: 0,
                              }}
                            >
                              il y a 10 heures
                            </p>{" "}
                          </div>
                          <div style={{ width: "30%", textAlign: "right" }}>
                            <East
                              style={{
                                background: "#fff",
                                borderRadius: 100,
                                color: defaultLabelColors.main_blue,
                                padding: 2,
                                fontSize: "16px",
                              }}
                            />{" "}
                          </div>
                        </div>
                        <span
                          className="lorem-ipsum-dolor-fc"
                          style={{ marginBottom: "10px" }}
                        >
                          Lorem ipsum dolor sit amet consectetur. Sapien
                          pharetra adipiscing odio et bibendum duis. Auctor
                          scelerisque tellus tortor euismod...
                        </span>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "Raleway-Italic",
                  fontSize: "22px",
                }}
              >
                VOIR TOUS LES ARTICLES <East />
              </div>
            </div>

            <div className="display-flex" style={{}}>
              <Grid container spacing={2} marginTop={5} style={{ padding: 10 }}>
                <Grid
                  xs={12}
                  item
                  className="display-flex"
                  style={{
                    flexDirection: "column",
                    background: "#F5C9B3",
                    padding: "10px",
                    borderRadius: "18px",
                    width: "80%",
                    paddingLeft: "10px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "36px",
                      fontWeight: "900",
                      fontFamily: "Raleway-Italic",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      textAlign: "left",
                      width: "100%",
                      paddingLeft: "48px",
                      padding: "15px",
                    }}
                  >
                    {" "}
                    En savoir plus sur l'envoi de colis entre particuliers
                  </div>

                  <Accordion
                    elevation={0}
                    style={{
                      border: "0px",
                      background: "#F5C9B3",
                    }}
                  >
                    <AccordionSummary
                      style={{ border: "none", width: "100%" }}
                      expandIcon={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                            fontSize: "22px",
                            fontFamily: "Raleway-Variable",
                          }}
                        >
                          {" "}
                          <ExpandMore style={{ textAlign: "right" }} />{" "}
                        </div>
                      }
                      className="span-what-is-frank_mobile"
                    >
                      <h3 style={{ fontWeight: "500" }}>
                        Quels types de colis puis-je envoyer avec Frank?
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{
                        width: "90%",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      <p>
                        Particuliers ou professionnels, vous pouvez expédier
                        toutes sortes de colis via notre plateforme :
                      </p>
                      <p>
                        1. Des petits paquets : cadeaux, vêtements, livres, etc.
                        ;
                      </p>
                      <p>
                        2. Des colis moyens : appareils électroménagers, meubles
                        en kit, vélos, matériel de sport, etc. ;
                      </p>
                      <p>
                        3. Des envois volumineux : cartons de déménagement, gros
                        électroménager, meubles, etc. ;
                      </p>
                      <p>
                        Il n'y a pas de limite de poids. Tant qu'un particulier
                        accepte de transporter votre colis, chez Frank c'est
                        possible !
                      </p>
                      <p>
                        Avec Frank, vous pouvez même organiser votre
                        déménagement !
                      </p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    elevation={0}
                    style={{
                      border: "0px",
                      background: "#F5C9B3",
                      width: "100%",
                    }}
                  >
                    <AccordionSummary
                      style={{ border: "none", width: "100%" }}
                      expandIcon={
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                            fontSize: "28px",
                            fontFamily: "Raleway-Variable",
                          }}
                        >
                          {" "}
                          <ExpandMore style={{ textAlign: "right" }} />{" "}
                        </div>
                      }
                      aria-controls="panel1-content"
                      id="panel1-header"
                      className="span-what-is-frank_mobile"
                    >
                      <h3 style={{ fontWeight: "500" }}>
                        Où faut-il déposer mon colis?
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails style={{ width: "90%" }}>
                      <p>
                        Vous convenez d'un point de rendez-vous avec le
                        particulier qui assurera le transport de colis. Cela
                        peut être :{" "}
                      </p>

                      <p>• À votre domicile ;</p>
                      <p>• Sur son lieu de départ (gare, aéroport, etc.).</p>
                      <p>
                        L'application vous permet de choisir le lieu le plus
                        pratique et d'échanger avec le transporteur pour
                        organiser la remise du colis en toute simplicité.
                      </p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    elevation={0}
                    style={{
                      border: "0px",
                      background: "#F5C9B3",
                      width: "100%",
                    }}
                  >
                    <AccordionSummary
                      style={{ border: "none", width: "100%" }}
                      expandIcon={
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                            fontSize: "28px",
                            fontFamily: "Raleway-Variable",
                          }}
                        >
                          {" "}
                          <ExpandMore style={{ textAlign: "right" }} />{" "}
                        </div>
                      }
                      aria-controls="panel1-content"
                      id="panel1-header"
                      className="span-what-is-frank_mobile"
                    >
                      <h3 style={{ fontWeight: "500" }}>
                        H3 Existe-t-il un poids maximum pour l'envoi d'un colis
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails style={{ width: "90%" }}>
                      <p>
                        Non, il n'y a pas de limite de poids chez Frank,
                        contrairement à de nombreux transporteurs. Notre réseau
                        de particuliers nous permet d'acheminer même les colis
                        les plus lourds et encombrants.
                      </p>
                      <p>
                        C'est idéal pour les déménagements par exemple. Vous
                        pouvez indiquer le volume et le nombre de personnes
                        requises, et constituer une équipe de Frankies pour vous
                        aider !
                      </p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    elevation={0}
                    style={{
                      border: "0px",
                      background: "#F5C9B3",
                      width: "100%",
                    }}
                  >
                    <AccordionSummary
                      style={{ border: "none", width: "100%" }}
                      expandIcon={
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                            fontSize: "28px",
                            fontFamily: "Raleway-Variable",
                          }}
                        >
                          {" "}
                          <ExpandMore style={{ textAlign: "right" }} />{" "}
                        </div>
                      }
                      aria-controls="panel1-content"
                      id="panel1-header"
                      className="span-what-is-frank_mobile"
                    >
                      <h3 style={{ fontWeight: "500" }}>
                        Puis-je envoyer plusieurs colis en même temps ?
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails style={{ width: "90%" }}>
                      <p>
                        Oui, avec Frank vous pouvez grouper vos expéditions sans
                        problème. Au moment de publier votre annonce, précisez
                        simplement le nombre de colis à envoyer en appuyant sur
                        le “ + “. Notre système adapté fera en sorte de trouver
                        le transporteur adéquat, qu'il s'agisse de quelques
                        paquets ou d'une cargaison complète.
                      </p>
                      <p>
                        Vous pouvez aussi envoyer plusieurs colis à plusieurs
                        destinations différentes. Dans ce cas, il faudra publier
                        une annonce par colis.
                      </p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    elevation={0}
                    style={{
                      border: "0px",
                      background: "#F5C9B3",
                      width: "100%",
                    }}
                  >
                    <AccordionSummary
                      style={{ border: "none", width: "100%" }}
                      expandIcon={
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                            fontSize: "28px",
                            fontFamily: "Raleway-Variable",
                          }}
                        >
                          {" "}
                          <ExpandMore style={{ textAlign: "right" }} />{" "}
                        </div>
                      }
                      aria-controls="panel1-content"
                      id="panel1-header"
                      className="span-what-is-frank_mobile"
                    >
                      <h3 style={{ fontWeight: "500" }}>
                        Quelle assurance est disponible pour mon colis ?
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails style={{ width: "90%" }}>
                      <p>
                        Chez Frank,
                        <span style={{ fontWeight: 600 }}>
                          {" "}
                          la sécurité de vos colis est notre priorité.
                        </span>
                        C'est pourquoi chaque envoi réalisé via notre service de
                        livraison collaborative est automatiquement assuré à
                        hauteur de 100€, sans frais supplémentaires.
                      </p>
                      <p>
                        Mais si votre colis a une valeur supérieure, pas de
                        panique ! Grâce à notre partenariat avec un assureur
                        indépendant, vous pouvez souscrire à une
                        <span style={{ fontWeight: 600 }}>
                          {" "}
                          garantie renforcée{" "}
                        </span>{" "}
                        qui couvrira intégralement votre bien en cas de vol,
                        casse ou incident pendant le transport.
                      </p>
                      <p>
                        Par ailleurs, notre plateforme collaborative nous permet
                        de sélectionner des livreurs de confiance.
                      </p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    elevation={0}
                    style={{
                      border: "0px",
                      background: "#F5C9B3",
                      width: "100%",
                      padding: "10px 0px",
                    }}
                  >
                    <AccordionSummary
                      style={{ border: "none", width: "100%" }}
                      expandIcon={
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                            fontSize: "28px",
                            fontFamily: "Raleway-Variable",
                          }}
                        >
                          {" "}
                          <ExpandMore style={{ textAlign: "right" }} />{" "}
                        </div>
                      }
                      aria-controls="panel1-content"
                      id="panel1-header"
                      className="span-what-is-frank_mobile css-yw020d-MuiAccordionSummary-expandIconWrapper_mobile"
                    >
                      <h3 style={{ fontWeight: "500", padding: "5px 0px" }}>
                        Comment est fixé le prix pour l'envoi de mon colis ?
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails style={{ width: "90%" }}>
                      <p>
                        Chez Frank, l’expéditeur peut indiquer le tarif qu’il
                        souhaite pour sa demande de livraison. Le prix dépendra
                        du poids et des dimensions du colis, mais aussi de la
                        distance à parcourir. De leur côté, les transporteurs
                        peuvent être amenés à négocier le prix. Une fois que
                        vous avez renseigné les principales informations
                        concernant votre colis, Frank vous conseille une
                        fourchette de prix que vous pouvez suivre.{" "}
                      </p>
                      <p>
                        Nous optimisons chaque trajet pour vous garantir le
                        meilleur rapport qualité-prix, jusqu'à 50 % moins cher
                        que les transporteurs classiques. Avec notre calculateur
                        en ligne, estimez le coût de votre expédition en
                        quelques clics !
                      </p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    elevation={0}
                    style={{
                      border: "0px",
                      background: "#F5C9B3",
                      width: "100%",
                    }}
                  >
                    <AccordionSummary
                      style={{ border: "none", width: "100%" }}
                      expandIcon={
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                            fontSize: "28px",
                            fontFamily: "Raleway-Variable",
                          }}
                        >
                          {" "}
                          <ExpandMore style={{ textAlign: "right" }} />{" "}
                        </div>
                      }
                      aria-controls="panel1-content"
                      id="panel1-header"
                      className="span-what-is-frank_mobile"
                    >
                      <h3 style={{ fontWeight: "500" }}>
                        Quelles sont les zones de livraisons possibles pour
                        l'envoi de mon colis ?{" "}
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails style={{ width: "90%" }}>
                      <p>
                        Envoyez vos colis partout en France métropolitaine, de
                        la grande ville au petit village. Grâce à notre vaste
                        communauté de transporteurs particuliers, nous couvrons
                        même les zones rurales habituellement mal desservies.
                        Que ce soit pour un trajet local, régional ou national,
                        il y aura toujours un Franky prêt à livrer votre colis à
                        bon port. Élargissez vos horizons sans vous ruiner !
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </div>
          </div>

          <div style={{ padding: "10px 20px 10px 20px" }}>
            <div
              className="triangle"
              style={{ height: "100vh", position: "relative" }}
            >
              <div
                style={{
                  paddingTop: 20,
                  rowGap: 20,
                  display: "flex",
                  flexDirection: "column",
                  zIndex: 111,
                }}
              >
                <FrankIcon fill={"#fff"} height={70} />

                <p
                  className="heading-two"
                  style={{
                    fontSize: "28px",
                    textAlign: "center",
                    color: "#fff",
                    padding: "0px 10px 0px 10px",
                  }}
                >
                  <span style={{ fontSize: "24px", fontWeight: "500" }}>
                    Télécharger
                  </span>
                  <br />
                  notre application mobile
                </p>

                <div
                  className="display-flex"
                  style={{ flexDirection: "column", gap: "10px" }}
                >
                  <a
                    href="https://apps.apple.com/app/frank/id6502341979"
                    target="_blank"
                    rel="noopener noreferrer"

                  >
                    <AppStoreFR height={60} />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.frankc2c"
                    target="_blank"
                    rel="noopener noreferrer"

                    style={{ textAlign: "center", width: "190px" }}
                  >
                    {" "}
                    <img
                      src={playSore}
                      alt="google-play"
                      height={56}
                      width={"65px"}
                    />
                  </a>
                </div>
              </div>

              <img
                src={downloadappStoreBanner}
                style={{ position: "absolute", bottom: 0 }}
                alt="download-banner"
              />
            </div>
          </div>
          <div
            className="footer-new"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <div className="contain" style={{ padding: "40px 0px 0px 0px" }}>
              <div className="col">
                <p>
                  {" "}
                  <FrankIcon fill={"#fff"} height={80} />
                </p>
              </div>
              <div className="col">
                <span style={{ padding: 0,fontSize:'14px',fontWeight:'700',color:'#fff',fontFamily:'Raleway-Italic' }}>Particulier</span >
                <ul>
                  <li>Envoyer un colis</li>
                  <li>Transporteur</li>
                  <li>Devenir transporteur</li>

                  <li style={{ marginTop: "10px", fontWeight: "700" }}>
                    Mon compte
                  </li>
                  <li style={{ fontWeight: "700" }}>Messagerie</li>
                </ul>
              </div>
              <div className="col">
                <span style={{ padding: 0,fontSize:'14px',fontWeight:'700',color:'#fff',fontFamily:'Raleway-Italic' }}>Transporteur</span>
                <ul>
                  <li>Devenir transporteur</li>
                  <li>Voir les annonces pour</li>
                  <li> une livraison</li>
                </ul>
              </div>
              <div className="col">
                <span style={{ padding: 0,fontSize:'14px',fontWeight:'700',color:'#fff',fontFamily:'Raleway-Italic' }}>Déménagement </span >
                <ul>
                  <li>Déménageur particulier</li>
                  <li>Déménageur professionnel</li>
                </ul>
              </div>
              <div className="col">
                <span style={{ padding: 0,fontSize:'14px',fontWeight:'700',color:'#fff',fontFamily:'Raleway-Italic' }}>Marketplace</span >
                <ul></ul>
              </div>
              <div className="col">
                <span style={{ padding: 0,fontSize:'14px',fontWeight:'700',color:'#fff',fontFamily:'Raleway-Italic' }}>Stockage colis</span>
                <ul>
                  <li>Trouver un point relais particulier </li>
                  <li> Proposer un espace de point relais</li>
                  <li>Consigner bagage</li>

                  <li
                    style={{
                      marginTop: "10px",
                      fontWeight: "700",
                      fontSize: "18px",
                    }}
                  >
                    Blog
                  </li>
                  <li style={{ fontWeight: "700", fontSize: "18px" }}>FAQ</li>
                </ul>
              </div>
              <div className="col" style={{ width: "190px", height: 200 }}>
                <div
                  style={{
                    border: "1px solid #fff",
                    borderRadius: "12px",
                    marginTop: "20px",
                    padding: "20px",
                    width: "180px",
                  }}
                >
                  <span style={{ padding: 0,fontSize:'14px',fontWeight:'700',color:'#fff',fontFamily:'Raleway-Italic' }}>Nous Contacter:</span>
                  <ul>
                    <li>hello@frankme.com</li>
                    <li> 04 XX XX XX XX</li>
                    <li>91 avenue de la République 75011 Paris</li>
                  </ul>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginBottom: "70px",
                    gap: "10px",
                    marginTop: "10px",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <div>
                    <img src={facebookIcon} alt="facebook-icon" />
                  </div>
                  <div>
                    <img src={instaIcon} alt="instagram-icon" />
                  </div>
                  <div>
                    <img src={linkedInIcon} alt="linkedin-icon" />
                  </div>
                </div>
              </div>
              <div
                className="col"
                style={{
                  width: "100%",
                  // marginLeft: "170px",
                  fontFamily: "Raleway-Italic",
                  fontSize: "14px",
                  color: "#fff",
                  // marginTop: "-30px",
                  textAlign: "center",
                }}
              >
                <a
                  href="https://frankme.com/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"

                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  Politique de confidentialité
                </a>
                |
                <a
                  style={{ textDecoration: "none", color: "#fff" }}
                  href="https://frankme.com/terms-and-condition"
                  target="_blank"
                  rel="noopener noreferrer"

                >
                  Termes et conditions
                </a>
              </div>

              <div className="clearfix"></div>
            </div>
          </div>

          <React.Fragment>
            <ActionSheet
              ref={ref}
              sheetStyle={{
                backgroundColor: "#fff",
                borderTopLeftRadius: 20,
                borderTopRightRadius: 20,
                padding: 10,
              }}

              bgStyle={{
                backgroundColor: "rgba(1, 1, 1, 0.8)",
              }}
            >
              <div className="actionSheetContent">
                <div style={{ display: "flex", padding: "20px 0px" }}>
                  <div
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontWeight: "600",
                      fontSize: 22,
                      flex: 1,
                      width: "90%",
                    }}
                  >
                    Ajouter un colis
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "10%",
                    }}
                  >
                    <Close onClick={handleClose} />
                  </div>
                </div>
                <Grid container spacing={2}>
                  <Grid
                    xs={12}
                    md={12}
                    style={{
                      display: "flex",
                      cursor: "pointer",
                      flexDirection: "column",
                      marginTop: "10px",
                    }}
                    item
                  >
                    <div
                      style={{
                        background: "#F4F3F6",
                        width: "100%",
                        height: "120px",
                        border: `2px dashed ${defaultLabelColors.main_blue}`,
                        borderRadius: "32px",
                      }}
                      className="display-flex"
                      onClick={() => {
                        let { announcement_item } = props;
                        let item_obj = {
                          ...announcement_item,
                        };
                        if (item_obj?.images?.length > 5) {
                          Toast.Error("You cannot add more than 6 pictures");
                          return;
                        }
                        item_obj?.images?.push({});
                        setState({
                          announcement_item: item_obj,
                        });
                      }}
                    >
                      <label
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontFamily: "Raleway-Variable",
                          color: defaultLabelColors.main_blue,
                          fontSize: "20px",
                          fontWeight: "700",
                          flexDirection: "column",
                          cursor: "pointer",
                        }}
                      >
                        <AddImageIcon fill={defaultLabelColors.main_blue} />
                        {"Ajouter des photos"}
                      </label>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        {props?.announcement_item?.images?.map(
                          (item: any, index: number) => (
                            <div
                              key={"PRODUCTIMAGES_" + index + 1}
                              style={{ marginTop: 10 }}
                            >
                              <div
                                style={{
                                  height: 100,
                                  width: 100,
                                  borderRadius: 16,
                                  backgroundColor: "white",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  padding: 10,
                                  flexWrap: "wrap",
                                  display: "flex",
                                }}
                              >
                                {item?.uri || typeof item === "string" ? (
                                  <>
                                    <label
                                      style={{ position: "relative" }}
                                      htmlFor={`product_image_back_${
                                        index + 1
                                      }`}
                                    >
                                      <img
                                        src={item?.uri ?? item ?? ""}
                                        alt="img"
                                        style={{
                                          height: "110px",
                                          width: "100%",
                                          borderRadius: 16,
                                          objectFit: "contain",
                                        }}
                                      />
                                      <Close
                                        style={{
                                          position: "absolute",
                                          cursor: "pointer",
                                          backgroundColor: "grey",
                                          borderRadius: "50%",
                                          color: "#fff", // Change the color if you want
                                          height: "20px",
                                          width: "20px",
                                          zIndex: 111111,
                                        }}
                                        onClick={() =>
                                          props?.handleRemoveImage(index)
                                        }
                                      />
                                    </label>
                                    <input
                                      id={`product_image_back_${index + 1}`}
                                      type="file"
                                      accept=".jpg, .jpeg, .png"
                                      className={"inputfile"}
                                      onChange={(e) =>
                                        props?.handleLaunchGallery2(
                                          "images",
                                          index,
                                          e
                                        )
                                      }
                                    />
                                  </>
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <label
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                      htmlFor={`product_image_back_${
                                        index + 1
                                      }`}
                                    >
                                      <AddPhotoAlternateOutlined
                                        style={{
                                          color: defaultLabelColors.main_blue,
                                          cursor: "pointer",
                                        }}
                                      />
                                    </label>
                                    <input
                                      id={`product_image_back_${index + 1}`}
                                      type="file"
                                      className={"inputfile"}
                                      onChange={(e) => {
                                        props?.handleLaunchGallery2(
                                          "images",
                                          index,
                                          e
                                        );
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </Grid>

                  <Grid xs={12} item>
                    <div
                      style={{
                        background: "#F4F3F6",
                        padding: "20px",
                        borderRadius: 16,
                        width: "100%",
                      }}
                    >
                      <ParcelSizeSelect
                        onChange={props?.handleShipmentTypeChange}
                        selected_value={props.selected_value}
                      />
                    </div>
                  </Grid>

                  <Grid xs={12} item style={{ display: "flex" }}>
                    <div
                      style={{
                        padding: "10px",
                        borderRadius: 16,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <p
                        style={{
                          margin: 0,
                          fontFamily: "Raleway-Variable",
                          fontWeight: "600",
                          fontSize: "18px",
                        }}
                      >
                        A propos du colis
                      </p>
                      <Grid
                        container
                        style={{ marginTop: "10px", marginLeft: 5 }}
                      >
                        {SHIPMENT_TYPE?.map((i: any) => {
                          return (
                            <>
                              <Grid
                                xs={5.5}
                                item
                                onClick={() => {
                                  props.setSelectedValue(i?.size);
                                }}
                                style={{
                                  borderRadius: "24px",
                                  background: "#fff",
                                  flexDirection: "column",
                                  margin: 5,
                                  padding: 15,
                                  fontFamily: "Raleway-Variable",
                                  cursor: "pointer",
                                  border: `2px solid ${
                                    props.selectedValue === i?.size
                                      ? defaultLabelColors.main_blue
                                      : "grey"
                                  }`,
                                }}
                              >
                                <div
                                  style={{
                                    fontWeight: "800",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <div style={{ flex: 1 }}>{i?.size}</div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <Radio
                                      checked={props?.selectedValue === i?.size}
                                      onChange={handleChange}
                                      value={i?.size}
                                      name="type"
                                      style={{ color: "#000" }}
                                    />
                                  </div>
                                </div>
                                <div
                                  style={{
                                    fontWeight: "700",
                                    fontSize: "14px",
                                    marginTop: "10px",
                                  }}
                                >
                                  {i?.weight_fr}
                                  <span style={{ fontWeight: "500" }}>
                                    : Entre une enveloppe et une boite à
                                    chaussure
                                  </span>
                                </div>
                              </Grid>
                            </>
                          );
                        })}
                      </Grid>
                    </div>
                  </Grid>
                  <Grid xs={12} item>
                    <div
                      style={{
                        background: "#F4F3F6",
                        padding: "20px",
                        borderRadius: 16,
                      }}
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              onClick={() => {
                                setState({
                                  isDimestions: !props?.isDimestions,
                                });
                              }}
                              sx={{
                                "& .MuiSwitch-switchBase.Mui-checked": {
                                  color: "#ee6931",
                                },
                                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                                  {
                                    backgroundColor: "#ee6931",
                                  },
                              }}
                            />
                          }
                          label="Je connais les dimensions"
                          sx={{
                            ".MuiFormControlLabel-label": {
                              fontFamily: "Raleway-Variable",
                              fontSize: 16,
                              fontWeight: 600,
                            },
                          }}
                        />
                      </FormGroup>
                      {state?.isDimestions ? (
                        <Grid
                          container
                          spacing={2}
                          style={{ display: "flex", paddingTop: 10 }}
                        >
                          <Grid xs={4} item>
                            <span
                              style={{
                                margin: 0,
                                paddingLeft: 10,
                                fontFamily: "Raleway-Variable",
                                fontWeight: "600",
                                fontSize: "16px",
                              }}
                            >
                              {strings?.length}
                            </span>
                            <InputField
                              input_type={"number"}
                              input_value={props?.prod_length ?? ""}
                              endIicon={
                                <span style={{ fontFamily: "Raleway-Italic" }}>
                                  cm
                                </span>
                              }
                              onchange={props?.onInputChange}
                              input_name={"prod_length"}
                              input_style={{
                                background: "#fff",
                                width: "100%",
                                borderRadius: "16px",
                                border: "none",
                              }}
                            />
                          </Grid>
                          <Grid xs={4} item>
                            <span
                              style={{
                                margin: 0,
                                paddingLeft: 10,
                                fontFamily: "Raleway-Variable",
                                fontWeight: "600",
                                fontSize: "16px",
                              }}
                            >
                              {strings?.width}
                            </span>
                            <InputField
                              input_type={"number"}
                              input_value={props?.prod_width ?? ""}
                              endIicon={
                                <span style={{ fontFamily: "Raleway-Italic" }}>
                                  cm
                                </span>
                              }
                              onchange={props?.onInputChange}
                              input_name={"prod_width"}
                              input_style={{
                                background: "#fff",
                                width: "100%",
                                borderRadius: "16px",
                                border: "none",
                              }}
                            />
                          </Grid>
                          <Grid xs={4} item>
                            <span
                              style={{
                                margin: 0,
                                paddingLeft: 10,
                                fontFamily: "Raleway-Variable",
                                fontWeight: "600",
                                fontSize: "16px",
                              }}
                            >
                              {strings?.height}
                            </span>
                            <InputField
                              input_type={"number"}
                              input_value={props?.prod_height ?? ""}
                              endIicon={
                                <span style={{ fontFamily: "Raleway-Italic" }}>
                                  cm
                                </span>
                              }
                              onchange={props?.onInputChange}
                              input_name={"prod_height"}
                              input_style={{
                                background: "#fff",
                                width: "100%",
                                borderRadius: "16px",
                                border: "none",
                                fontSize: "16px",
                              }}
                            />
                          </Grid>
                          <br />
                          <Grid xs={12} item>
                            <div
                              style={{
                                borderRadius: 16,
                              }}
                            >
                              <InputField
                                placeHolder={"Information complémentaire"}
                                isMultiLine={true}
                                input_rows={1}
                                input_type={"text"}
                                input_value={props?.additional_info ?? ""}
                                onchange={props?.onInputChange}
                                input_name={"additional_info"}
                                input_style={{
                                  background: "#fff",
                                  width: "100%",
                                  borderRadius: "16px",
                                  border: "none",
                                  fontSize: "16px",
                                  float: "left",
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      ) : null}
                    </div>
                  </Grid>

                  <Grid xs={12} item>
                    <div
                      style={{
                        background: "#F4F3F6",
                        padding: "20px",
                        borderRadius: 16,
                      }}
                    >
                      <FormGroup>
                        <FormControlLabel
                          style={{
                            fontFamily: "Raleway-Variable",
                            fontSize: 22,
                          }}
                          control={
                            <Switch
                              onClick={() => {
                                setState({
                                  isFragile: !props?.isFragile,
                                });
                              }}
                              sx={{
                                "& .MuiSwitch-switchBase.Mui-checked": {
                                  color: "#ee6931",
                                },
                                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                                  {
                                    backgroundColor: "#ee6931",
                                  },
                              }}
                            />
                          }
                          sx={{
                            ".MuiFormControlLabel-label": {
                              fontFamily: "Raleway-Variable",
                              fontSize: 16,
                              fontWeight: 600,
                            },
                          }}
                          label={strings?.fragile}
                        />
                      </FormGroup>
                    </div>
                  </Grid>

                  <Grid xs={12} item>
                    <Box
                      sx={{
                        background: "#F4F3F6",
                        padding: "20px",
                        borderRadius: "16px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "75%",
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontWeight: "bold",
                            fontFamily: "Raleway-Variable",
                            flex: 1,
                            fontSize: "14px",
                            textAlign: "center",
                          }}
                        >
                          Combien de colis de cette dimension souhaitez vous
                          envoyer:
                        </Typography>
                      </div>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "50%",
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            props?.handleIncrmntDecrmnt("decrement");
                          }}
                          sx={{
                            border: "1px solid #e0e0e0",
                            borderRadius: "8px",
                            width: "40px",
                            height: "40px",
                            backgroundColor: "#fff",
                          }}
                        >
                          <Remove />
                        </IconButton>
                        <Typography
                          variant="h6"
                          sx={{
                            // margin: "0 15px",
                            minWidth: "20px",
                            textAlign: "center",
                          }}
                        >
                          {props?.item_quantity ?? 1}
                        </Typography>
                        <IconButton
                          onClick={() => {
                            props?.handleIncrmntDecrmnt("increament");
                          }}
                          sx={{
                            border: "1px solid #e0e0e0",
                            borderRadius: "8px",
                            width: "40px",
                            height: "40px",
                            backgroundColor: "#fff",
                          }}
                        >
                          <Add />
                        </IconButton>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      padding: "20px",
                      gap: "10px",
                    }}
                  >
                    <CustomButton
                      classesNames={"outlined_button"}
                      value={"Annuler"}
                      style={{ width: "200px" }}
                      onClick={handleClose}
                    />

                    {props?.isUpdate ? (
                      <CustomButton
                        classesNames={"contained_button"}
                        value={"Update"}
                        style={{ width: "200px" }}
                        onClick={props?.handleUpdateShipment}
                      />
                    ) : (
                      <CustomButton
                        classesNames={"contained_button"}
                        value={"Ajouter"}
                        style={{ width: "200px" }}
                        onClick={handleNext}
                      />
                    )}
                  </div>
                </div>
              </div>
            </ActionSheet>
          </React.Fragment>
        </>
      ) : props?.step === 2 ? (
        <PickupInfo
          handleUpadte={props?._handleUpdateState}
          handleUpadateState={props?.handleUpdateState}
          {...state}
          {...props}
        />
      ) : props?.step === 3 ? (
        <DropOffInfo
          handleUpadte={props?._handleUpdateState}
          handleUpadateState={props?.handleUpdateState}
          {...state}
          {...props}
        />
      ) : props?.step === 4 ? (
        <div style={{ padding: "0px 20px" }}>
          <ReviewPackage
            handleUpadte={props?._handleUpdateState}
            handleEmptyShipment={props?.handleEmptyShipment}
            handleUpadateState={props?.handleUpdateState}
            {...state}
            {...props}
          />
        </div>
      ) : null}

      {/* <React.Fragment>
        <ActionSheet
          ref={refTwo}
          sheetStyle={{
            backgroundColor: "#fff",
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            padding: 10,
          }}
          bgStyle={{
            backgroundColor: "rgba(1, 1, 1, 0.8)",
          }}
        >
          <div className="actionSheetContent">
            <Grid xs={12} item>
              <AuthAction
                {...state}
                {...props}
                handleCloseLoginPage={props?.handleCloseLoginPage}
              />
            </Grid>
          </div>
        </ActionSheet>
      </React.Fragment> */}
    </div>
  );
}

export default SendPackageMobileView;

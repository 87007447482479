import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import craneImage from "../../assets/images/FrankIcons/loader_vehicle.png";
import cranceImage from "../../assets/images/FrankIcons/orange-blue-triangles@4x.png";
import "./index.css";

import {
  East,
  ExpandMore,
  KeyboardArrowDown,
  LocationOn,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/system";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import "react-awesome-slider/dist/styles.css";
import { Helmet } from "react-helmet";
import "react-multi-carousel/lib/styles.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import iconThree from "../../assets/images/FrankIcons/assuree@4x.png";
import iconOne from "../../assets/images/FrankIcons/ecologique@4x.png";
import iconTwo from "../../assets/images/FrankIcons/economique@4x.png";
import homeSliderThree from "../../assets/images/FrankIcons/homeSliderImagethree.jpg";
import StepScreen1 from "../../assets/images/FrankIcons/StepScreen1.png";
import StepScreen2 from "../../assets/images/FrankIcons/StepScreen2.png";
import StepScreen3 from "../../assets/images/FrankIcons/StepScreen3.png";
import StepScreen3Coin from "../../assets/images/FrankIcons/StepScreen3Coin.png";
import apiServices from "../../services/RequestHandler";
import { RootState } from "../../Store";
import CustomButton from "../../UiComponent/CustomButton";
import CustomFooter from "../../UiComponent/CustomFooter";
import Loader from "../../UiComponent/Loader";
import NewHeader from "../../UiComponent/NewHeader";
import {
  color_theme,
  defaultLabelColors,
  no_image_url,
  spacing,
} from "../../utils/constants";
import {
  handleLocalize,
  numberFormatWithoutCurrency,
  throttle,
} from "../../utils/helper";
import {
  getDataFromLocalStorage,
  saveDataToLocalStorage,
} from "../../utils/LocalStore.util";
import {
  getDeviceToken,
  requestPermission,
} from "../../utils/notificationListner";
import MobileView from "../MobileView";

function LandingPage(props: any) {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const xl = useMediaQuery(theme.breakpoints.up("xl"));
  const imgRef = useRef<any>();

  // const [divHeight, setDivHeight] = useState(323); // Default height

  // useEffect(() => {
  //   const updateHeight = () => {
  //     if (imgRef.current) {
  //       const imgHeight = imgRef.current.offsetHeight; // Get image height
  //       setDivHeight(imgHeight - 38); // Subtract 240px
  //     }
  //   };

  //   // Update height on mount
  //   updateHeight();

  //   // Optional: Update height on window resize
  //   window.addEventListener("resize", updateHeight);
  //   return () => window.removeEventListener("resize", updateHeight);
  // }, []);

  // const isCustomRange = useMediaQuery(
  //   "(min-width: 1200px) and (max-width: 1400px)"
  // );

  const [scrollPercentage, setScrollPercentage] = React.useState(0);
  const slides = [
    // comment by asad iqbal 1/14/2025 on Ben Demand ( Launching Frank C2C ppt)
    // {
    //   imageUrl: homeSlider,
    //   caption: "Vendez ",
    //   captionTwo: "sur la Marketplace",
    // },
    // {
    //   imageUrl: homeSliderTwo,
    //   caption: "Pour les",
    //   captionTwo: "professionnels",
    // },
    //comment by asad iqbal 1/14/2025 on Ben Demand ( Launching Frank C2C ppt)
    {
      imageUrl: homeSliderThree,
      // caption: `Déménagez `,
      // captionTwo: "avec Frank",

      caption: "Livraison collaborative",
      captionTwo: "",
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);
  const changeSlide = (index: any) => {
    setCurrentSlide(index);
  };

  const navigate = useNavigate();
  const selector = useSelector((state: RootState) => state.mainApp);
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      loadingLoader: false,
      randomProducts: [],
    }
  );

  useEffect(() => {
    handleUpdateLanguage();
    handleGetRecentJobs();
    getRandomProducts();
  }, []);
  const handleOpenProduct = (item: any) => {
    try {
      if (item?.is_particular === true) {
        navigate(`/p-product/${item?.id}`);
        return;
      }

      navigate(`/product/${item?.id}`);
    } catch (error) {}
  };
  const getRandomProducts = async () => {
    try {
      const resp = await apiServices.getFromApi(
        "/c2c-product-masters/products-random",
        ""
      );

      setState({
        randomProducts: resp?.data ?? [],
      });
      handleGetAllBlog();
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetAllBlog = async () => {
    try {
      setState({ activity: true });
      let resp = await apiServices.getFromApi(
        `c2c-blogs/web?page=1&limit=6`,
        ""
      );
      if (resp?.status === 200 || resp?.status === 201) {
        setState({ allBlogs: resp?.data, totalPage: resp?.total_pages });
        setState({ activity: false });
      }
    } catch (error) {
      setState({ activity: false });
    }
  };

  const handleUpdateLanguage = async () => {
    try {
      const user = getDataFromLocalStorage("user");
      if (user) {
        await apiServices.postFromApi("/c2c/u-lang", { lang: "fr" }, "");
      }
    } catch (error) {}
  };

  // useEffect(() => {}, [selector?.lang]);

  useEffect(() => {
    const handleScroll = throttle(() => {
      const totalHeight =
        document.documentElement.scrollHeight - window.innerHeight;
      const currentScroll = window.scrollY;
      setScrollPercentage((currentScroll / totalHeight) * 100);
    }, 100); // Throttle the scroll event every 100ms

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleGetRecentJobs = async () => {
    try {
      setState({ loadingLoader: true });
      let user = getDataFromLocalStorage("user");

      const body = { type: "All", limit: false, user: user?.id ?? null };
      let resp = await apiServices.postFromApi(
        "c2c-shipments/web-all-announcements",
        body,
        ""
      );
      const slicedData = resp?.data?.slice(0, xl ? 12 : lg ? 8 : md ? 4 : 3);

      setState({
        annoucesData: slicedData,
        loadingLoader: false,
      });
    } catch (error) {}
  };

  useEffect(() => {
    const slicedData = state.annoucesData?.slice(
      0,
      xl ? 12 : lg ? 8 : md ? 4 : 3
    );

    setState({
      annoucesData: slicedData,
      loadingLoader: false,
    });
  }, [xl, lg, md, sm]);

  let settings = {
    infinite: true,
    slidesToShow: xl ? 5 : lg ? 4 : md ? 3 : sm ? 1 : 1,
    initialSlide: xl ? 5 : lg ? 4 : md ? 3 : sm ? 1 : 1,
    slidesToScroll: xl ? 5 : lg ? 4 : md ? 3 : sm ? 1 : 1,
    dots: false,
    speed: 500,
    gap: "15px",

    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 1,
    //       infinite: true,
    //       dots: false,
    //     },
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //       initialSlide: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    // ],
  };

  const handleNext = () => {
    setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  };

  useEffect(() => {
    const slideInterval = setInterval(() => {
      handleNext();
    }, 4000);

    return () => clearInterval(slideInterval);
  }, [currentSlide]);

  useEffect(() => {
    let user = getDataFromLocalStorage("user");

    if (user) {
      checkForPermission();
    }
  }, []);

  const checkForPermission = async () => {
    try {
      let user = getDataFromLocalStorage("user");
      const isNotifAvailable = "Notification" in window;
      if (isNotifAvailable) {
        const permissionGranted = await requestPermission();
        if (permissionGranted) {
          const token = await getDeviceToken();

          if (token && user?.id) {
            // Only proceed with the API call if a token was successfully retrieved

            await apiServices.postFromApi(
              "c2c/u-token",
              {
                device_token: token,
              },
              ""
            );
            console.log("Token saved successfully.");
          } else if (!token) {
            console.warn("Failed to retrieve device token.");
          }
        } else {
          console.log(
            "Notification permission denied. Please enable notifications in your browser settings to receive updates."
          );
        }
      } else {
        console.log("NOTIFICATION NOT SUPPORTED");
      }
    } catch (error) {
      console.error(
        "An error occurred while checking notification permission:",
        error
      );
    }
  };

  const handleVerticalTabChange = () => {
    // setVerticalTabValue(newValue);
    const saved_tab = getDataFromLocalStorage("tab_state");
    saveDataToLocalStorage("tab_state", {
      hor_tab: 1,
      ver_tab: 1,
    });
  };
  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          height: "5px",
          width: `${scrollPercentage}%`,
          background: "#ee6931",
          zIndex: 111111111,
          transition: "width 0.2s ease",
          borderRadius: "5px",
          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
        }}
      />
      <Helmet>
        <title>Frank : Service de livraison collaborative</title>
        <link rel="canonical" href="https://www.v2internal.frankme.com" />
        <meta
          name="description"
          content="Envoyez vos colis à peu de frais ou gagnez de l’argent en transportant ceux des autres grâce à notre plateforme de livraison collaborative . H1 Envoyez vos colis grâce à vos voisins !"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Frank",
            contactPoint: {
              "@type": "ContactPoint",
              telephone: "+33-1-2345-6789",
              contactType: "Customer Service",
              areaServed: "FR",
              availableLanguage: "French",
            },
          })}
        </script>
      </Helmet>
      <div className="web-screen">
        <Loader loading={state?.loadingLoader} />
        <Grid
          container
          spacing={2}
          className="display-flex"
          style={{
            background: "#F4C9B3",
            paddingLeft: "40px",
            paddingRight: "40px",
            paddingTop: "30px",
          }}
        >
          <Grid xs={12} item style={{ zIndex: 99 }}>
            <NewHeader />
            {/* <button onClick={()=>requestPermission()}>Enable Notifications</button> */}
          </Grid>
        </Grid>
        <div className="main-container">
          <div className="content">
            <div
              className="top-section"
              style={{
                display: "flex",
                paddingTop: "20px",
                position: "relative",
              }}
            >
              <Grid container spacing={2} className="display-flex">
                <div
                  style={{
                    width: "80%",
                    height: "85vh",
                    display: "flex",
                    marginTop: 50,
                    // paddingBottom: 75,
                  }}
                >
                  <Grid container spacing={5}>
                    <Grid xs={12} md={7} item>
                      <div className="frame-f">
                        <h1
                          className="envoyez-vos-colis"
                          style={{ fontFamily: "Raleway-Variable", margin: 0 }}
                        >
                          Envoyez vos colis
                          <br />
                          entre particuliers
                        </h1>
                        <div className="frame-10">
                          <div className="frame-11">
                            <div
                              className="frame-12"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                navigate("/send-package");
                              }}
                            >
                              <East
                                style={{
                                  background: "#fff",
                                  borderRadius: 100,
                                  color: defaultLabelColors.main_blue,
                                  height: 30,
                                  width: 30,
                                  padding: 4,
                                }}
                              />

                              <div
                                className="frame-15"
                                style={{ cursor: "pointer" }}
                              >
                                {/* {strings.sender} */}
                                <h3
                                  style={{ margin: 0 }}
                                  className="transporter-un-colis"
                                >
                                  Envoyer un
                                  <br />
                                  colis
                                </h3>
                                <span className="et-nisi-leo">
                                  Nos transporteurs prennent en charge vos colis
                                  pour un envoi économique.
                                </span>
                              </div>
                            </div>
                            <div
                              className="frame-16"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                navigate("/transporter-un-colis");
                              }}
                            >
                              <East
                                style={{
                                  background: "#fff",
                                  borderRadius: 100,
                                  color: defaultLabelColors.main_orange,
                                  height: 30,
                                  width: 30,
                                  padding: 4,
                                }}
                              />
                              <div className="frame-1a">
                                <h3
                                  style={{ margin: 0 }}
                                  className="transporter-un-colis"
                                >
                                  Transporter
                                  <br />
                                  un colis
                                </h3>
                                <span className="et-nisi-leo-1b">
                                  Devenez transporteur et gagnez de l'argent en
                                  assurant des livarisons sur vos trajets.
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="frame-1c">
                            <span className="je-demenage">Je déménage</span>
                            <div className="cest-par-ici">
                              <span
                                className="cest-par-ici-1d"
                                style={{ cursor: "pointer" }}
                              >
                                C’est par ici !
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>

                    <Grid
                      xs={12}
                      md={5}
                      item
                      className="display-flex"
                      style={{
                        borderRadius: "48px",
                        cursor: "pointer",
                        zIndex: 94,
                      }}
                    >
                      <div className="slider-wrapperNew ">
                        <div
                          className="sliderNew "
                          style={{
                            transform: `translateX(-${currentSlide * 100}%)`,
                            transition: "transform 0.5s ease",
                          }}
                        >
                          {slides.map((slide, index) => (
                            <div key={index} className="slideNew">
                              <img
                                src={slide?.imageUrl}
                                alt={`Slide ${index + 1}`}
                              />
                              <div className="captionNew">
                                <h3
                                  className="caption"
                                  style={{ margin: 0, marginBottom: "20px" }}
                                >
                                  {slide.caption}
                                  <br />
                                  {slide.captionTwo}
                                </h3>

                                {/* Dots inside the caption container */}
                                <div
                                  className="caption_line"
                                  style={{ marginBottom: "20px" }}
                                ></div>

                                {/*
                                comment by asad iqbal 17/1/25 (web+app review ppt)
                                <div
                                  className="slider-dotted"
                                  style={{ marginBottom: "20px" }}
                                >
                                  {slides.map((_, index) => (
                                    <span
                                      key={index}
                                      className={`dotted ${
                                        currentSlide === index ? "active" : ""
                                      }`}
                                      onClick={() => changeSlide(index)}
                                    ></span>
                                  ))}
                                </div> */}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Grid>

                    <Grid
                      xs={12}
                      md={12}
                      item
                      style={{
                        zIndex: 1111,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // marginBottom: "40px",
                      }}
                    >
                      <KeyboardArrowDown
                        onClick={() =>
                          window.scrollTo({ top: 2000, behavior: "smooth" })
                        }
                        style={{
                          fontSize: "120px",
                          color: "#f9d9b6",
                          cursor: "pointer",
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <img
                src={require("../../assets/images/FrankIcons/home_banner.png")}
                className="home-page-banner"
                alt="home-banner"
              />
            </div>

            <Grid
              container
              spacing={spacing?.spacing2}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "200px",
              }}
              className="pour-qui"
            >
              <Grid
                xs={12}
                sm={6}
                md={3}
                item
                style={{
                  maxWidth: "300px",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  paddingRight: "20px",
                }}
              >
                <h2
                  style={{
                    fontFamily: "Raleway-Extra-Bold-Italic",
                    fontWeight: "900",
                    fontSize: "42px",
                    color: "#3026f1",
                    marginTop: "-5px",
                  }}
                >
                  POURQUOI
                  <br />
                  CHOISIR
                  <br />
                  <span
                    style={{ color: "#ee6931", fontFamily: "Raleway-Italic" }}
                  >
                    FRANK ?
                  </span>{" "}
                  <CustomButton
                    onClick={() => {
                      navigate("/about-us");
                    }}
                    style={{
                      fontFamily: `Raleway-Extra-Bold !important`,
                      marginTop: 20,
                    }}
                    classesNames={"contained_button_two"}
                    value={
                      <React.Fragment>
                        <div
                          className="display-flex"
                          style={{
                            padding: "8px 0px 8px 0px",
                            fontSize: "16px",
                            justifyContent: "space-around",
                          }}
                        >
                          NOUS DÉCOUVRIR
                          <East
                            style={{
                              color: defaultLabelColors.main_blue,
                              background: "#f9d9b6",
                              borderRadius: 100,
                              margin: "4px",
                              padding: 4,
                            }}
                          />
                        </div>
                      </React.Fragment>
                    }
                  />
                </h2>
              </Grid>
              <Grid xs={12} sm={6} md={3} item style={{ maxWidth: "250px" }}>
                <div
                  style={{
                    background: "#f9d9b6",
                    borderRadius: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    height: "250px",
                    position: "relative",
                  }}
                >
                  <img
                    src={iconOne}
                    style={{
                      position: "absolute",
                      bottom: "32%",
                      width: "75%",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      left: "1%",
                      height: "80%",
                    }}
                    alt="icon-one"
                  />
                  <div
                    style={{
                      fontFamily: "Raleway-Extra-Bold",
                      fontSize: "16px",
                      color: "#ee6931",
                      textAlign: "center",
                      paddingTop: "100px",
                    }}
                    // className="display-flex"
                  >
                    <span style={{ color: "#3026f1", textAlign: "center" }}>
                      LIVRAISON
                    </span>
                    <br />
                    ÉCOLOGIQUE
                    <p
                      style={{
                        fontFamily: "Raleway-Semi-Bold",
                        fontSize: "12px",
                        color: "#000",
                        padding: "0px 30px 0px 30px",
                      }}
                    >
                      Chaque colis livré par Frank, c'est 25kg de CO2 en moins.
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid xs={12} sm={6} md={3} item style={{ maxWidth: "250px" }}>
                <div
                  style={{
                    background: "#f9d9b6",
                    borderRadius: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    height: "250px",
                    position: "relative",
                  }}
                >
                  <img
                    src={iconTwo}
                    style={{
                      position: "absolute",
                      bottom: "29%",
                      width: "75%",
                      height: "80%",
                      right: "1%",
                    }}
                    alt="icon-two"
                  />
                  <div
                    style={{
                      fontFamily: "Raleway-Extra-Bold",
                      fontSize: "16px",
                      color: "#ee6931",
                      textAlign: "center",
                      paddingTop: "100px",
                    }}
                  >
                    <span style={{ color: "#3026f1", textAlign: "center" }}>
                      LIVRAISON
                    </span>
                    <br />
                    ÉCONOMIQUE
                    <p
                      style={{
                        fontFamily: "Raleway-Semi-Bold",
                        fontSize: "12px",
                        color: "#000",
                        padding: "0px 30px 0px 30px",
                      }}
                    >
                      Avec Frank, envoyez vos colis sans vous ruiner !
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid xs={12} sm={6} md={3} item style={{ maxWidth: "250px" }}>
                <div
                  style={{
                    background: "#f9d9b6",
                    borderRadius: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    height: "250px",
                    position: "relative",
                  }}
                >
                  <img
                    src={iconThree}
                    style={{
                      position: "absolute",
                      bottom: "12%",
                      right: "0%",
                      height: "97%",
                      width: "64%",
                    }}
                    alt="icon-three"
                  />
                  <div
                    style={{
                      fontFamily: "Raleway-Extra-Bold",
                      fontSize: "16px",
                      color: "#ee6931",
                      textAlign: "center",
                      paddingTop: "100px",
                    }}
                  >
                    <span style={{ color: "#3026f1", textAlign: "center" }}>
                      LIVRAISON
                    </span>
                    <br />
                    ASSURÉE
                    <p
                      style={{
                        fontFamily: "Raleway-Semi-Bold",
                        fontSize: "12px",
                        color: "#000",
                        padding: "0px 30px 0px 30px",
                      }}
                    >
                      Tous vos colis sont assurés lors de vos envois !
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={spacing?.spacing2}
              style={{
                paddingLeft: "170px",
                paddingRight: "170px",
                marginTop: "20px",
                marginBottom: "-20px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "10px",
                  backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'%3E%3Ccircle cx='5' cy='5' r='2' fill='%233026f1' /%3E%3C/svg%3E")`,
                  backgroundRepeat: "repeat-x",
                }}
              ></div>
            </Grid>

            {/*
            comment by asad iqbal 1/14/2025 on Ben Demand ( Launching Frank C2C ppt)
            <Grid container spacing={spacing.spacing2}>
              <Grid
                xs={12}
                item
                className="display-flex"
                style={{ flexDirection: "column" }}
              >
                <h2
                  style={{
                    fontFamily: "Raleway-Italic",
                    fontWeight: "900",
                    fontSize: "46px",
                    color: "#ee6931",
                    textAlign: "center",
                    margin: 0,
                  }}
                >
                  <span style={{ color: "#3026f1" }}> FRANK,</span> LA
                  MARKETPLACE
                  <br /> COLLABORATIVE
                </h2>
                <p
                  style={{
                    fontFamily: "Raleway-Bold",
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >
                  Frank, c'est aussi une Marketplace où vous pouvez acheter des
                  objets de seconde main ou mettre en ligne <br /> vos propres
                  annonces. Nous nous chargeons ensuite de la livraison !
                </p>
              </Grid>

              <Grid xs={12} item className="display-flex">
                <div style={{ padding: "30px 80 0px 80", gap: 20 }}>
                  <Slider
                    {...settings}
                    nextArrow={<ArrowForwardIos style={{ fontSize: "32px" }} />}
                    arrows={true}
                    prevArrow={
                      <div style={{ paddingLeft: "10px" }}>
                        <ArrowBackIos
                          style={{
                            fontSize: "36px",
                            paddingLeft: 5,
                            paddingBottom: 4,
                          }}
                        />
                      </div>
                    }
                  >
                    {state.randomProducts?.map((i: any) => {
                      return (
                        <ProductCard
                          openProduct={() => handleOpenProduct(i)}
                          prductAmount={i?.price - Number(i?.discount ?? 0)}
                          productDesc={""}
                          productImage={i?.images[0]}
                          productTitle={i?.product_title?.fr}
                          productType={i?.is_particular}
                        />
                      );
                    })}
                  </Slider>
                </div>
              </Grid>

              <Grid xs={12} item style={{ marginTop: "30px" }}>
                <div
                  style={{ display: "flex", gap: "20px" }}
                  className="display-flex"
                >
                  <CustomButton
                    style={{
                      width: "360px",
                      maxWidth: "400px",
                    }}
                    classesNames={"outlined_button_two"}
                    onClick={() => {
                      navigate("/marketplace");
                    }}
                    value={
                      <React.Fragment>
                        <div
                          className="display-flex"
                          style={{
                            columnGap: "30px",
                            padding: "8px 0px 8px 0px",
                            fontSize: "14px",
                          }}
                        >
                          DÉCOUVREZ PLUS D'ANNONCES
                          <East
                            style={{
                              color: defaultLabelColors.main_blue,
                              background: "#f9d9b6",
                              borderRadius: 100,
                              margin: "4px",
                              padding: 4,
                            }}
                          />
                        </div>
                      </React.Fragment>
                    }
                  />
                  <CustomButton
                    style={{
                      width: "280px",
                    }}
                    classesNames={"contained_button_two"}
                    onClick={() => {
                      navigate("/add-product-individual");
                    }}
                    value={
                      <React.Fragment>
                        <div
                          className="display-flex"
                          style={{
                            columnGap: "30px",
                            padding: "8px 0px 8px 0px",
                            fontSize: 14,
                            fontFamily: "Raleway-Extra-Bold",
                          }}
                        >
                          VENDEZ MAINTENANT
                          <East
                            style={{
                              color: defaultLabelColors.main_blue,
                              background: "#f9d9b6",
                              borderRadius: 100,
                              margin: "4px",
                              padding: 4,
                            }}
                          />
                        </div>
                      </React.Fragment>
                    }
                  />
                </div>
              </Grid>
            </Grid> */}

            <div>
              <section>
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="mstpone"
                >
                  {/* Text Block */}
                  <div
                    style={{
                      fontSize: "38px",
                      fontFamily: "Raleway-Italic",
                      fontWeight: "900",
                      color: defaultLabelColors.main_blue,
                      padding: "30px",
                      // width: "900px",
                      textAlign: "center",
                    }}
                  >
                    <h2
                      style={{
                        margin: 0,
                        fontSize: "38px",
                        fontWeight: "900",
                      }}
                    >
                      LA LIVRAISON DE PARTICULIER À PARTICULIER,
                      <br />
                      COMMENT ÇA MARCHE
                      <span
                        style={{
                          color: defaultLabelColors.main_orange,
                          marginLeft: 5,
                        }}
                      >
                        ?
                      </span>
                    </h2>
                  </div>
                  <div
                    style={{
                      flexDirection: "row-reverse",
                      display: "flex",
                      width: "80%",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <img
                        src={StepScreen1}
                        alt="step-one"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                      <p className="vous-voulez">
                        VOUS VOULEZ ENVOYER UN COLIS ?
                        <br />
                        <span className="vous-voyez-desc">
                          Publiez votre annonce en indiquant les <br /> détails
                          concernant le colis et sa destination.
                        </span>
                        <br />
                        <span style={{ marginTop: "20px", fontWeight: "700" }}>
                          <CustomButton
                            style={{
                              background: "transparent",
                              border: "1px solid #3026f1",
                              borderRadius: "32px",
                              width: "250px",
                              color: "#3026f1",
                              marginTop: "20px",
                              padding: "10px",
                              fontSize: "16px",
                            }}
                            onClick={() => {
                              navigate("/send-package");
                            }}
                            classesNames={"outlined_button_two"}
                            value={"ENVOYER UN COLIS"}
                          />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              <section>
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="mstptwo"
                >
                  <div
                    style={{
                      flexDirection: "row-reverse",
                      display: "flex",
                      width: "80%",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <img
                        src={StepScreen2}
                        alt="step-one"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                      <p className="vous-voyez-two">
                        VOUS VOUS VOYEZ <br />
                        PLUTÔT TRANSPORTEUR ?
                        <br />
                        <span className="vous-voyez-child">
                          Parcourez les offres de livraisons disponibles
                          <br /> et choisissez celle qui vous convient.
                        </span>
                        <br />
                        <span style={{ marginTop: "20px", fontWeight: "700" }}>
                          <CustomButton
                            style={{
                              background: "transparent",
                              border: "1px solid #fff",
                              borderRadius: "32px",
                              fontSize: "12px",
                              width: "270px",
                              color: "#fff",
                              marginTop: "20px",
                              padding: "10px",
                            }}
                            onClick={() => {
                              navigate("/transporter-un-colis");
                            }}
                            value={"VOIR LES ANNONCES"}
                            classesNames={"white_outlined_button"}
                          />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              <section>
                <div
                  style={{
                    position: "relative",
                    // height: divHeight,
                    display: "flex",
                    flexDirection: "column",
                    // justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="mstpone"
                >
                  <div
                    style={{
                      flexDirection: "row-reverse",
                      display: "flex",
                      width: "80%",
                    }}
                  >
                    <div style={{ position: "relative" }}>
                      <img
                        ref={imgRef}
                        src={StepScreen3}
                        alt="step-one"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                      <img
                        src={StepScreen3Coin}
                        style={{
                          // height: "100%",
                          // width: "100%",
                          // objectFit: "contain",
                          // position: "absolute",
                          // zIndex: 999999,
                          // bottom: 0,

                          height: "6vw",
                          width: "100%",
                          objectFit: "contain",
                          position: "absolute",
                          bottom: -50,
                          left: 0,
                          zIndex: 999999,
                        }}
                      />
                      <div className="vous-voyez-three">
                        <p style={{ margin: 0 }}>
                          LE COLIS A ÉTÉ LIVRÉ !<br />
                          <span
                            style={{
                              fontFamily: "Raleway-Bold",
                              fontSize: "16px",
                              color: "#000",
                            }}
                          >
                            <span style={{ fontFamily: "Raleway-Extra-Bold" }}>
                              Félicitations{" "}
                            </span>
                            ! Votre porte-monnaie se remplit
                            <br />
                            automatiquement une fois que la livraison a été
                            assurée.
                          </span>
                        </p>
                        <CustomButton
                          style={{
                            background: "transparent",
                            border: "1px solid #3026f1",
                            borderRadius: "32px",
                            fontSize: "14px",
                            width: "290px",
                            color: "#3026f1",
                            marginTop: "20px",
                            padding: "10px",
                          }}
                          onClick={() => {
                            handleVerticalTabChange();
                            navigate("/profile");
                          }}
                          classesNames={"outlined_button_two"}
                          value={"GAGNER DE L'ARGENT"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* <div
                    style={{
                      background: "red",
                      position: "relative",
                    }}
                  >
                    <img src={StepScreen1} alt="step-one" />
                    <div style={{
                      position:'absolute'
                    }}>
                      <h1>some content</h1>
                      <h3>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Eveniet iste eaque quas neque voluptatum,
                      </h3>
                    </div>
                  </div> */}
            </div>

            <br />
          </div>

          <Grid
            container
            spacing={2}
            className="display-flex"
            style={{ top: "100%" }}
          >
            <div className="market-place-2d">
              <div
                style={{
                  fontFamily: "Raleway-Italic",
                  fontWeight: "900",
                  fontSize: "52px",
                  color: "#ee6931",
                  flex: 1,
                  width: "100%",
                }}
              >
                <h2
                  style={{
                    color: defaultLabelColors.main_blue,
                    margin: 0,
                    fontWeight: "900",
                    fontSize: "52px",
                  }}
                >
                  Transporter un colis
                  <br />
                  <span style={{ color: defaultLabelColors.main_orange }}>
                    les dernières annonces
                  </span>
                </h2>
              </div>
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  gap: "20px",
                  marginTop: "-80px",
                }}
              >
                <CustomButton
                  style={{
                    background: defaultLabelColors.white,
                    color: defaultLabelColors.main_blue,
                    borderRadius: 100,
                    fontSize: "14px",
                    fontWeight: "700",
                    width: "200px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    border: `1px solid ${defaultLabelColors.main_blue}`,
                  }}
                  classesNames={"outlined_button_two"}
                  value={
                    <React.Fragment>
                      <div
                        className="display-flex"
                        style={{
                          columnGap: "40px",
                          padding: "8px 0px 8px 0px",
                          fontSize: 14,
                        }}
                      >
                        Taille
                        <ArrowForwardIos
                          style={{
                            borderRadius: 100,
                            color: defaultLabelColors.main_blue,
                            fontSize: 18,
                          }}
                        />
                      </div>
                    </React.Fragment>
                  }
                />
              </div> */}
              <div className="arguments-2e" style={{ marginTop: "30px" }}>
                <Grid container spacing={4}>
                  {state?.annoucesData?.length > 0 ? (
                    state?.annoucesData?.map((i: any) => {
                      return (
                        <Grid
                          xs={12}
                          sm={6}
                          md={3}
                          lg={3}
                          xl={2}
                          item
                          onClick={() => {
                            navigate("/transporter-un-colis");
                          }}
                          style={{ maxHeight: "400px" }}
                        >
                          <div
                            style={{
                              background: color_theme.sand,
                              borderRadius: 32,
                            }}
                          >
                            <div
                              style={{
                                position: "relative",
                                borderTopLeftRadius: 32,
                                borderTopRightRadius: 32,
                              }}
                            >
                              <img
                                src={
                                  i?.shipment_items?.[0]?.prod_images[0] ??
                                  no_image_url
                                }
                                style={{
                                  height: 150,
                                  width: "100%",
                                  objectFit: "cover",
                                  objectPosition: "top",
                                  borderTopLeftRadius: 32,
                                  borderTopRightRadius: 32,
                                }}
                              />
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 8,
                                rowGap: 8,
                                padding: 16,
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: 16,
                                    fontFamily: "Raleway-Bold",
                                    width: `calc(100% - 100px)`,
                                    flex: 1,
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    color: "##281208",
                                    margin: 0,
                                  }}
                                >
                                  {handleLocalize({
                                    en: i?.shipment_items[0]?.item_name_en,
                                    fr: i?.shipment_items[0]?.item_name_fr,
                                  }) ?? "N/A"}
                                </p>
                              </div>

                              <p
                                style={{
                                  fontSize: 12,
                                  fontFamily: "Raleway",
                                  maxWidth: "100%",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  color: "#281208",
                                  margin: 0,
                                }}
                              >
                                Il y a{" "}
                                {`${moment(i?.created_at).fromNow()}`
                                  .replaceAll("days ago", "")
                                  .replaceAll("day ago", "")}{" "}
                                jours
                              </p>

                              <p
                                style={{
                                  margin: 0,
                                  fontFamily: "Raleway-Extra-Bold",
                                  fontSize: "22px",
                                  color: defaultLabelColors.main_blue,
                                }}
                              >
                                {numberFormatWithoutCurrency(
                                  Number(i?.ship_price ?? 0)
                                )}{" "}
                                €
                              </p>
                              <div
                                style={{
                                  background: "#fff",
                                  borderRadius: "32px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: 10,
                                  padding: 5,
                                  fontFamily: "Raleway-Bold",
                                  fontSize: "11px",
                                  flexDirection: "row",
                                }}
                              >
                                <LocationOn
                                  style={{
                                    color: defaultLabelColors.main_orange,
                                    fontSize: "22px",
                                  }}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "row",
                                  }}
                                >
                                  <div>
                                    De &nbsp;
                                    {i?.dropoff?.city}
                                  </div>
                                  <div>
                                    à &nbsp;
                                    {i?.pickup?.city}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* <div
                            className="frame-2f"
                            style={{ maxHeight: "400px" }}
                          >
                            <div
                              className="product"
                              style={{ cursor: "pointer" }}
                            >
                              <div className="frame-30">
                                <div className="image">
                                  <img
                                    src={
                                      i?.shipment_items?.[0]
                                        ?.prod_images?.[0] ?? "prod name"
                                    }
                                    alt="prod-img"
                                  />
                                </div>
                              </div>
                              <div className="frame-31">
                                <div className="title">
                                  <span className="table-wood">
                                    {i?.shipment_items?.[0]?.prod_name ??
                                      "prod name"}
                                  </span>
                                  <span className="time">
                                    {moment(i?.created_at).fromNow()}
                                  </span>
                                </div>
                              </div>
                              <div
                                className=""
                                style={{
                                  display: "flex",
                                  padding: "0px 20px 20px 20px",
                                }}
                              >
                                <div style={{ flex: 1, width: "35%" }}>
                                  <span
                                    className="time-38"
                                    style={{
                                      fontFamily: "Raleway-Extra-Bold",
                                      fontSize: "22px",
                                      color: defaultLabelColors.main_blue,
                                    }}
                                  >
                                    {i?.ship_price ?? "0.00"}€
                                  </span>
                                </div>

                                
                              </div>
                            </div>
                          </div> */}
                        </Grid>
                      );
                    })
                  ) : (
                    <div
                      className="frame-2f"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "Raleway-Variable",
                        fontSize: "32px",
                        fontWeight: "900",
                        color: "grey",
                        height: "400px",
                      }}
                    >
                      No Anouncement available !
                    </div>
                  )}

                  <Grid
                    xs={12}
                    item
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                  >
                    <CustomButton
                      style={{
                        background: defaultLabelColors.main_blue,
                        color: defaultLabelColors.white,
                        borderRadius: 100,
                        fontSize: "18px",
                        width: "300px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        navigate("/transporter-un-colis");
                      }}
                      classesNames={"contained_button_two"}
                      value={
                        <React.Fragment>
                          <div
                            className="display-flex"
                            style={{
                              columnGap: "30px",
                              padding: "8px 0px 8px 0px",
                              fontSize: 14,
                            }}
                          >
                            VOIR PLUS D’ANNONCES
                            <East
                              style={{
                                background: defaultLabelColors.sand,
                                borderRadius: 100,
                                color: defaultLabelColors.main_blue,
                                padding: 4,
                              }}
                            />
                          </div>
                        </React.Fragment>
                      }
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>

          <div
            style={{ position: "relative", height: "100%", marginTop: "100px" }}
            className="display-flex"
          >
            <img
              src={cranceImage}
              alt="crance-img"
              style={{
                width: "100%",
                height: "100%",
              }}
            />
            <div style={{}} className="triangle-two">
              <Grid container>
                <Grid xs={7} item>
                  <div style={{ flex: 1 }}>
                    <h3
                      style={{
                        fontSize: "32px",
                        fontWeight: "800",
                        color: defaultLabelColors.main_blue,
                      }}
                    >
                      VOUS ÊTES UN PROFESSIONNEL ?
                    </h3>
                    <p
                      style={{
                        fontFamily: "Raleway-Extra-Bold",
                        fontSize: "20px",
                        color: "#000",
                      }}
                    >
                      Découvrez notre solution de livraison Peoples post !
                    </p>
                    <p
                      style={{
                        fontFamily: "Raleway-Variable",
                        fontSize: "16px",
                        color: "#000",
                        fontWeight: "400",
                        textAlign: "left",
                      }}
                    >
                      Éco-responsable, économique et flexible, la solution
                      Peoples post change la vie des professionnels. En fonction
                      de vos besoins, nous prenons en charge la collecte, la
                      préparation de commande et même la livraison au client
                      final. Pour plus de fluidité, Peoples post peut s'intégrer
                      à vos systèmes de gestion via Sendcloud !
                    </p>
                  </div>
                  <div>
                    <CustomButton
                      style={{ fontFamily: "Raleway-Variable", width: "230px" }}
                      classesNames={"contained_button_two"}
                      onClick={() => {
                        // navigate("/transporter-un-colis");
                        // // comment by asad iqbal from 16/1/25(review website + app ppt)
                        window.open("https://www.peoplespost.fr/", "_blank");
                      }}
                      value={
                        <React.Fragment>
                          <div
                            className="display-flex"
                            style={{
                              columnGap: "30px",
                              fontSize: 14,
                            }}
                          >
                            DÉCOUVRIR
                            <East
                              style={{
                                color: defaultLabelColors.main_blue,
                                background: "#f9d9b6",
                                borderRadius: 100,
                                margin: "4px",
                                padding: 4,
                              }}
                            />
                          </div>
                        </React.Fragment>
                      }
                    />
                  </div>
                </Grid>
                <Grid
                  xs={5}
                  item
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <div style={{ position: "relative", height: "100%" }}>
                    <img
                      src={craneImage}
                      alt="crane-img"
                      className="crane-image"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <div style={{ padding: "0px 140px 0px 140px" }}>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h2
                style={{
                  textAlign: "left",
                  fontSize: "42px",
                  fontFamily: "Raleway-Extra-Bold-Italic",
                  padding: "40px 0px 20px 20px",
                  margin: 0,
                }}
              >
                Nos actualités
              </h2>
              <ul className="cards">
                {state?.allBlogs?.slice(0, 3)?.map((item: any) => {
                  return (
                    <>
                      <li
                        className="cards__item"
                        onClick={() => {
                          navigate(
                            `/blog-details/${item?.blog_cat_id}/${item?.id}`
                          );
                        }}
                      >
                        <div className="card">
                          <div
                            className="card__image"
                            style={{
                              backgroundImage: `url(${item?.blog_image?.[0]})`,
                            }}
                          ></div>
                          <div className="card__content">
                            <div style={{ display: "flex" }}>
                              <div className="card__title" style={{ flex: 1 }}>
                                {handleLocalize(item?.title)}
                                <p
                                  style={{
                                    fontFamily: "Raleway-Italic",
                                    fontSize: "14px",
                                    color: "#000",
                                    fontWeight: "400",
                                    marginTop: 5,
                                  }}
                                >
                                  Posté le{" "}
                                  {moment(
                                    `${item?.created_at}`.endsWith("Z")
                                      ? item?.created_at
                                      : item?.created_at + "Z"
                                  ).format("DD MMM YYYY")}
                                </p>
                              </div>
                              <div>
                                <East
                                  style={{
                                    background: "#fff",
                                    borderRadius: 100,
                                    color: defaultLabelColors.main_blue,
                                    padding: 5,
                                    fontSize: "16px",
                                  }}
                                />{" "}
                              </div>
                            </div>
                            <p className="card__text">
                              {handleLocalize(item?.sub_title)}
                            </p>
                          </div>
                        </div>
                      </li>
                    </>
                  );
                })}
              </ul>
            </div>
          </Grid>

          <Grid container className="display-flex">
            <Grid xs={12} item>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "26px",
                  fontFamily: "Raleway-Extra-Bold-Italic",
                  color: "#000",
                }}
              >
                <h3
                  style={{
                    cursor: "pointer",
                    fontSize: "26px",
                    fontFamily: "Raleway-Extra-Bold-Italic",
                  }}
                  className="display-flex"
                  onClick={() => {
                    navigate("/blogs");
                  }}
                >
                  {" "}
                  VOIR TOUS LES ARTICLES &nbsp; <East />
                </h3>
              </div>

              <div
                style={{
                  // maxWidth: "1000px",
                  marginTop: "30px",
                  height: 1,
                  background: "grey",
                  textAlign: "center",
                }}
              ></div>
            </Grid>
          </Grid>
        </div>
        <div className="display-flex">
          <Grid
            container
            spacing={2}
            marginTop={5}
            style={{
              padding: 10,
              width: "1000px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              xs={12}
              item
              className="display-flex"
              style={{
                flexDirection: "column",
                background: "#F5C9B3",
                padding: "10px",
                borderRadius: "18px",
              }}
            >
              <h3
                style={{
                  fontSize: "28px",
                  fontFamily: "Raleway-Extra-Bold-Italic",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  textAlign: "left",
                  width: "100%",
                  padding: "15px",
                  paddingLeft: "32px",
                  margin: 0,
                }}
              >
                Des questions ?
              </h3>
              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: "#F5C9B3",
                }}
              >
                <AccordionSummary
                  style={{ border: "none" }}
                  expandIcon={<ExpandMore />}
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500" }}>
                    Comment fonctionne la livraison collaborative ?
                  </h3>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    width: "90%",
                    fontFamily: "Raleway-Variable",
                  }}
                >
                  Grâce à notre plateforme, un particulier peut envoyer un colis
                  qui sera livré par un autre particulier lors d’un de ses
                  trajets personnels. Au cœur de notre activité, la livraison
                  collaborative nous permet de créer une communauté d’entraide
                  en garantissant un service d’envoi peu coûteux et écologique.
                </AccordionDetails>
              </Accordion>

              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: "#F5C9B3",
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500" }}>
                    Comment fonctionne la livraison professionnelle avec Frank ?
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "90%" }}>
                  <p>
                    {" "}
                    Nous couvrons l’envoi du premier au dernier kilomètre et
                    stockons vos colis dans notre entrepôt en Île-de-France. En
                    fonction de vos besoins, nous proposons plusieurs modes de
                    livraison professionnelle :
                  </p>

                  <p>
                    {" "}
                    • Pick & Pack : récupération des colis à votre adresse,
                    préparation des colis, packaging d’envoi ;{" "}
                  </p>
                  <p>
                    • Livraison totale : nous nous occupons de tout, de la
                    récupération des colis jusqu’à leur dépôt chez le client
                    final ;
                  </p>
                  <p>
                    • Stockage : nous stockons vos colis dans notre entrepôt en
                    Île-de-France, avant leur retrait.
                  </p>

                  <p>
                    {" "}
                    Pour plus d’informations, vous pouvez nous contacter en
                    envoyant un mail à l’adresse :
                    <a href="mailto:hello@frankme.com">hello@frankme.com</a>.
                  </p>
                </AccordionDetails>
              </Accordion>

              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: "#F5C9B3",
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500" }}>
                    Puis-je gagner de l’argent avec Frank ?
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "90%" }}>
                  <p>
                    {" "}
                    Vous êtes transporteur ? En voiture, en train, en
                    trottinette, en péniche ou à pied, quel que soit le mode de
                    transport, vous gagnez de l’argent !{" "}
                  </p>

                  <p>
                    Vous envoyez un colis avec Frank ? Vous ne gagnez pas
                    d’argent, mais vous en économisez à coup sûr !{" "}
                  </p>
                </AccordionDetails>
              </Accordion>

              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: "#F5C9B3",
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500" }}>
                    Comment fonctionne l’assurance des colis ?
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "90%" }}>
                  Tout envoi de colis via Frank est assuré à une hauteur de 100
                  €. Pour vos colis les plus précieux, vous pouvez souscrire à
                  une assurance supplémentaire (HISCOX).
                </AccordionDetails>
              </Accordion>

              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: "#F5C9B3",
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500" }}>
                    Comment devenir transporteur ?
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "90%" }}>
                  <p>
                    {" "}
                    Pour devenir transporteur à nos côtés, rien de plus simple :{" "}
                  </p>

                  <p>
                    {" "}
                    • Vous créez un compte sur le site ou l’application de Frank
                    ;{" "}
                  </p>
                  <p>
                    {" "}
                    • Vous checkez les annonces à côté de vous, ou sur vos
                    trajets ;{" "}
                  </p>
                  <p> • Vous acceptez une offre ;</p>
                  <p>
                    {" "}
                    • Vous remettez le colis au destinataire, qui vous remet un
                    code pour valider la livraison ;{" "}
                  </p>
                  <p>
                    {" "}
                    • Vous recevez le montant de la livraison sur votre
                    porte-monnaie une fois que vous avez renseigné le code.{" "}
                  </p>
                </AccordionDetails>
              </Accordion>

              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: "#F5C9B3",
                  width: "100%",
                  padding: "10px 0px",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500", padding: "5px 0px" }}>
                    En tant que transporteur, comment transférer l’argent de mon
                    porte-monnaie sur mon compte bancaire ?
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "90%" }}>
                  <p>
                    Sur l’application Frank, il y a un espace porte-monnaie, où
                    vous trouverez le montant total de vos gains. Il suffit de
                    cliquer sur la carte bancaire, puis sur “transférer sur un
                    compte bancaire” et de renseigner les informations
                    demandées. L’argent arrive sur votre compte au bout de
                    quelques jours !
                  </p>
                </AccordionDetails>
              </Accordion>

              {/*
              comment by asad iqbal 1/14/2025 on Ben Demand ( Launching Frank C2C ppt)
              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: "#F5C9B3",
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500" }}>
                    C’est quoi, le Marché de Frank ?
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "90%" }}>
                  <p>
                    {" "}
                    Le Marché de Frank, c’est une marketplace pour particuliers
                    et pour professionnels :{" "}
                  </p>
                  <p>
                    {" "}
                    • Les professionnels peuvent y vendre autant de produits
                    qu’ils le veulent. Chaque professionnel a son propre shop au
                    nom de sa marque ;{" "}
                  </p>
                  <p>
                    • Les particuliers peuvent vendre leurs articles de seconde
                    main ou leurs créations personnelles jusqu’à une hauteur de
                    2 500 euros par mois. Au-delà de ce montant, vous êtes
                    clairement un professionnel !
                  </p>
                  Pour les vendeurs particuliers, la livraison des produits est
                  également assurée par des particuliers. Une fois qu’une
                  personne a acheté un objet, une annonce est automatiquement
                  créée dans l’espace dédié aux transporteurs. Il ne reste plus
                  qu’à attendre que l’un d’entre eux accepte la livraison ! Ce
                  système de marketplace collaborative est écologique de A à Z.
                </AccordionDetails>
              </Accordion> */}

              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: "#F5C9B3",
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500" }}>
                    Y a-t-il une limite de poids pour la livraison des colis ?
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "100%" }}>
                  <p>
                    Il n’y a pas de limite de poids chez Frank. Pour un
                    déménagement, vous pouvez prévoir le nombre de personnes
                    dont vous avez besoin en renfort en fonction de la taille
                    des meubles.
                  </p>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </div>
        <div
          className="main-container"
          style={{ background: "#fff", marginTop: "100px" }}
        >
          <CustomFooter />
        </div>
      </div>

      <div className="mobile-screen">
        <MobileView
          {...state}
          {...props}
          handleGetRecentJobs={handleGetRecentJobs}
          handleGetAllBlog={handleGetAllBlog}
        />
      </div>
    </>
  );
}

export default LandingPage;

export const BlogCard = ({ i }: any) => (
  <div className="blogcard-container">
    <div className="blogcard-image">
      <img
        src={i?.blog_image?.[0]}
        alt="Box Image"
        className="blogresponsive-image"
      />
    </div>
    <div className="blogcard-content">
      <h3 className="blogcard-title">
        <strong>{handleLocalize(i?.title)}</strong>
      </h3>
      <p className="blogcard-subtitle">il y a 10 heures</p>
      <p className="blogcard-description">{handleLocalize(i?.sub_title)}</p>
      <div className="blogcard-arrow">
        <span>&#10140;</span>
      </div>
    </div>
  </div>
);
